import React, {useMemo, useState} from 'react';
import {Button, Row, Col} from 'reactstrap';
import {DateTime} from 'luxon';

import {RootState} from '../../../../reducer';
import {updateActiveMacros} from '../../../../lib/callable-functions';

const RefreshMacros = ({
  settingId,
  zendesk,
}: {
  settingId: RootState['firestore']['setting']['id'];
  zendesk: RootState['firestore']['setting']['zendesk'];
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {refreshDate, disableRefresh} = useMemo(
    () => ({
      refreshDate: zendesk.zendeskMacrosLastUpdate
        ? `Mis à jour ${DateTime.fromJSDate(
            zendesk.zendeskMacrosLastUpdate
          ).toRelative()}`
        : 'Jamais mis à jour',
      disableRefresh:
        zendesk.zendeskMacrosLastUpdate &&
        Math.abs(
          DateTime.fromJSDate(zendesk.zendeskMacrosLastUpdate).diffNow(
            'minutes'
          ).minutes
        ) < 5,
    }),
    [zendesk.zendeskMacrosLastUpdate]
  );

  const handleRefresh = async () => {
    setLoading(true);
    try {
      await updateActiveMacros(settingId);
    } catch {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <>
      <h5 className="mb-3">Liste des macros</h5>

      <Row className="mb-2">
        <Col md="7">{refreshDate}</Col>
        <Col
          md="5"
          className="align-items-center justify-content-center d-flex"
        >
          <div className="text-center">
            {!error && (
              <Button
                disabled={disableRefresh || loading}
                color={disableRefresh ? 'success' : 'hubware'}
                outline={disableRefresh}
                onClick={handleRefresh}
              >
                {loading
                  ? 'Chargement...'
                  : disableRefresh
                  ? 'Macros à jour'
                  : 'Rafraichir les macros'}
              </Button>
            )}
            {error && (
              <p className="small text-danger">
                Une erreur est survenue, merci de réessayer ultérieurement.
              </p>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RefreshMacros;
