// @flow
import React from 'react';
import styled from 'styled-components';

import EditBtn from '../../../../../components/edit-btn';
import {Card} from '../../../../../components/card';
import OperatorModal from '../../../../../components/operators-modal';

import useAutoAssign from './useAutoAssign';

const AutoAssign = ({intent}: {intent: Object}) => {
  const {
    notSupported,
    modal,
    setModal,
    handleClick,
    Child,
    pairedOperator,
  } = useAutoAssign({intent});

  return (
    <Card>
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="d-flex align-items-center">
              <div>Assignation automatique</div>
              <StyledBadge>New</StyledBadge>
            </h4>

            <h6 className="card-subtitle mb-2 text-muted">
              Assigner Automatiquement un opérateur ou groupe d'opérateurs à
              cette catégorie
            </h6>
          </div>

          {!notSupported && (
            <EditBtn isEditing={false} toggleEdit={() => setModal(true)} />
          )}
        </div>

        {Child}

        <OperatorModal
          modal={modal}
          setModal={setModal}
          handleClick={handleClick}
          selectedValue={pairedOperator}
        />
      </div>
    </Card>
  );
};

export default AutoAssign;

const StyledBadge = styled.div.attrs({
  className: 'ml-2 badge badge-hubware',
})`
  line-height: 0.8em;
  font-size: 0.7em;
  font-weight: lighter;
`;
