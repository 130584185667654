// @flow
import React, {type Node} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {Card} from '../../components/card';
import logo from '../../components/logo.svg';
import Ads from './ad';

const SignWrapper = ({
  children,
  link,
}: {
  children: Node,
  link?: {title: string, pathname: string},
}) => (
  <StyledWrapper>
    <div className="align-items-center justify-content-center d-flex m-auto">
      <div className="col-md-10">
        <div className="text-center mb-3">
          <img src={logo} alt="logo" height="75px" />
        </div>
        <Card className="mb-3">
          <div className="card-body">{children}</div>
        </Card>
        {link && (
          <div className="text-center">
            <Link
              className="btn btn-sm btn-link"
              to={{
                pathname: link.pathname,
                search: window.location.search,
              }}
            >
              {link.title}
            </Link>
          </div>
        )}
      </div>
    </div>
    <Ads />
  </StyledWrapper>
);

export default SignWrapper;

const StyledWrapper = styled.div`
  display: flex;
  min-height: 100%;
  overflow: auto;

  > div {
    height: auto !important;
    min-height: 100%;

    @media (min-width: 768px) {
      width: 50%;
    }
  }
`;
