// @flow
import firebase from 'firebase/app';
import {get} from 'lodash';

import {unpairTemplate} from './templates';

import {SET_TEMPLATES} from '../../actions/types/firestore-types';

const schemas = require('@hubwa-re/joi-firebase')(require('joi-browser'));
const offlineTemplates = require('../../offline/demo-macros.json');

type PREPARED_REPLY = {
  title: string,
  actions: {
    email: {
      body: string,
    },
  },
};

const db = (settingId: string) =>
  firebase
    .firestore()
    .collection('settings')
    .doc(settingId)
    .collection('preparedReplies');

export const addPreparedReply = (title: string, body: string) => async (
  dispatch: Function,
  getState: Function
) => {
  const sanitizedPreparedReply = sanitize(
    {title, actions: {email: {body}}},
    getState()
  );

  // Offline
  if (getState().app.offline) {
    return dispatch({
      type: SET_TEMPLATES,
      templates: [
        ...offlineTemplates,
        {title, actions: {email: {body}}, id: Math.random()},
      ],
    });
  }

  return db(getState().firestore.setting.id).add(sanitizedPreparedReply);
};

export const updatePreparedReply = (
  id: string,
  title: string,
  body: string
) => async (dispatch: Function, getState: Function) => {
  const sanitizedPreparedReply = sanitize(
    {title, actions: {email: {body}}},
    getState()
  );

  if (getState().app.offline) {
    return dispatch({
      type: SET_TEMPLATES,
      templates: offlineTemplates.map((tmp) =>
        tmp.id === id ? {...tmp, ...sanitizedPreparedReply} : tmp
      ),
    });
  }

  return db(getState().firestore.setting.id)
    .doc(id)
    .update(sanitizedPreparedReply);
};

export const deletePreparedReply = (id: string) => async (
  dispatch: Function,
  getState: Function
) => {
  // Offline
  if (getState().app.offline) {
    return dispatch({
      type: SET_TEMPLATES,
      templates: offlineTemplates.filter((tmp) => tmp.id !== id),
    });
  }

  await dispatch(unpairTemplate(id));

  return db(getState().firestore.setting.id).doc(id).delete();
};

const sanitize = (preparedReply: PREPARED_REPLY, state: Object) => {
  if (!get(state, 'firestore.setting.salesforce')) {
    throw new Error(
      "Can't create prepared reply for a tickets provider different than salesforce"
    );
  }

  const {error, value} = schemas.preparedReply.validate(preparedReply);

  if (error) {
    throw error;
  }

  return value;
};
