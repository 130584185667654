// @flow
import {createSelector} from 'reselect';

export type USER_INTERFACE = {
  email: string,
  created: Date,
  isUserForced?: boolean,
  emailVerified?: boolean,
  providers: Array<{email: string, id: string}>,
};

const user = ({app: {loggedUser}, firestore: {user}}): ?USER_INTERFACE => {
  if (!loggedUser.uid) {
    return undefined;
  }
  const isUserForced = loggedUser.uid !== user.id;

  if (isUserForced) {
    return {
      isUserForced,
      email: user.uiSettings.email,
      created: user.created,
      providers: [],
    };
  }
  return {
    isUserForced,
    email: loggedUser.email,
    created: new Date(Number(loggedUser.createdAt)),
    emailVerified: loggedUser.emailVerified,
    providers: loggedUser.providerData.map(({providerId, email}) => ({
      id: providerId,
      email,
    })),
  };
};

export const getUser = createSelector([user], (usr) => usr);
