// @flow
import React from 'react';
import styled from 'styled-components';
import {DateTime} from 'luxon';
import {Calendar, ArrowRight} from 'react-feather';

const DateButton = ({
  dateRange,
  handleClick,
}: {
  dateRange: Object,
  handleClick: Function,
}) => (
  <StyledDateButton onClick={handleClick}>
    <span className="d-flex align-items-center">
      <Calendar
        size="16"
        className="mr-1"
        style={{
          top: '-1px',
          position: 'relative',
        }}
      />
      {dateRange.startDate.toLocaleString(DateTime.DATE_MED)}
    </span>
    <span className="d-flex align-items-center">
      <ArrowRight size="17" className="mx-1" />
      {dateRange.endDate.toLocaleString(DateTime.DATE_MED)}
    </span>
  </StyledDateButton>
);

export default DateButton;

const StyledDateButton = styled.div.attrs({
  className: 'text-muted d-flex flex-wrap align-items-center py-2 px-1 clic',
})`
  border-bottom: 1px solid var(--gray);
  font-size: 95%;
  margin-bottom: 25px;

  &:hover {
    background: #f3f3f3;
  }
`;
