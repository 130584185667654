// @flow
import {get} from 'lodash';
import React from 'react';
import {MoreHorizontal} from 'react-feather';
import {Link} from 'react-router-dom';
import AddButton from './add-button';

import {assistanceTypes} from '../../../../selectors/templates';
import type {TEMPLATE_INTERFACE} from '../../../../selectors/templates';
import type {INTENT_INTERFACE} from '../../../../selectors/intents';
import filters from '../../../../components/table/filters/filters';

const columns = (allIntents: INTENT_INTERFACE) => [
  {Header: 'Titre', accessor: 'title', Filter: filters.input},
  {
    Header: 'Assistance',
    accessor: ({assistanceType}: TEMPLATE_INTERFACE) =>
      assistanceType === 'none' ? '-' : assistanceTypes[assistanceType],
  },
  {
    Header: 'Catégories',
    accessor: ({intents}: TEMPLATE_INTERFACE) =>
      intents.map((key) => get(allIntents, [key, 'title'], key)).join(' • ') ||
      '-',
    filter: 'reverseIncludes',
    Filter: filters.select,
  },
  {
    Header: 'Logique',
    accessor: ({filter}: TEMPLATE_INTERFACE) =>
      filter ? (filter.disabled ? 'Désactivée' : 'Activée') : '-',
    filter: 'bool',
    Filter: filters.toggle,
  },
  {
    Header: 'Variables',
    accessor: ({variables}: TEMPLATE_INTERFACE) => variables.length || '-',
    filter: 'bool',
    Filter: filters.toggle,
  },
  {
    Header: 'Actions Backoffice',
    accessor: ({requestsActions}: TEMPLATE_INTERFACE) => requestsActions || '-',
    filter: 'bool',
    Filter: filters.toggle,
  },
  {
    Header: 'Détails',
    accessor: 'id',
    disableSorting: true,
    Cell: ({cell: {value}}: {cell: {value: string}}) => (
      <Link
        to={{
          pathname: `/config/templates/${value}/details`,
          search: window.location.search,
        }}
      >
        <MoreHorizontal color="grey" />
      </Link>
    ),
  },
];

const tab = {
  key: 'templates',
  title: 'Modèles de réponse',
  pathname: '/config/_templates',
};

const templatesUtils = {
  tab,
  columns,
  AddButton,
};

export default templatesUtils;
