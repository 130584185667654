import {DataState} from '../reducer/types/data-types';
import {
  DataActionsTypes,
  SET_EVAL_JOB,
  SET_INTENTS,
  SET_PROVIDER,
  SET_SYSTEMS_STATUS,
} from './types/data-types';

export const setEvalJobs = (
  key: DataState['ai'],
  evalJob: DataState['ai']
) => ({
  type: SET_EVAL_JOB,
  key,
  evalJob,
});

export const setProvider = (
  provider: DataState['provider']
): DataActionsTypes => ({
  type: SET_PROVIDER,
  provider,
});

export const setIntents = (
  intents: DataState['intents']
): DataActionsTypes => ({
  type: SET_INTENTS,
  intents,
});

export const fetchSystemsStatus = () => async (
  dispatch: Function,
  getState: Function
) => {
  await dispatch({
    type: SET_SYSTEMS_STATUS,
    systemsStatus: {},
  });
  const response = await fetch(
    'https://wqdwvp76qbvb.statuspage.io/api/v2/summary.json'
  );

  if (response.ok) {
    const systemsStatus = await response.json();
    dispatch({
      type: SET_SYSTEMS_STATUS,
      systemsStatus,
    });
  }
};
