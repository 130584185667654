// @flow
import React from 'react';
import {Button} from 'reactstrap';
import {CloudOff} from 'react-feather';
import styled from 'styled-components';

const Disabled = () => (
  <StyledPlaceHolder>
    <p className="text-muted m-0">
      <CloudOff className="mr-3" size={20} style={{opacity: 0.6}} />
      Cette fonctionnalité n'est pas disponible pour cette catégorie
    </p>

    <Button
      href="https://hubwa.re/contact-us/"
      target="blank"
      rel="noopener noreferrer"
      outline
      color="hubware"
      className="mt-3"
    >
      Nous contacter
    </Button>
  </StyledPlaceHolder>
);

export default Disabled;

const StyledPlaceHolder = styled.div.attrs({
  className:
    'd-flex flex-column align-items-center justify-content-center mt-2 p-4',
})`
  background-color: #fafafa;
  min-height: 150px;
`;
