// @flow
import React from 'react';
import {Col} from 'reactstrap';

import Doughnut from '../../components/doughnut';
import {getDoughnutRatio, getHistoryRatio} from './utils';

const IntentsDoughnuts = ({
  auto,
  filteredFormatedValues,
  filteredHistoryFormatedValues,
  historyRange,
  isLoading,
  prepared,
  suggested,
}: {
  auto: Array<string>,
  filteredFormatedValues: Array<Object>,
  filteredHistoryFormatedValues: Array<Object>,
  historyRange: {startDate: Date, endDate: Date},
  isLoading: boolean,
  prepared: Array<string>,
  suggested: Array<string>,
}) => (
  <>
    <Col md="4">
      <Doughnut
        labels={auto}
        usage={auto[0]}
        isLoading={isLoading}
        datasets={getDoughnutRatio(filteredFormatedValues, ...auto)}
        historyVal={getHistoryRatio(filteredHistoryFormatedValues, ...auto)}
        historyRange={historyRange}
      />
    </Col>
    <Col md="4">
      <Doughnut
        labels={prepared}
        usage={prepared[0]}
        isLoading={isLoading}
        datasets={getDoughnutRatio(filteredFormatedValues, ...prepared)}
        historyVal={getHistoryRatio(filteredHistoryFormatedValues, ...prepared)}
        historyRange={historyRange}
      />
    </Col>
    <Col md="4">
      <Doughnut
        labels={suggested}
        usage={suggested[0]}
        isLoading={isLoading}
        datasets={getDoughnutRatio(filteredFormatedValues, ...suggested)}
        historyVal={getHistoryRatio(
          filteredHistoryFormatedValues,
          ...suggested
        )}
        historyRange={historyRange}
      />
    </Col>
  </>
);

export default IntentsDoughnuts;
