// @flow
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Row, Col} from 'reactstrap';

import {
  getBoardconfiguration,
  type BOARDCONFIG_INTERFACE,
} from '../../../../selectors/board-configuration';
import {DownloadData} from '../../../../lib/firestore/utils';
import {updateSettingAtPath} from '../../../../lib/firestore/setting';

const ExportData = ({
  loggedUser,
  DownloadData,
  boardConfiguration,
  updateSettingAtPath,
}: {
  loggedUser: Object,
  DownloadData: Function,
  boardConfiguration: BOARDCONFIG_INTERFACE,
  updateSettingAtPath: Function,
}) => {
  const [success, setSuccess] = useState(false);
  const {requestDataExport} = boardConfiguration;

  const handleExportconfig = () => {
    DownloadData();
    setSuccess(true);
  };

  const handleClick = async () => {
    if (!Boolean(requestDataExport)) {
      await updateSettingAtPath(
        'boardConfiguration.requestDataExport',
        loggedUser.email
      );
    }
  };

  return (
    <>
      <h5 className="mb-3">Export des données</h5>

      <h6 className="mt-4 mb-3">
        <strong>Export des données de configuration</strong>
      </h6>
      <Row className="mb-2">
        <Col md="7">
          <ul style={{listStyle: 'circle'}}>
            <li>Configuration de votre assistant</li>
            <li>Données liées à votre compte utilisateur.</li>
            <li>Modèles de réponses associés à votre compte</li>
          </ul>
        </Col>
        <Col
          md="5"
          className="align-items-center justify-content-center d-flex"
        >
          <div className="text-center">
            <Button
              color={success ? 'success' : 'hubware'}
              onClick={handleExportconfig}
              disabled={success}
              outline={!success}
            >
              {success
                ? 'Données exportées avec succès'
                : 'Exporter la configuration'}
            </Button>
            <small className="text-muted d-block mt-1">
              Export au format Json
            </small>
          </div>
        </Col>
      </Row>

      <h6 className="mt-4 mb-3">
        <strong>Export de la totalité des données</strong>
      </h6>
      <Row>
        <Col md="7">
          <ul style={{listStyle: 'circle'}}>
            <li>Données backoffice</li>
            <li>Données statistiques</li>
            <li>Données transactionnelles</li>
            <li>Données des conversations</li>
          </ul>
        </Col>
        <Col
          md="5"
          className="align-items-center justify-content-center d-flex"
        >
          {!requestDataExport && (
            <div className="text-center">
              <Button color="hubware" onClick={handleClick} outline>
                Demander un export
              </Button>
              <small className="text-muted d-block mt-1">
                Export au format CSV
              </small>
            </div>
          )}
          {requestDataExport && (
            <div className="text-center">
              <h6 className="text-success">
                Demande d'export prise en compte.
              </h6>
              <p>
                <small>
                  La demande sera traité dans les plus brefs délais. pour toutes
                  questions vous pouvez contacter{' '}
                  <a href="mailto:help@hubwa.re">mailto:help@hubwa.re</a>.
                </small>
              </p>
            </div>
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md="7">
          <p className="text-muted">
            <small>
              <strong>Cet export peut êre très volumineux</strong> et peut durer
              plusieurs jours.
            </small>
          </p>
        </Col>
      </Row>
    </>
  );
};

export default connect(
  (state) => ({
    loggedUser: state.app.loggedUser,
    boardConfiguration: getBoardconfiguration(state),
  }),
  (dispatch) => ({
    ...bindActionCreators({DownloadData, updateSettingAtPath}, dispatch),
  })
)(ExportData);
