// @flow
import React, {type Node} from 'react';
import styled from 'styled-components';
import {Col, Row} from 'reactstrap';
import {ExternalLink, CheckCircle} from 'react-feather';
import i18n from '../../../i18n';

const Final = ({isActive, children}: {isActive: boolean, children: Node}) => (
  <Row>
    <Col className="text-center">
      <StyledCheckCircle className={isActive ? 'animate__animated' : ''} />
      {children}
      <h5>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${window.location.origin}?demo-tour`}
          className="btn btn-hubware btn-lg"
        >
          {i18n.t('Access the demo')}
          <ExternalLink
            size="20"
            className="ml-1"
            style={{top: '-2px', position: 'relative'}}
          />
        </a>
      </h5>
    </Col>
  </Row>
);

export const FinalZendesk = ({isActive}: {isActive: boolean}) => (
  <Final isActive={isActive}>
    <h4 className="mb-4" style={{marginTop: '60px'}}>
      {i18n.t('Thank you for installing the official Hubware extension')}
    </h4>
    <p className="text-muted lead">
      {i18n.t('Your Analyzer is now installed on your Zendesk environment')}
      <br />
      {i18n.t('You can preview the dashboard right now and close this window.')}
    </p>
  </Final>
);

export const FinalOther = ({isActive}: {isActive: boolean}) => (
  <Final isActive={isActive}>
    <h4 className="mb-4" style={{marginTop: '60px'}}>
      {i18n.t('Thank you for finalizing your registration!')}
    </h4>
    <p className="text-muted lead">
      {i18n.t('You can preview the dashboard right now and close this window.')}
    </p>
  </Final>
);

const StyledCheckCircle = styled(CheckCircle).attrs({
  className: 'animate__bounceIn text-success',
  size: 95,
})`
  margin-top: 60px;
  margin-bottom: 60px;
  stroke-width: 1px;

  & > path {
    animation: dash 3s ease-in-out;
    animation-direction: alternate-reverse;
    animation-iteration-count: 3;
    stroke-dasharray: 50;

    @keyframes dash {
      0% {
        stroke-dashoffset: 100;
        stroke-dasharray: 50;
      }
      100% {
        stroke-dashoffset: 100;
        stroke-dasharray: 10;
      }
    }
  }
`;
