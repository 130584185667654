export const EVENT_VALUES = {
  AUTO_REPLY: {
    title: 'Réponses automatiques',
    shortTitle: 'Auto.',
    symbol: 'a',
    color: `hsl(214, 69%, 35%)`,
  },
  MACRO_AUTO_USED: {
    title: 'Réponses préparées',
    shortTitle: 'Préparées',
    symbol: 'p',
    color: `hsl(214, 69%, 60%)`,
  },
  SUGGESTION_USED: {
    title: 'Réponses assistées',
    shortTitle: 'Assistées',
    symbol: 's',
    color: `hsl(214, 69%, 75%)`,
  },
  AUTO_REPLY_NOT_USED: {
    title: 'Réponses automatiques refusées',
    shortTitle: 'Auto. refusées',
    symbol: 'a',
    color: `hsl(214, 69%, 35%)`,
  },
  MACRO_AUTO_NOT_USED: {
    title: 'Réponses préparées refusées',
    shortTitle: 'Préparées refusées',
    symbol: 'p',
    color: `hsl(214, 69%, 60%)`,
  },
  SUGGESTION_NOT_USED: {
    title: 'Réponses assistées refusées',
    shortTitle: 'Assistées refusées',
    symbol: 's',
    color: `hsl(214, 69%, 85%)`,
  },
  NO_ASSISTANCE: {
    title: 'Aucune assistance',
    shortTitle: 'Aucune assistance',
    symbol: 's',
    color: {main: 'red', alt: 'red'},
  },
  LINK_CLICKED: {
    title: 'Liens cliqués',
    symbol: 'L',
    color: `hsl(105, 49%, 40%)`,
  },
  VARIABLE_SUBSTITUTION_MACRO_COUNT: {
    title: 'Variables substituées',
    symbol: 'V',
    color: `hsl(105, 49%, 55%)`,
  },
};

export const ASSISTANCE_USAGES = [
  'AUTO_REPLY',
  'MACRO_AUTO_USED',
  'SUGGESTION_USED',
];

export const REFUSED_ASSISTANCE_USAGES = [
  'AUTO_REPLY_NOT_USED',
  'MACRO_AUTO_NOT_USED',
  'SUGGESTION_NOT_USED',
];

export const NO_ASSISTANCE_USAGES_FULL = [
  ...REFUSED_ASSISTANCE_USAGES,
  'NO_ASSISTANCE',
];

export const AUTO_ASSISTANCE = ['AUTO_REPLY', 'AUTO_REPLY_NOT_USED'];
export const PREPARED_ASSISTANCE = ['MACRO_AUTO_USED', 'MACRO_AUTO_NOT_USED'];
export const SUGGESTED_ASSISTANCE = ['SUGGESTION_USED', 'SUGGESTION_NOT_USED'];
