import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import Modal from '../../../../components/modal';
import BlocForm from './bloc-form';
import {Bloclogic} from '../../../../reducer/types/firestore-types';

const ModalWrapper = ({
  handleValidate,
  handleDelete,
  openModal,
  setModal,
  modal,
  getBlocUsage,
  selectBloc,
}: {
  handleValidate: Function;
  handleDelete: Function;
  openModal: Function;
  setModal: Function;
  modal: boolean;
  getBlocUsage: Function;
  selectBloc: Bloclogic;
}) => {
  const [editLogic, setEditLogic] = useState<Bloclogic>();

  const {t} = useTranslation();

  useEffect(() => {
    selectBloc && setEditLogic(selectBloc);
  }, [selectBloc]);

  const customValidate = selectBloc?.type.includes('customlogicbloc-');
  const unabledValidate =
    editLogic?.type === '' ||
    editLogic?.description === '' ||
    editLogic?.code === t("// Create your own logic : Should('...') //") ||
    editLogic?.code === '';

  return (
    <Modal
      size="lg"
      modal={modal}
      setModal={setModal}
      handleConfirm={() => handleValidate(editLogic)}
      handleCancel={() => setModal(false)}
      title={t('Edit custom logic')}
      unabled={unabledValidate}
      confirmTitle={customValidate && t('Edit all templates using it')}
    >
      <BlocForm
        setEditLogic={setEditLogic}
        editLogic={editLogic}
        handleDelete={handleDelete}
        getBlocUsage={getBlocUsage}
      />
    </Modal>
  );
};

export default ModalWrapper;
