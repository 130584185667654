// @flow
import React from 'react';
import {Line} from 'react-chartjs-2';
import {merge} from 'lodash';

import {Card} from '../../../components/card';
import LoadingState from './loading-state';
import {TIMECHART_PARAMS, getYAxes} from '../../../lib/charts';

const AnalyticsLine = ({
  labels,
  datasets,
  isLoading,
  yaxes,
  height = '330px',
  options = {},
}: {
  labels: Array<string>,
  datasets: Array<Object>,
  isLoading: boolean,
  yaxes: Array<string>,
  height?: string,
  options?: Object,
}) => {
  const ADDITIONAL_CHART_PARAMS = merge({}, TIMECHART_PARAMS, {
    options: {
      scales: {
        yAxes: getYAxes(yaxes),
      },
      ...options,
    },
  });

  return (
    <Card className="mb-4">
      <div className="card-body" style={{height}}>
        {isLoading ? (
          <LoadingState />
        ) : (
          <Line {...ADDITIONAL_CHART_PARAMS} data={{labels, datasets}} />
        )}
      </div>
    </Card>
  );
};

export default AnalyticsLine;
