// @flow
import React from 'react';

const MetaDataDetails = () => (
  <>
    <h5 className="mb-3">Comment utiliser les éléments variables&nbsp;?</h5>
    <p>
      Il vous est possible de mettre en place des éléments de texte variables
      dans vos modèles de réponse. Ces éléments variables seront remplacés par
      les informations issues de vos APIs ou de votre backoffice.
    </p>
    <p>
      Cela permet de gagner du temps en personnalisant et en enrichissant
      automatiquement vos réponses.
    </p>
    <div className="mb-2">
      Une fois l'élément variable défini dans la liste ci-dessous, vous pouvez
      l'utiliser dans vos modèles de réponse grace à la syntaxe &nbsp;
      <code>&#x40;&#x40;variable&#x40;&#x40;</code>
    </div>
    <div className="mb-2 text-right small">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://hubware-assistance.zendesk.com/hc/fr/articles/115003673673-Comment-mettre-en-place-des-champs-variables-dans-vos-macros-"
      >
        Plus de détails
      </a>
    </div>
  </>
);

export default MetaDataDetails;
