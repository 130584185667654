// @flow
import React from 'react';
import {usePagination, useSortBy, useFilters, useTable} from 'react-table';
import styled from 'styled-components';
import CsvButton from './csv-button';
import Pagination from './pagination';
import TableBody from './table-body';
import TableHeader from './table-header';
import Filter from './filters';
import filterTypes from './filters/filterTypes';

function Index({
  columns,
  csvFilename,
  data,
  isLoading = false,
  pageSize = 10,
  sortBy = [],
  className,
  hiddenColumns = [],
}: {
  columns: Array<{accessor: string | Function, Header: string}>,
  data: Array<Object>,
  csvFilename?: string,
  data: Array<Object>,
  isLoading?: boolean,
  pageSize?: number,
  sortBy?: Array<string>,
  className?: string,
  hiddenColumns?: Array<string>,
}) {
  const initialState = {
    pageIndex: 0,
    pageSize,
    hiddenColumns,
    sortBy: React.useMemo(
      () =>
        sortBy.map((id) => {
          const reverse = id[0] === '-';
          return {
            id: reverse ? id.substr(1) : id,
            asc: !reverse,
            desc: reverse,
          };
        }),
      [sortBy]
    ),
    paginateExpandedRows: false,
  };

  const opts = useTable(
    {
      data,
      columns,
      initialState,
      filterTypes: React.useMemo(filterTypes, []),
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Filter columns={opts.columns} />

      <div className="pb-2 table-responsive-md">
        <StyledTable {...opts.getTableProps()} className={className}>
          <TableHeader headerGroups={opts.headerGroups} />
          <TableBody
            isLoading={isLoading}
            prepareRow={opts.prepareRow}
            page={opts.page}
          />
        </StyledTable>
        {data.length > 1 && (
          <div className="d-flex justify-content-between align-items-center">
            <Pagination
              className="d-flex align-items-center"
              data={data}
              nextPage={opts.nextPage}
              previousPage={opts.previousPage}
              canPreviousPage={opts.canPreviousPage}
              canNextPage={opts.canNextPage}
              pageOptions={opts.pageOptions}
              pageIndex={opts.state.pageIndex}
            />

            {csvFilename && (
              <CsvButton
                values={data}
                filename={csvFilename}
                columns={columns}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

const StyledTable = styled.table.attrs({
  className: 'table mb-2 light-table',
})`
  font-size: 90%;
  table-layout: fixed;

  th {
    width: 100%;
    color: var(--gray);
    vertical-align: middle;
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    > div {
      display: inline-block;
      position: relative;
      > svg {
        position: absolute;
        right: -15px;
        top: 2px;
      }
    }
  }
  th:first-child {
    width: 250px;
  }

  th:not(:last-child) {
    max-width: 330px;
    min-width: 140px;
  }
`;

export default Index;
