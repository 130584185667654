import React from 'react';
import {Row, Col} from 'reactstrap';
import {useTranslation} from 'react-i18next';

import {RootState} from '../../../../reducer';
import Status from '../../../../components/status';

const Informations = ({
  zendesk,
}: {
  zendesk: RootState['firestore']['setting']['zendesk'];
}) => {
  const {t} = useTranslation();

  return (
    <>
      <h5 className="mb-3">{t('Informations')}</h5>
      <Row>
        <Col sm="3" className="mb-2">
          <strong>{t('Domain')}</strong>
        </Col>
        <Col sm="9" className="d-flex align-items-center">
          <a
            href={`https://${zendesk.domain}.zendesk.com`}
            target="_blank"
            rel="noopener noreferrer"
          >
            https://{zendesk.domain}.zendesk.com
          </a>
        </Col>
      </Row>

      <Row>
        <Col sm="3" className="mb-2">
          <strong>{t('Connexion')}</strong>
        </Col>
        <Col sm="9" className="d-flex align-items-center">
          <Status
            enabled={!!zendesk.auth.bearer}
            title={zendesk.auth.bearer ? t('Connected') : t('Not-connected')}
          />
        </Col>
      </Row>

      <Row>
        <Col sm="3" className="mb-2">
          <strong>{t('Macros auto-refresh')}</strong>
        </Col>
        <Col sm="9" className="d-flex align-items-center">
          <Status
            enabled={!!zendesk.enableMacrosAutoRefresh}
            title={
              zendesk.enableMacrosAutoRefresh ? t('Enabled') : t('Disabled')
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default Informations;
