import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class Admin extends Component {
  render() {
    const {isAdmin, children} = this.props;

    if (!isAdmin) {
      return null;
    }

    return children;
  }
}

export default connect((state) => ({
  isAdmin: state.app.isAdmin,
}))(Admin);

Admin.propTypes = {
  children: PropTypes.node,
  isAdmin: PropTypes.bool,
};
