// @flow
import React from 'react';

import {Button} from 'reactstrap';
import {Shuffle} from 'react-feather';

import styled from 'styled-components';

const UnPaired = ({intent, setModal}: {intent: Object, setModal: Function}) => (
  <StyledPlaceHolder>
    <p className="text-muted m-0">
      <Shuffle className="mr-3" size={25} style={{opacity: 0.6}} />
      Aucun opérateur n'est assigné automatiquement à la catégorie "
      {intent.title.toLowerCase()}".
    </p>

    <Button
      outline
      size="sm"
      color="hubware"
      className="mt-3"
      onClick={() => setModal(true)}
    >
      Activer l'assignation automatique
    </Button>
  </StyledPlaceHolder>
);

export default UnPaired;

const StyledPlaceHolder = styled.div.attrs({
  className:
    'd-flex flex-column align-items-center justify-content-center mt-2 p-4',
})`
  background-color: #fafafa;
  min-height: 150px;
`;
