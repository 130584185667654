import {mapValues, isPlainObject, isArray} from 'lodash';
import {DateTime} from 'luxon';

import {downloadFile} from '../utils';

// Utils
// -----
export const collectionToData = (querySnapshot) =>
  querySnapshot.docs.map(docToData);

export const docToData = (docSnapshot) =>
  Object.assign(
    {},
    docSnapshot.data(),
    docSnapshot.data().id ? null : {id: docSnapshot.id} // Avoid replace existing "id" field in object
  );

export const parseObjectWithDates = (obj) =>
  mapValues(obj, (val) => {
    if (isPlainObject(val)) {
      return parseObjectWithDates(val);
    }
    if (!val) {
      return val;
    }
    if (typeof val.toDate === 'function') {
      return val.toDate();
    }
    if (isArray(val) && isPlainObject(val[0])) {
      return val.map(parseObjectWithDates);
    }
    return val;
  });

export const DownloadData = () => (dispatch, getState) => {
  const {app, firestore} = getState();
  const json = {
    loggedUser: app.loggedUser,
    user: firestore.user,
    setting: firestore.setting,
  };

  downloadFile(
    JSON.stringify(json),
    `conf.export.${DateTime.local().toLocaleString()}`,
    'json'
  );
};
