import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'reactstrap';

import {Card} from '../../../components/card';
import {getUserNotification} from '../../../lib/user';
import features from './features';
import {RootState} from '../../../reducer/index';

const mapDispatch = {
  setUiState: () => ({type: 'SET_UI_STATE'}),
};

export const WhatsNew = ({
  setUiState,
}: {
  setUiState: ({userNotification: boolean}) => void;
}) => {
  useEffect(() => {
    setUiState({userNotification: getUserNotification()});
  }, [setUiState]);
  localStorage.setItem('featuresLen', `${features.length}`);

  return (
    <div className="container">
      <Row className="justify-content-md-center">
        <Col md="7">
          <Card>
            <div className="card-body text-left">
              <h4 className="card-title pb-4">Nouveautés</h4>
              {features}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default connect((state: RootState) => ({}), mapDispatch)(WhatsNew);
