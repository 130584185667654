// @flow
import {DateTime} from 'luxon';

import {DATE_FORMAT} from './utils';

export const TIMECHART_PARAMS = {
  height: 100,
  legend: {position: 'bottom'},
  options: {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          type: 'time',
          stacked: true,
          time: {
            isoWeekday: true,
            unit: 'week',
            displayFormats: {
              week: DATE_FORMAT,
            },
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        title: ([{label}]: Array<{label: string}>) =>
          DateTime.fromISO(label).toLocaleString(DateTime.DATE_MED),
      },
    },
  },
};

const leftGridlines = {
  drawBorder: false,
  borderDash: [3, 3],
  lineWidth: 1,
  zeroLineWidth: 0,
};

const YAXES = [
  {
    id: 'responses',
    stacked: true,
    ticks: {
      min: 0,
    },
    scaleLabel: {
      display: true,
      labelString: 'Réponses',
    },
    gridLines: leftGridlines,
  },
  {
    id: 'predictions',
    stacked: true,
    ticks: {
      min: 0,
    },
    scaleLabel: {
      display: true,
      labelString: 'Messages analysés',
    },
    gridLines: leftGridlines,
  },
  {
    id: 'assistance',
    ticks: {
      min: 0,
      callback: (value) => `${value}%`,
    },
    scaleLabel: {
      display: true,
      labelString: 'Assistance',
    },
    gridLines: {
      display: false,
    },
  },
  {
    id: 'coverage',
    stacked: true,
    ticks: {
      min: 0,
      max: 100,
      callback: (value) => `${value}%`,
    },
    scaleLabel: {
      display: true,
      labelString: 'Couverture',
    },
    gridLines: leftGridlines,
  },
];

export const getYAxes = (ids: Array<string>): Array<Object> =>
  YAXES.filter(({id}) => ids.includes(id)).map((yAxe, index) => ({
    ...yAxe,
    position: index === 0 ? 'left' : 'right',
  }));
