// @flow
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Label, Input, Row, Col} from 'reactstrap';

import {
  getBoardconfiguration,
  type BOARDCONFIG_INTERFACE,
} from '../../../../selectors/board-configuration';
import {updateSettingAtPath} from '../../../../lib/firestore/setting';

const Accountdeletion = ({
  loggedUser,
  boardConfiguration,
  updateSettingAtPath,
}: {
  loggedUser: Object,
  boardConfiguration: BOARDCONFIG_INTERFACE,
  updateSettingAtPath: Function,
}) => {
  const [confirm, setConfirm] = useState(false);
  const {requestAccountDeletion} = boardConfiguration;

  const handleClick = async () => {
    if (!Boolean(requestAccountDeletion)) {
      await updateSettingAtPath(
        'boardConfiguration.requestAccountDeletion',
        loggedUser.email
      );
    }
  };

  return (
    <>
      <h5 className="mb-3">Suppression du compte</h5>
      <Row className="mb-2">
        <Col md="7">
          <p>
            La totalité des données du compte, ainsi que toutes les données
            d'apprentissage et de suivi statistique seront définitivement
            supprimée.
          </p>
          <Label check className="mb-3 clic">
            Je comprends que cette action est irréversible et que toutes les
            données seront définitivement perdues
            <Input
              type="checkbox"
              className="mt-1 ml-1"
              style={{position: 'relative'}}
              checked={confirm}
              onChange={(e) => setConfirm(e.target.checked)}
            />
          </Label>
        </Col>
        <Col
          md="5"
          className="align-items-center justify-content-center d-flex"
        >
          <div className="text-center">
            {!requestAccountDeletion && (
              <Button
                color="hubware"
                onClick={handleClick}
                outline={!confirm}
                disabled={!confirm}
              >
                Demander la suppression du compte
              </Button>
            )}
            {requestAccountDeletion && (
              <div>
                <h6 className="text-success">
                  Demande de suppression prise en compte.
                </h6>
                <p>
                  <small>
                    La demande sera traité dans les plus brefs délais. pour
                    toutes questions vous pouvez contacter{' '}
                    <a href="mailto:help@hubwa.re">mailto:help@hubwa.re</a>.
                  </small>
                </p>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default connect(
  (state) => ({
    loggedUser: state.app.loggedUser,
    boardConfiguration: getBoardconfiguration(state),
  }),
  (dispatch) => ({...bindActionCreators({updateSettingAtPath}, dispatch)})
)(Accountdeletion);
