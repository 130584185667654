// @flow
import {get} from 'lodash';
import {createSelector} from 'reselect';

export type ACTION_INTERFACE = {
  id: string,
  title: string,
  description?: string,
  label?: string,
  isActive: boolean,
  templates?: Array<string>,
  action?: {
    url: string,
    options: Object,
  },
};

export const getAllActions: Array<ACTION_INTERFACE> = createSelector(
  [(state) => get(state, 'firestore.setting.actions', [])],
  (allActions) => allActions
);
