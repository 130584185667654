// @flow
import React from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'reactstrap';
import {v4 as uuidv4} from 'uuid';
import {get} from 'lodash';

import {
  getBoardconfiguration,
  type BOARDCONFIG_INTERFACE,
} from '../../../../selectors/board-configuration';
import {getAllIntents} from '../../../../selectors/intents';
import {Card} from '../../../../components/card';
import Bubble from '../../components/bubble';

const Sabotage = ({
  boardConfiguration,
  intentsList,
  zendeskBaseUrl,
}: {
  boardConfiguration: BOARDCONFIG_INTERFACE,
  intentsList: Object,
  zendeskBaseUrl: string,
}) => {
  // TODO : THIS IS A TEMPORARILY COMPONENT. ASK JULIEN
  // TODO : THIS IS A TEMPORARILY COMPONENT. ASK JULIEN
  // TODO : THIS IS A TEMPORARILY COMPONENT. ASK JULIEN
  // TODO : THIS IS A TEMPORARILY COMPONENT. ASK JULIEN
  const sabotageAnalytics = boardConfiguration.sabotageAnalytics;

  if (!sabotageAnalytics) {
    return (
      <Row>
        <Col>
          <p className="text-muted">Aucune analyse disponible</p>
        </Col>
      </Row>
    );
  }

  const {value, period, tickets, intents} = sabotageAnalytics;

  return (
    <>
      <Row>
        <Col>
          <Card className="mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="text-muted">Pertinence {period}</div>
                <Bubble color="var(--primary)" symbol="S" />
              </div>
              <div className="display-3">{value}</div>
            </div>
          </Card>
        </Col>
      </Row>

      {intents && (
        <Row>
          <Col>
            <Card className="mb-4">
              <div className="card-body">
                <table className="table mb-4 light-table">
                  <thead>
                    <tr>
                      <th>Catégorie</th>
                      <th>Pertinence</th>
                    </tr>
                  </thead>
                  <tbody>
                    {intents.map(({intent = 'no_intent', value}) => (
                      <tr key={uuidv4()}>
                        <td>{(intentsList[intent] || {}).title}</td>
                        <td>{value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          </Col>
        </Row>
      )}

      {tickets && (
        <Row>
          <Col>
            <Card className="mb-4">
              <div className="card-body">
                <table className="table mb-4 light-table">
                  <thead>
                    <tr>
                      <th>Ticket</th>
                      <th>Creation</th>
                      <th>Catégorie</th>
                      <th>Statut</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.map(
                      ({ticketId, creation, intent = 'no_intent', status}) => (
                        <tr key={uuidv4()}>
                          <td>
                            <a
                              href={`${zendeskBaseUrl}/agent/tickets/${
                                ticketId || '--'
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {ticketId || '--'}
                            </a>
                          </td>
                          <td>{creation}</td>
                          <td>{(intentsList[intent] || {}).title}</td>
                          <td>{status}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default connect((state, props) => ({
  zendeskBaseUrl: get(state, 'firestore.setting.zendesk.baseUrl', ''),
  boardConfiguration: getBoardconfiguration(state),
  intentsList: getAllIntents(state),
}))(Sabotage);
