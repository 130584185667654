// @flow
import {GraphQLClient} from 'graphql-hooks';
import memCache from 'graphql-hooks-memcache';
import {map, get} from 'lodash';

import {getProjectId} from '../firebase';
import queries from './queries';

let client;

export const getClient = () => {
  if (!client) {
    const url =
      getProjectId() === 'hubware-production-firebase'
        ? 'https://api2.hubwa.re/api/v3/graphql'
        : 'https://nightly-api2.hubwa.re/api/v3/graphql';

    client = new GraphQLClient({
      url,
      cache: memCache(),
      fetch:
        window.location.search.includes('offline') ||
        window.location.search.includes('demo-tour') // TODO : improve this, use offline & demo into main state
          ? demoInterceptor
          : undefined,
    });
  }
  return client;
};

export const setClient = (jwt: string) => (
  dispatch: Function,
  getState: Function
) => {
  getClient();

  if (client.headers['x-hubware-authentication'] !== jwt) {
    client.setHeader('x-hubware-authentication', jwt);
  }
};

const demoInterceptor = async (uri: string, options: Object) => {
  const body = JSON.parse(options.body);
  const path = get(
    map(queries).find(({query}) => query === body.query),
    'offline',
    ''
  );

  console.log(`emulate ${path} graphql data`);

  return Promise.resolve({
    ok: true,
    json: async () => require(`../../offline/${path}`),
  });
};
