// @flow
import {DateTime} from 'luxon';
import {map, sortBy, sumBy, groupBy, pickBy} from 'lodash';

import {
  EVENT_VALUES,
  ASSISTANCE_USAGES,
  NO_ASSISTANCE_USAGES_FULL,
} from '../../../../lib/analytics';

export const getTotalNumberFromValue = (
  values: Array<{value: number}> = []
): number => {
  if (values.length === 0) {
    return 0;
  }

  return values.map(({value}) => value).reduce((acc, a) => acc + a);
};

export const buildBarsDataset = (
  yLabels: Array<string>,
  groupedValues: Object,
  usages: Array<string>
): Array<Object> =>
  usages.map((usage, i) => {
    const {shortTitle, color} = EVENT_VALUES[usage];
    const values = groupedValues[usage] || [];

    return {
      yAxisID: 'responses',
      label: shortTitle,
      backgroundColor: color,
      borderColor: 'transparent',
      data: yLabels.map((date) => ({
        x: date,
        y: values.find((v) => v.date === date)?.value ?? 0,
      })),
    };
  });

export const buildMeanLineDataset = (data: Object): Object => {
  const valuesGroupedByWeek = groupBy(data?.botUsage ?? [], 'date');
  const assistanceByDay = getAssistanceByDay(valuesGroupedByWeek);

  const assistanceByWeek = getAssistanceByWeek(assistanceByDay);

  return {
    yAxisID: 'assistance',
    type: 'line',
    label: 'Assistance',
    data: assistanceByWeek,
    fill: false,
    lineTension: 0.2,
    borderColor: '#ed484e',
    backgroundColor: 'transparent',
  };
};

function getAssistanceByDay(values: Object): Array<Object> {
  return map(values, (usages, date: string) => ({
    date,
    assistanceSum: sumBy(usages, ({grouped, value}) =>
      ASSISTANCE_USAGES.includes(grouped) ? value : 0
    ),
    noAssistanceSum: sumBy(usages, ({grouped, value}) =>
      NO_ASSISTANCE_USAGES_FULL.includes(grouped) ? value : 0
    ),
  }));
}

function getAssistanceByWeek(assistanceByDay: Array<Object>): Array<Object> {
  const groupedByWeek = groupBy(
    assistanceByDay,
    (o) => DateTime.fromISO(o.date).weekNumber
  );

  const fullWeeks = pickBy(groupedByWeek, (a) => a.length === 7);

  const assistanceByWeek = map(fullWeeks, (weekValues) => {
    const totalAssistance = sumBy(weekValues, 'assistanceSum');
    const totalNoAssistance = sumBy(weekValues, 'noAssistanceSum');
    const percentage =
      (totalAssistance / (totalAssistance + totalNoAssistance)) * 100;

    return {
      x: sortBy(weekValues, 'date')[2].date, // Get thursday.. don't judge me..
      y: isNaN(percentage) ? 0 : Math.round(percentage * 100) / 100,
    };
  });

  return sortBy(assistanceByWeek, 'x');
}
