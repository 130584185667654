// @flow
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Container, Row, Col} from 'reactstrap';
import {renderRoutes, matchRoutes} from 'react-router-config';
import {DateTime} from 'luxon';
import {Redirect} from 'react-router-dom';
import {find} from 'lodash';

import SideNav from '../../components/sidenav';
import Modal from '../../components/modal';
import DateButton from '../analytics/components/date-picker/date-button';

export const Freemium = ({
  route,
  onboarding,
}: {
  route: Object,
  onboarding: Array<{id: string, value: string, timestamp: Date}>,
}) => {
  const [modal, setModal] = useState(false);
  const NAV = [
    {
      title: 'Statistiques',
      links: [
        {
          pathname: '/freemium/activity',
          title: 'Activité',
        },
        {
          pathname: '/freemium/intents',
          title: 'Catégories',
        },
        {
          pathname: '/freemium/operators',
          title: 'Conseillers',
        },
        {
          pathname: '/freemium/templates',
          title: 'Modèles de réponse',
        },
      ],
    },
    {
      title: 'Performances',
      links: [
        {
          pathname: '/freemium/ai/annotations/list',
          title: 'Annotations',
        },
      ],
    },
  ];

  const dateRange = getDateRande(onboarding);

  if (matchRoutes(route.routes, window.location.pathname).length === 0) {
    return (
      <Redirect
        to={{
          pathname: '/freemium/activity',
          search: window.location.search,
        }}
      />
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col md="2">
          <DateButton
            dateRange={dateRange}
            handleClick={() => setModal((m) => !m)}
          />
          <SideNav nav={NAV} />
        </Col>
        <Col md="10">{renderRoutes(route.routes, {dateRange})}</Col>
      </Row>
      <Modal
        title="Plage de dates"
        handleConfirm={() => setModal(false)}
        modal={modal}
        setModal={setModal}
        size="md"
      >
        Pour les comptes Freemium, la plage de dates disponible est limitée aux
        30 derniers jours maximum.
      </Modal>
    </Container>
  );
};

export default connect(({firestore}) => ({
  onboarding: firestore?.user?.uiSettings?.onboarding ?? [],
}))(Freemium);

function getDateRande(
  onboarding: Array<{id: string, value: string, timestamp: Date}>
) {
  // try to get the init timestamp from onboarding steps
  // aka the date when we deploy the dedicated model
  const initTimestamp = DateTime.fromJSDate(
    find(onboarding, {
      id: 'FINAL_STEP',
      value: 'enable_freemium',
    })?.timestamp
  );
  // init an array with a minus 30days date
  const startDates = [DateTime.local().minus({day: 30}).startOf('day')];

  if (!initTimestamp.invalid) {
    // if initDate exists, add it to the array in order to take the max of these two dates
    startDates.push(initTimestamp);
  }

  return {
    startDate: DateTime.max(...startDates),
    endDate: DateTime.local().minus({day: 1}).endOf('day'),
  };
}
