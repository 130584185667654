// @flow
import React, {type Node} from 'react';
import firebase from 'firebase/app';
import {connect} from 'react-redux';
import {find} from 'lodash';
import {matchRoutes} from 'react-router-config';
import {Redirect} from 'react-router-dom';

import {isUserFreemium, hasUserFinishedOnboarding} from '../lib/user';

export const ProtectedRoute = ({
  routes,
  setting,
  offline,
  user,
  children,
}: {
  routes: Array<Object>,
  setting: Object,
  offline: boolean,
  user: Object,
  children: Node,
}) => {
  const matchedRoutes = matchRoutes(routes, window.location.pathname);

  const allowFreemium = find(matchedRoutes, {route: {allowFreemium: true}});
  const allowUnAuth = find(matchedRoutes, {route: {allowUnAuth: true}});
  const onlyUnAuth = find(matchedRoutes, {route: {onlyUnAuth: true}});
  const allowOnboarding = find(matchedRoutes, {route: {allowOnboarding: true}});
  const isOnboardingPath = find(matchedRoutes, {route: {path: '/welcome'}});

  if (matchedRoutes.length === 0) {
    return children;
  }

  // Duplicate code (app.js)
  const userSignedIn = offline || Boolean(firebase.auth().currentUser);

  // ------------------
  // User not signed-in
  // ------------------
  if (!userSignedIn) {
    if (allowUnAuth) {
      return children;
    }

    return (
      <Redirect
        to={{
          pathname: '/sign-in',
          search: window.location.search,
        }}
      />
    );
  }

  // ------------------
  // User signed-in
  // ------------------

  // Redirect signed user if he's on only-unAuth page
  if (onlyUnAuth) {
    return (
      <Redirect
        to={{
          pathname: '/home',
          search: window.location.search,
        }}
      />
    );
  }

  // force user to finish onboarding (if he started one) if he didn't finished it
  if (!hasUserFinishedOnboarding(user)) {
    if (allowOnboarding) {
      return children;
    } else {
      return (
        <Redirect
          to={{
            pathname: '/welcome',
            search: window.location.search,
          }}
        />
      );
    }
  }

  // if user has finished onboarding steps, and on onboarding paths (/welcome/*), redirect to /home
  if (isOnboardingPath) {
    return (
      <Redirect
        to={{
          pathname: '/home',
          search: window.location.search,
        }}
      />
    );
  }

  // Redirect freemium user which are not visiting a freemium page
  if (isUserFreemium(setting) && !allowFreemium) {
    return (
      <Redirect
        to={{
          pathname: '/freemium',
          search: window.location.search,
        }}
      />
    );
  }

  return children;
};

export default connect(({data, firestore, app}) => ({
  setting: firestore.setting,
  offline: app.offline,
  user: firestore.user,
}))(ProtectedRoute);
