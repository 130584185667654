// @flow

export const startEndOfDay = ({
  startDate,
  endDate,
}: {
  startDate: Object,
  endDate: Object,
}): {
  startDate: Object,
  endDate: Object,
} => ({
  startDate: startDate.startOf('day'),
  endDate: endDate.endOf('day'),
});

export const getDiffFromRange = ({
  startDate,
  endDate,
}: {
  startDate: Object,
  endDate: Object,
}): {
  startDate: Object,
  endDate: Object,
} => {
  const diffDays = Math.abs(startDate.diff(endDate, 'days').toObject().days);

  return {
    startDate: startDate.minus({day: Math.ceil(diffDays)}),
    endDate: endDate.minus({day: Math.ceil(diffDays)}),
  };
};
