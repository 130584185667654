import React from 'react';
import {Circle} from 'react-feather';
import {Button} from 'reactstrap';
import {useTranslation} from 'react-i18next';

const Status = ({
  enabled,
  edit = false,
  description,
  onClick,
  title,
}: {
  enabled: boolean;
  edit?: boolean;
  description?: string;
  onClick?: () => void;
  title: string;
}) => {
  const {t} = useTranslation();

  return (
    <>
      <div className="d-flex align-items-center">
        <div
          className={`d-flex align-items-center text-${
            enabled ? 'success' : 'danger'
          }`}
        >
          <Circle
            size="12"
            className="mr-2"
            style={{
              fill: `var(--${enabled ? 'success' : 'danger'})`,
              position: 'relative',
              top: '1px',
            }}
          />
          <div className="py-2">{title}</div>
        </div>

        {edit && (
          <Button outline color="hubware" className="ml-auto" onClick={onClick}>
            {enabled ? t('Disable') : t('Enable')}
          </Button>
        )}
      </div>
      {description && <p className="small text-muted">{description}</p>}
    </>
  );
};

export default Status;
