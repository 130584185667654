// @flow
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {DateTime} from 'luxon';
import {v4 as uuidv4} from 'uuid';
import {find} from 'lodash';

import {Card} from '../../../../components/card';
import TabNav from '../../../../components/tab-nav';
import {getReplies} from '../../../../lib/firestore/replies';
import {
  getAllTemplates,
  type TEMPLATE_INTERFACE,
} from '../../../../selectors/templates';
import Reply from './reply';

const Replies = ({
  getReplies,
  templates,
}: {
  getReplies: Function,
  templates: Array<TEMPLATE_INTERFACE>,
}) => {
  const [selectedTab, setTab] = useState('scheduled');
  const [replies, setReplies] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleRepliesUpdate = (replies) => {
    setReplies(replies);
    setLoading(false);
  };

  useEffect(() => {
    let unsubscribe;

    const fetchReplies = async () => {
      unsubscribe = await getReplies(
        {
          startDate: DateTime.local().startOf('day').toJSDate(),
        },
        handleRepliesUpdate
      );
    };

    fetchReplies();

    return () => {
      unsubscribe();
    };
  }, [getReplies]);

  const filteredList = {
    scheduled: replies.filter(({status}) =>
      ['replying', 'scheduled'].includes(status)
    ),
    success: replies.filter(({status}) => status === 'success'),
    rejected: replies.filter(
      ({status}) => !['success', 'scheduled', 'replying'].includes(status)
    ),
  };

  const tabsMap = {
    scheduled: {
      title: 'Programmées',
      value: filteredList['scheduled'].length,
    },
    success: {
      title: 'Envoyées',
      value: filteredList['success'].length,
    },
    rejected: {
      title: 'Rejetées',
      value: filteredList['rejected'].length,
    },
  };

  return (
    <Card>
      <div className="card-body pb-0">
        <div className="d-flex">
          <div>
            <h5 className="card-title mb-0">Réponse automatiques</h5>
            <div className="text-muted">
              Suivi temps-réel des réponse automatiques de la journée
            </div>
          </div>
        </div>
      </div>
      <TabNav selectedTab={selectedTab} setTab={setTab} tabsMap={tabsMap} />

      {loading && (
        <div className="text-muted text-center card-body">Chargement</div>
      )}

      {!loading && (
        <div>
          {filteredList[selectedTab].map((reply) => (
            <Reply
              key={uuidv4()}
              reply={reply}
              template={find(templates, {id: reply.macroId})}
              selectedTab={selectedTab}
            />
          ))}
          {tabsMap[selectedTab].value === 0 && (
            <p className="text-muted text-center card-body">Aucune réponse</p>
          )}
        </div>
      )}
    </Card>
  );
};

export default connect(
  (state) => ({
    templates: getAllTemplates(state),
  }),
  (dispatch) => ({...bindActionCreators({getReplies}, dispatch)})
)(Replies);
