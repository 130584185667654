import React from 'react';
import {Plus} from 'react-feather';
import {Button} from 'reactstrap';
import {useTranslation} from 'react-i18next';

import {Card} from '../../../../components/card';
import ModalWrapper from './modal-wrapper';
import CustomLogicList from './custom-logic-list';
import useCustomBloc from './useCustomBloc';

const LogicBloc = () => {
  const {t} = useTranslation();
  const {
    handleValidate,
    handleDelete,
    openModal,
    setModal,
    modal,
    getBlocUsage,
    selectBloc,
  } = useCustomBloc();

  return (
    <Card>
      <div className="card-body">
        <h4 className="card-title m-0">{t('Personalized logic blocks')}</h4>
        <hr />

        <div className="d-flex justify-content-between align-items-center">
          <h5 className="m-0">{t('Available blocks')}</h5>
          <Button
            color="hubware"
            id="add-button"
            outline
            onClick={() => openModal()}
          >
            <Plus size="15" className="mr-2" />
            {t('Add')}
          </Button>
        </div>
        <hr />

        <CustomLogicList openModal={openModal} getBlocUsage={getBlocUsage} />

        <ModalWrapper
          handleValidate={handleValidate}
          handleDelete={handleDelete}
          openModal={openModal}
          setModal={setModal}
          modal={modal}
          getBlocUsage={getBlocUsage}
          selectBloc={selectBloc}
        />
      </div>
    </Card>
  );
};

export default LogicBloc;
