// @flow
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {v4 as uuidv4} from 'uuid';
import styled from 'styled-components';
import {map, get} from 'lodash';
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';

import Modal from '../../../../components/modal';
import {getAllIntents} from '../../../../selectors/intents';
import {
  type AssistanceType,
  type TEMPLATE_INTERFACE,
  assistanceTypes,
} from '../../../../selectors/templates';
import {setTemplateAssistance} from '../../../../lib/firestore/templates';

const AssistanceDropdown = ({
  assistance,
  template,
  intents,
  setTemplateAssistance,
  autoReplyAuthorSet,
}: {
  assistance: AssistanceType,
  template: TEMPLATE_INTERFACE,
  intents: Object,
  setTemplateAssistance: Function,
  autoReplyAuthorSet: Array<Number>,
}) => {
  const [modal, setModal] = useState(false);
  const [modalParams, setModalParams] = useState({});

  if (!assistanceTypes[assistance]) {
    return null;
  }

  const handleClick = async (newAssistance: string, force?: boolean) => {
    if (assistance === newAssistance) {
      return;
    }

    if (template.intents.length > 1 && !force) {
      setModalParams({
        prevAssistanceType: assistance,
        nextAssistanceType: newAssistance,
      });
      setModal(true);
      return;
    }

    await setTemplateAssistance(template, newAssistance);
  };

  return (
    <>
      <UncontrolledButtonDropdown>
        <DropdownToggle color="hubware" outline caret>
          {assistanceTypes[assistance]}
        </DropdownToggle>
        <StyledDropdownMenu>
          {map(assistanceTypes, (title, key: string) => (
            <DropdownItem
              disabled={key === 'autoReplied' && !autoReplyAuthorSet}
              active={assistance === key}
              key={uuidv4()}
              onClick={() => handleClick(key)}
            >
              {title}
            </DropdownItem>
          ))}
        </StyledDropdownMenu>
      </UncontrolledButtonDropdown>
      <Modal
        title={'Avertissement'}
        handleConfirm={async () => {
          handleClick(modalParams.nextAssistanceType, true);
          setModal(false);
        }}
        handleCancel={() => setModal(false)}
        modal={modal}
        setModal={setModal}
      >
        <p>
          Attention, vous souhaitez définir le modèle de réponse{' '}
          <code>{template.title}</code> en{' '}
          <b>
            réponse&nbsp;: {assistanceTypes[modalParams.nextAssistanceType]}
          </b>
          .
        </p>
        <p>
          Ce modèle est actuellement paramétré en{' '}
          <b>réponse {assistanceTypes[modalParams.prevAssistanceType]}</b> et
          est utilisé sur les catégories suivante&nbsp;:
        </p>
        <ul>
          {template.intents.map((key) => (
            <li key={uuidv4()}>{get(intents, [key, 'title'], key)}</li>
          ))}
        </ul>

        <p>
          La modification en{' '}
          <b>
            réponse&nbsp;: {assistanceTypes[modalParams.nextAssistanceType]}
          </b>{' '}
          étant définie pour le modèle de réponse,{' '}
          <u>
            le nouveau comportement sera en place pour toutes les catégories
            précédentes
          </u>
          .
        </p>
        <p>Êtes-vous certain de vouloir valider cette modification&nbsp;?</p>
      </Modal>
    </>
  );
};

export default connect(
  (state, {template}) => ({
    intents: getAllIntents(state),
    autoReplyAuthorSet:
      state.firestore?.setting?.autoReplyConfiguration?.authorIds,
  }),
  (dispatch) => ({
    ...bindActionCreators({setTemplateAssistance}, dispatch),
  })
)(AssistanceDropdown);

// TODO: move this as common since it's used elsewhere
const StyledDropdownMenu = styled(DropdownMenu)`
  margin-left: -1px;

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent;
    color: var(--hubware);
    font-weight: bold;
  }
`;
