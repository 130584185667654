// @flow
import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import styled from 'styled-components';

import {Card} from '../../../components/card';
import {EVENT_VALUES} from '../../../lib/analytics';
import Trend from './trend';
import Bubble from './bubble';
import LoadingState from './loading-state';

const PARAMS = {
  height: 100,
  width: 100,
  legend: {display: false},
  options: {
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: ({datasetIndex, index}, {datasets}) =>
          `  ${datasets[datasetIndex].data[index]} réponse${
            datasets[datasetIndex].data[index] > 1 ? 's' : ''
          }`,
      },
    },
    cutoutPercentage: 90,
  },
};

const AnalyticDoughnuts = ({
  usage,
  labels,
  datasets,
  historyVal,
  historyRange,
  isLoading,
}: {
  usage: string,
  labels: Array<string>,
  datasets: Array<{data: Array<number>}>,
  historyVal: number,
  historyRange: {startDate: Date, endDate: Date},
  isLoading: boolean,
}) => {
  const {title, symbol, color} = EVENT_VALUES[usage];
  const val1 = datasets[0].data[0];
  const val2 = datasets[0].data[1];
  const ratio = Math.round((val1 / (val1 + val2)) * 100);

  let child;

  if (isLoading) {
    child = <LoadingState />;
  } else if (!val1 && !val2) {
    child = <div className="no-data">Aucune donnée</div>;
  } else {
    child = (
      <StyledDoughnut>
        <StyledDisplay>
          {ratio}
          <span>%</span>
        </StyledDisplay>

        <Doughnut {...PARAMS} data={{labels, datasets}} />
      </StyledDoughnut>
    );
  }

  return (
    <Card className="mb-4">
      <div className="card-body">
        <div className="d-flex align-items-center mb-2">
          <div className="text-muted">{title} utilisées</div>
          <Bubble color={color} symbol={symbol} />
        </div>
        <div style={{height: '120px'}}>{child}</div>
        <StyledTrendWrapper>
          <Trend
            value={ratio}
            percent={true}
            historyVal={historyVal}
            historyRange={historyRange}
          />
        </StyledTrendWrapper>
      </div>
    </Card>
  );
};

export default AnalyticDoughnuts;

const StyledDisplay = styled.div.attrs({
  className: 'text-center',
})`
  font-size: 2.7rem;
  font-weight: 300;
  left: 0;
  line-height: 1.2;
  position: absolute;
  right: 0;

  > span {
    font-size: 48%;
  }
`;

const StyledTrendWrapper = styled.div`
  bottom: 1rem;
  position: absolute;
  right: 1rem;
`;

const StyledDoughnut = styled.div.attrs({
  className: 'd-flex align-items-center',
})`
  > canvas {
    z-index: 1;
  }
`;
