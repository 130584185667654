// @flow
import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Form, FormGroup, Label, Input} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';

import Modal from '../../../../components/modal';
import {addAction} from '../../../../lib/firestore/actions';

export default withRouter(
  ({
    modal,
    setModal,
    history,
  }: {
    modal: boolean,
    setModal: (x: boolean) => void,
    history: Object,
  }) => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [isLoading, setIsloading] = useState(false);
    const [description, setDescription] = useState('');

    useEffect(() => {
      setTitle('');
      setDescription('');
      setIsloading(false);
    }, []);

    const handleSubmit = async (e) => {
      e.preventDefault();
      const form = document.getElementById('add-action-form');
      //$FlowFixMe
      if (!form.reportValidity()) {
        return;
      }
      setIsloading(true);
      const id = uuidv4();
      await dispatch(
        addAction({
          id,
          title,
          description,
          action: {},
        })
      );
      setIsloading(false);
      setModal(false);
      history.push({
        pathname: `/config/actions/${id}/details`,
        search: window.location.search,
      });
    };

    return (
      <Modal
        size="md"
        modal={modal}
        setModal={setModal}
        handleConfirm={handleSubmit}
        handleCancel={() => {
          setTitle('');
          setDescription('');
          setModal(false);
        }}
        title="Nouvelle action"
        isLoading={isLoading}
      >
        <Form onSubmit={handleSubmit} id="add-action-form">
          <FormGroup>
            <Label for="title">Titre</Label>
            <Input
              autoFocus={true}
              type="text"
              id="title"
              name="title"
              value={title}
              placeholder="Titre..."
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="text"
              id="description"
              name="description"
              placeholder="Description..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
          {/* we add a hidden submit button in orer to trigger form HTML5 validation */}
          <button type="submit" className="d-none" />
        </Form>
      </Modal>
    );
  }
);
