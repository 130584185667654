// @flow
import React from 'react';

import {type USER_INTERFACE} from '../../../../selectors/user';
import icon from './icon.svg';

const Google = ({user}: {user: USER_INTERFACE}) => {
  const googleProvider = user.providers.find(({id}) => id === 'google.com');

  if (!googleProvider) {
    return null;
  }

  return (
    <>
      <hr />
      <h5>Compte Google</h5>
      <div>
        <p className="d-flex align-items-center">
          <img
            src={icon}
            alt="google-icon"
            height="25px"
            width="22px"
            className="mr-2"
          />
          Votre compte est lié avec une authentification Google (compte
          <strong className="ml-1">{googleProvider.email}</strong>)
        </p>
      </div>
    </>
  );
};

export default Google;
