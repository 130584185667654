// @flow
import React, {type Node} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const CustomModal = ({
  title,
  handleConfirm,
  handleCancel,
  children,
  modal,
  setModal,
  size = 'lg',
  isLoading = false,
  unabled = false,
  confirmTitle,
}: {
  title: string,
  handleConfirm?: Function,
  handleCancel?: Function,
  children: Node,
  modal: boolean,
  setModal: (x: boolean) => void,
  size?: string,
  isLoading?: boolean,
  unabled?: boolean,
  confirmTitle?: string,
}) => {
  const {t} = useTranslation();

  const handleToggle = () => {
    if (!isLoading) {
      setModal(!modal);
    }
  };

  return (
    <Modal isOpen={modal} toggle={handleToggle} size={size}>
      <ModalHeader toggle={handleToggle}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        {handleCancel && (
          <Button
            color="secondary"
            outline
            onClick={handleCancel}
            disabled={isLoading}
          >
            {t('Cancel')}
          </Button>
        )}{' '}
        {handleConfirm && (
          <Button
            color="hubware"
            onClick={handleConfirm}
            disabled={isLoading || unabled}
          >
            {confirmTitle ? confirmTitle : t('Confirm')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CustomModal;
