// @flow
import React from 'react';
import styled from 'styled-components';
import {Col} from 'reactstrap';

const Stepper = ({steps, activeStep}: {steps: number, activeStep: number}) => (
  <Col className="flex-column d-none d-md-flex" md="1">
    {new Array(steps).fill(1).map((_, i) => (
      <StyledStep active={activeStep >= i} key={i}>
        {i + 1}
      </StyledStep>
    ))}
    <StyledProgress activeStep={steps} steps={steps} />
    <StyledProgress active activeStep={activeStep} steps={steps} />
    <StyledCurrent activeStep={activeStep} steps={steps} />
  </Col>
);

export default Stepper;

const StyledStep = styled.div`
  background-color: ${({active}) =>
    active ? 'var(--hubware)' : 'var(--gray-300)'};
  border-radius: 35px;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  height: 35px;
  line-height: 17px;
  margin: auto;
  padding: 9px;
  position: relative;
  text-align: center;
  user-select: none;
  width: 35px;
  z-index: 100;
`;

const StyledProgress = styled.div`
  background-color: ${({active}) =>
    active ? 'var(--hubware)' : 'var(--gray-300)'};
  left: calc(50% - 1px);
  height: calc(
    ${({activeStep, steps}) =>
      Math.min(
        activeStep * (100 / steps) + 100 / steps / 2,
        100 - 100 / steps
      )}%
  );
  position: absolute;
  top: ${({steps}) => 100 / steps / 2}%;
  transition: height 0.7s ease-in 0.1s;
  width: 2px;
`;

const StyledCurrent = styled.div`
  background-color: #fbfbfb;
  border: 2px solid var(--hubware);
  border-radius: 47px;
  height: 47px;
  left: calc(50% - 47px / 2);
  margin: auto;
  position: absolute;
  top: calc(
    ${({activeStep, steps}) => activeStep * (100 / steps) + 100 / steps / 2}% -
      47px / 2
  );
  transition: top 0.7s ease-in-out;
  width: 47px;
  z-index: 99;
`;
