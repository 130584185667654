// @flow
import React, {type Node} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import {Check} from 'react-feather';
import {withRouter} from 'react-router-dom';

import {type TEMPLATE_INTERFACE} from '../../../../selectors/templates';

export default withRouter(
  ({
    history,
    template,
    isSelected,
    edit,
    toggleSelected,
    children,
  }: {
    history: Object,
    template: TEMPLATE_INTERFACE,
    isSelected: boolean,
    edit: boolean,
    toggleSelected: Function,
    children?: Node,
  }) => {
    if (!isSelected && !edit) {
      return null;
    }

    const handleClick = () => {
      const {id} = template;
      if (edit) {
        toggleSelected(id);
      } else {
        history.push({
          pathname: `/config/templates/${id}/details`,
          search: window.location.search,
        });
      }
    };

    return (
      <tr height="55px">
        {edit && (
          <td
            className="py-2 align-middle clic"
            width="48px"
            onClick={handleClick}
          >
            <StyledCheck className={classnames({isSelected})}>
              <Check size="20" className="text-white" />
            </StyledCheck>
          </td>
        )}
        <td className="align-middle w-100 clic" onClick={handleClick}>
          {template.title}
        </td>
        {children}
      </tr>
    );
  }
);

const StyledCheck = styled.div.attrs({
  className: 'text-center',
})`
  border: 1px solid var(--gray-300);
  border-radius: 27px;
  height: 27px;
  width: 27px;

  &.isSelected {
    color: #fff;
    background-color: var(--teal);
    border-color: var(--teal);
  }

  > svg {
    margin-top: 1px;
    stroke-width: 3px;
    vertical-align: middle;
  }
`;
