// @flow
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Modal, ModalHeader, ModalBody, Button} from 'reactstrap';
import {Plus} from 'react-feather';

import {getIntentsListWithTemplates} from '../../../../selectors/intents';
import AddIntentModal from './modal';

const AddButton = () => {
  const [modal, setModal] = useState(false);

  const intents = useSelector(getIntentsListWithTemplates).filter(
    ({templates}) => templates.length === 0
  );

  if (intents.length === 0) {
    return null;
  }

  return (
    <div className="position-relative">
      <Button color="hubware" onClick={() => setModal(true)}>
        <Plus size="15" className="mr-2" />
        Nouvelle catégorie
      </Button>
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <ModalHeader
          toggle={() => setModal(!modal)}
          style={{borderBottomWidth: 0}}
        >
          Catégories
        </ModalHeader>
        <ModalBody>
          <AddIntentModal intents={intents} />
        </ModalBody>
      </Modal>

      <small
        className="text-muted d-block text-right"
        style={{position: 'absolute', right: 0}}
      >
        {intents.length} catégorie
        {intents.length > 1 && 's'} disponibles
      </small>
    </div>
  );
};

export default AddButton;
