// @flow
import React, {useState} from 'react';
import {Row, Col} from 'reactstrap';
import {useQuery, useManualQuery} from 'graphql-hooks';
import {chain, groupBy, keyBy, sumBy} from 'lodash';
import {v4 as uuidv4} from 'uuid';

import {
  ASSISTANCE_USAGES,
  REFUSED_ASSISTANCE_USAGES,
} from '../../../../lib/analytics';

import {CardError} from '../../../../components/card';
import NumberChart from '../../components/number';
import BarChart from '../../components/bar';
import getDataHook from '../../../../lib/graphQL';
import ButtonsGroup from '../../components/buttons-group';
import {
  buildBarsDataset,
  buildMeanLineDataset,
  getTotalNumberFromValue,
} from './utils';

const AnalyticsActivity = ({
  dateRange,
  diffDateRange,
}: {
  dateRange: Object,
  diffDateRange: Object,
}) => {
  // in-view controller
  const [view, setView] = useState('assistance');
  const views = [
    {key: 'assistance', title: 'Assistance utilisée'},
    {key: 'noAssistance', title: 'Assistance refusée'},
  ];

  const {loading, error, data} = useQuery(
    ...getDataHook('getBotUsage', {date: dateRange})
  );
  const [fetchHistoryData, historyState] = useManualQuery(
    ...getDataHook('getBotUsageTrend', {date: diffDateRange})
  );

  if (error || historyState.error) {
    return <CardError />;
  }

  if (
    !loading &&
    !historyState.data &&
    !historyState.loading &&
    !historyState.error
  ) {
    fetchHistoryData();
  }

  // set const with all interval values
  const valuesGroupedByUsage = groupBy(data?.botUsage ?? [], 'grouped');
  const historyValuesGroupedByUsage = keyBy(
    historyState.data?.botUsage ?? [],
    'key'
  );

  // set const with uniq yvalues (here time values)
  const yLabels = chain(data)
    .get('botUsage')
    .map(({date}) => date)
    .uniq()
    .value();

  const displayedUsages =
    view === 'assistance' ? ASSISTANCE_USAGES : REFUSED_ASSISTANCE_USAGES;

  return (
    <>
      <Row>
        <ButtonsGroup view={view} setView={setView} views={views} />
      </Row>

      <Row>
        <Col>
          <BarChart
            labels={yLabels}
            isLoading={loading}
            yaxes={['responses', 'assistance']}
            datasets={[
              buildMeanLineDataset(data),
              ...buildBarsDataset(
                yLabels,
                valuesGroupedByUsage,
                displayedUsages
              ),
            ].filter((dataset) => sumBy(dataset.data, 'y') > 0)}
          />
        </Col>
      </Row>

      <Row>
        {displayedUsages.map((usage) => (
          <Col key={uuidv4()}>
            <NumberChart
              usage={usage}
              isLoading={loading}
              historyRange={diffDateRange}
              value={getTotalNumberFromValue(valuesGroupedByUsage[usage])}
              historyVal={historyValuesGroupedByUsage[usage]?.value}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default AnalyticsActivity;
