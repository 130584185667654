import {RESET_STORE} from '../actions/types/app-types';
import {
  DataActionsTypes,
  SET_EVAL_JOB,
  SET_PROVIDER,
  SET_SYSTEMS_STATUS,
  SET_INTENTS,
} from '../actions/types/data-types';

import {DataState} from './types/data-types';

const initialState: DataState = {
  provider: undefined,
  ai: {
    evalJobs: {
      current: undefined,
      previous: undefined,
    },
  },
  systemsStatus: undefined,
  intents: {},
};

export function dataReducer(
  state = initialState,
  action: DataActionsTypes
): DataState {
  switch (action.type) {
    case SET_PROVIDER: {
      return {
        ...state,
        provider: action.provider,
      };
    }
    case SET_SYSTEMS_STATUS: {
      return {
        ...state,
        systemsStatus: action.systemsStatus,
      };
    }

    case SET_INTENTS: {
      return {
        ...state,
        intents: action.intents,
      };
    }

    case SET_EVAL_JOB: {
      return {
        ...state,
        ai: {
          ...state.ai,
          evalJobs: {
            ...state.ai.evalJobs,
            [action.key]: action.evalJob,
          },
        },
      };
    }
    default:
      return state;
  }
}

const rootReducer = (state = initialState, action): DataState => {
  if (action.type === RESET_STORE) {
    if (!action.stores || action.stores.includes('data')) {
      state = undefined;
    }
  }
  return dataReducer(state, action);
};

export default rootReducer;
