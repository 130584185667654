import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createLogger} from 'redux-logger';
import {createStore, applyMiddleware, compose} from 'redux';
import {Router} from 'react-router-dom';
import {renderRoutes} from 'react-router-config';
import {createBrowserHistory} from 'history';
import thunk from 'redux-thunk';
import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import './styles/custom-bootstrap.scss';
import './styles/main.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'animate.css';
import './i18n';

import routes from './routes';
import reducer from './reducer';
import * as serviceWorker from './register-service-worker';
import {GAInit} from './lib/google-analytics.js';
import {getAppSetting} from './lib/utils';

if (getAppSetting('production')) {
  firebase.initializeApp(require('./config/production.json'));
} else if (getAppSetting('staging')) {
  firebase.initializeApp(require('./config/staging.json'));
} else if (window.location.hostname === 'app.hubwa.re') {
  firebase.initializeApp(require('./config/production.json'));
} else {
  firebase.initializeApp(require('./config/production.json'));
}
firebase.firestore();

if (getAppSetting('debug')) {
  window.firebase = firebase;
}

// Middlewares
// –––––
const logger = createLogger({
  predicate: (getState, action) => getAppSetting('debug'),
});
const middlewares = [thunk, logger];

// History listener - Google Analytics
// –––––
const history = createBrowserHistory();
GAInit(history);

// Store
// –––––
const store = createStore(reducer, compose(applyMiddleware(...middlewares)));
ReactDOM.render(
  <Provider store={store}>
    <Router basename="/" history={history}>
      {renderRoutes(routes)}
    </Router>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
