import React from 'react';
import styled from 'styled-components';
import {Row, Col} from 'reactstrap';
import {sample} from 'lodash';

import Debugger from '../../components/architecture/debugger';
import LOADERS from './loaders';

const loader = sample(LOADERS)();

const Loading = () => (
  <CenteredRow>
    <Col>
      <div className="mb-3">{loader}</div>
      <span id="loading-text">Chargement</span>
    </Col>
    <Debugger />
  </CenteredRow>
);

export default Loading;

const CenteredRow = styled(Row).attrs({
  className: 'align-items-center text-center m-0',
})`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  #loading-text {
    opacity: 0.7;
  }
`;
