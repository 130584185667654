// @flow
import React from 'react';
import {connect} from 'react-redux';
import {get, toUpper} from 'lodash';
import styled from 'styled-components';
import {v4 as uuidv4} from 'uuid';

import {Card} from '../../../components/card';
import {getAllIntents} from '../../../selectors/intents';

export const PredictionAnswer = ({
  intentsList,
  predictions,
}: {
  intentsList: Object,
  predictions: Array<Object>,
}) => (
  <div className="mt-3">
    {predictions.length > 0 ? (
      <Card>
        {predictions.map(({subject, body, intents, language}) => (
          <StyledCardBody key={uuidv4()}>
            <div className="mb-3 d-flex align-items-center justify-content-between">
              <h6 className="m-0">
                Catégorie détectée :{' '}
                {(intents &&
                  intents
                    .map((i) => getIntentTitle(i, intentsList))
                    .join(' - ')) ||
                  getIntentTitle('no_intent', intentsList)}
              </h6>
              {language !== '??' && (
                <span
                  className="badge badge-hubware"
                  style={{fontSize: '1.1rem'}}
                >
                  {toUpper(language)}
                </span>
              )}
            </div>

            <div className="d-flex align-items-start">
              <span
                className="text-muted mr-3 mb-0"
                style={{whiteSpace: 'pre-line'}}
              >
                Sujet : {subject}
              </span>
            </div>
            <div className="d-flex align-items-start">
              <span className="mr-3 mb-0  text-muted">Message : {body}</span>
            </div>
          </StyledCardBody>
        ))}
      </Card>
    ) : (
      <StyledCard>
        <StyledCardBody>
          <h6 className="card-title m-0 text-center text-muted">
            <span>Aucune analyse lancée</span>
          </h6>
        </StyledCardBody>
      </StyledCard>
    )}
  </div>
);

export default connect((state) => ({
  intentsList: getAllIntents(state),
}))(PredictionAnswer);

function getIntentTitle(key: string, intentsList: Object): string {
  return get(intentsList, [key, 'title'], key);
}

const StyledCardBody = styled.div.attrs({className: 'card-body'})`
  position: relative;
  :not(:first-child)::after {
    background-color: var(--gray-300);
    content: '';
    height: 1px;
    left: 25%;
    position: absolute;
    top: 0;
    width: 50%;
  }
`;

const StyledCard = styled(Card)`
  background: transparent;
  border: none;
`;
