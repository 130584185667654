// @flow
import React from 'react';
import {Row, Col} from 'reactstrap';
import {useQuery} from 'graphql-hooks';
import {chain} from 'lodash';

import getDataHook from '../../../../lib/graphQL';
import {CardError} from '../../../../components/card';
import BarChart from '../../../analytics/components/bar';
import NumberChart from '../../../analytics/components/number';
import {
  buildBarsDataset,
  buildMeanLineDataset,
  getTotalCount,
  getIntentsCount,
  getTotalCoverage,
} from './utils';

const FreemiumActivity = ({dateRange}: {dateRange: Object}) => {
  const {loading, error, data} = useQuery(
    ...getDataHook('getPredictionActivity', {date: dateRange})
  );

  if (error) {
    return <CardError />;
  }

  // set const with uniq yvalues (here time values)
  const yLabels = chain(data)
    .get('predictions')
    .map(({date}) => date)
    .uniq()
    .value();

  return (
    <>
      <BarChart
        yaxes={['predictions', 'coverage']}
        labels={yLabels}
        isLoading={loading}
        datasets={[
          buildMeanLineDataset(yLabels, data),
          ...buildBarsDataset(yLabels, data),
        ]}
      />

      <Row>
        <Col>
          <NumberChart
            usage="Messages analysés"
            isLoading={loading}
            value={getTotalCount(data)}
          />
        </Col>
        <Col>
          <NumberChart
            usage="Couverture globale"
            unit="%"
            isLoading={loading}
            value={getTotalCoverage(yLabels, data)}
          />
        </Col>
        <Col>
          <NumberChart
            usage="Catégories"
            isLoading={loading}
            value={getIntentsCount(data)}
          />
        </Col>
      </Row>
    </>
  );
};

export default FreemiumActivity;
