// @flow
import React from 'react';
import styled from 'styled-components';
import {Badge, Input} from 'reactstrap';

const Filters = {
  toggle: ({
    column: {filterValue, setFilter, Header},
  }: {
    column: {filterValue: number, setFilter: Function, Header: string},
  }) => (
    <StyledBadge
      color="hubware"
      pill
      onClick={() => {
        setFilter(filterValue ? 0 : 1);
      }}
      className={filterValue ? 'active' : ''}
    >
      {Header}
    </StyledBadge>
  ),
  input: ({
    column: {filterValue, setFilter, Header},
  }: {
    column: {filterValue?: string, setFilter: Function, Header: string},
  }) => (
    <StyledInput
      bsSize="sm"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`${Header}...`}
    />
  ),
  select: SelectFilter,
};

export default Filters;

const StyledBadge = styled(Badge).attrs({
  className: 'clic',
})`
  border: 1px solid var(--hubware);
  &:not(.active) {
    background: #fff;
    color: var(--hubware);
  }
`;

const StyledInput = styled(Input)`
  border-radius: 10rem;
  font-size: 80%;
  height: 1.5rem;
  padding: 0.55rem 0.5rem;
`;

function SelectFilter({
  column: {filterValue, setFilter, Header, preFilteredRows, id},
}: {
  column: {
    filterValue: any,
    setFilter: Function,
    Header: string,
    preFilteredRows: Array<Object>,
    id: string,
  },
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      row.values[id].split(' • ').map((i) => options.add(i));
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <StyledSelect
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">{Header}...</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </StyledSelect>
  );
}

const StyledSelect = styled.select`
  background: #fff;
  border: 1px solid #ced4da;
  color: var(--gray);
  font-size: 85%;
  font-weight: 100;
  max-width: 20rem;

  &:focus {
    outline: 0;
  }
`;
