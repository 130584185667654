// @flow
import React, {useMemo, useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'reactstrap';
import {Redirect} from 'react-router-dom';
import {findLastIndex} from 'lodash';

import Stepper from './stepper';
import Step from './steps-components/step';
import {getSteps} from './utils';

export const Steps = ({user}: {user: Object}) => {
  const prevActiveStepRef = useRef();
  const steps = useMemo(() => getSteps(user.uiSettings.onboarding || []), [
    user.uiSettings.onboarding,
  ]);
  const activeStep = findLastIndex(steps, 'done') + 1;

  useEffect(() => {
    prevActiveStepRef.current = activeStep;
  }, [activeStep]);
  const prevActiveStep = prevActiveStepRef.current;

  if (activeStep === -1) {
    return (
      <Redirect
        to={{
          pathname: '/home',
          search: window.location.search,
        }}
      />
    );
  }

  return (
    <div className="container-fluid py-4 h-100 w-100">
      <Row className="h-100 w-100">
        <Stepper steps={steps.length} activeStep={activeStep} />
        <Col className="flex-column">
          {steps.map((step, index) => (
            <Step
              step={step}
              index={index}
              key={index}
              activeStep={activeStep}
              prevActiveStep={prevActiveStep}
            />
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default connect(({firestore}) => ({
  user: firestore.user,
}))(Steps);
