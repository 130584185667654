// @flow

export const buildAnalytics = (values: Object) => ({
  NO_ASSISTANCE: 0,
  MACRO_AUTO_USED: 0,
  SUGGESTION_NOT_USED: 0,
  AUTO_REPLY: 0,
  MACRO_AUTO_NOT_USED: 0,
  SUGGESTION_USED: 0,
  AUTO_REPLY_NOT_USED: 0,
  VARIABLE_SUBSTITUTION_MACRO_COUNT: 0,
  LINK_CLICKED: 0,
  ...values,
});
