// @flow
import React from 'react';
import classnames from 'classnames';
import {Circle} from 'react-feather';
import {v4 as uuidv4} from 'uuid';

import {EVENT_VALUES, ASSISTANCE_USAGES} from '../../../../lib/analytics';

const Legend = ({
  isLoading,
  data,
  totalRefusedAssistance,
}: {
  data: Object,
  isLoading: boolean,
  totalRefusedAssistance: number,
}) => (
  <div className={classnames('my-3', {invisible: isLoading})}>
    {ASSISTANCE_USAGES.map(
      (usage) =>
        data[usage] > 0 && (
          <small className="ml-3" key={uuidv4()}>
            <Circle
              size="14"
              className="mr-2"
              style={{fill: EVENT_VALUES[usage].color, stroke: 'white'}}
            />
            {EVENT_VALUES[usage].title}
          </small>
        )
    )}

    {totalRefusedAssistance > 0 && (
      <small className="ml-3">
        <Circle
          size="14"
          className="mr-2"
          style={{fill: '#00000073', stroke: 'white'}}
        />
        Assistance non utilisée
      </small>
    )}

    <small className="ml-3">
      <Circle
        size="14"
        className="mr-2"
        style={{fill: 'var(--gray-300)', stroke: 'white'}}
      />
      Pas d'assistance à la réponse
    </small>
  </div>
);

export default Legend;
