// @flow
import {get, without, uniq, set} from 'lodash';
import {updateSetting, updateSettingAtPath} from './setting';

import {
  type AssistanceType,
  type FullAssistanceType,
  type TEMPLATE_INTERFACE,
} from '../../selectors/templates';

export const pairTemplates = (
  templatesIds: Array<string>,
  intentKey: string
) => async (dispatch: Function, getState: Function) => {
  if (!intentKey) {
    return;
  }

  const templateParing = getPairing(getState());
  const filteredMap = templateParing.filter(({intent}) => intent !== intentKey);
  const newTemplatePairing = [
    ...filteredMap,
    ...templatesIds.map((id) => ({intent: intentKey, macro: id})),
  ];

  await dispatch(updateSettingAtPath('macroIntentPairs', newTemplatePairing));
  await dispatch(cleanObsoleteTemplates('autoApplyMacroIds'));
  await dispatch(cleanObsoleteTemplates('autoReplyConfiguration.macroIds'));
};

export const setTemplateAssistance = (
  template: TEMPLATE_INTERFACE,
  nextAssistance: AssistanceType
) => async (dispatch: Function, getState: Function) => {
  const prevAssistance = template.assistanceType;
  let setting = {...getState().firestore.setting};
  const threshold = setting?.autoReplyConfiguration?.sabotage?.threshold ?? 0;
  // update setting unsetting assistance.
  // if threshold > 0 && autoreply, then disable prepared reply
  setting = unsetAssistance(setting, prevAssistance, template.id);
  if (prevAssistance === 'autoReplied' && threshold > 0) {
    setting = unsetAssistance(setting, 'prepared', template.id);
  }

  // update setting unsetting assistance.
  // if threshold > 0 && autoreply, then enable prepared reply
  setting = setAssistance(setting, nextAssistance, template.id);
  if (nextAssistance === 'autoReplied' && threshold > 0) {
    setting = setAssistance(setting, 'prepared', template.id);
  }
  return await dispatch(updateSetting(setting));
};

const setAssistance = (
  setting: Object,
  assistance: AssistanceType,
  templateId: string
) => {
  if (assistance === 'prepared' || assistance === 'autoReplied') {
    const path = getAssistancePath(assistance);

    return set(
      {...setting},
      path,
      uniq([...get(setting, path, []), templateId])
    );
  }
  return setting;
};

const unsetAssistance = (
  setting: Object,
  assistance: FullAssistanceType,
  templateId: string
) => {
  if (assistance === 'prepared' || assistance === 'autoReplied') {
    const path = getAssistancePath(assistance);

    return set(
      {...setting},
      path,
      uniq(without(get(setting, path, []), templateId))
    );
  }
  return setting;
};

export const unpairTemplate = (templateId: string) => async (
  dispatch: Function,
  getState: Function
) => {
  if (!templateId) {
    return;
  }

  const filteredPairing = getPairing(getState()).filter(
    ({macro}) => macro !== templateId
  );

  await dispatch(updateSettingAtPath('macroIntentPairs', filteredPairing));
  await dispatch(cleanObsoleteTemplates('autoApplyMacroIds'));
  await dispatch(cleanObsoleteTemplates('autoReplyConfiguration.macroIds'));
};

const getAssistancePath = (assistance: AssistanceType): string => {
  if (assistance === 'prepared') {
    return 'autoApplyMacroIds';
  } else if (assistance === 'autoReplied') {
    return 'autoReplyConfiguration.macroIds';
  }
  return '';
};

const cleanObsoleteTemplates = (path: string) => async (
  dispatch: Function,
  getState: Function
) => {
  const pairedTemplates = getPairing(getState()).map(({macro}) => macro);
  const templatesList = get(getState(), `firestore.setting.${path}`, []);

  const cleanedTemplatesList = templatesList.filter((id) =>
    pairedTemplates.includes(id)
  );

  if (templatesList.length !== cleanedTemplatesList.length) {
    return await dispatch(updateSettingAtPath(path, cleanedTemplatesList));
  }
};

const getPairing = (state) =>
  get(state, 'firestore.setting.macroIntentPairs', []);

// TODO : This function is not really elegant. ask Simon to improve and test it!
export const setSabotagePreparedReplies = () => async (
  dispatch: Function,
  getState: Function
) => {
  let setting = {...getState().firestore.setting};
  const autoReplyIds = get(setting, getAssistancePath('autoReplied'), []);
  if (autoReplyIds.length === 0) {
    return;
  }

  const threshold = setting?.autoReplyConfiguration?.sabotage?.thresold ?? 0;
  // TODO : the following if..else wasn't tested, even running the dashboard in localhost :/
  if (threshold > 0) {
    autoReplyIds.forEach((id) => {
      setting = setAssistance(setting, 'prepared', id);
    });
  } else {
    autoReplyIds.forEach((id) => {
      setting = unsetAssistance(setting, 'prepared', id);
    });
  }

  return await dispatch(updateSetting(setting));
};
