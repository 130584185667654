import React from 'react';
import {Button} from 'reactstrap';
import {Edit2, XCircle, Check} from 'react-feather';

const EditBtn = ({
  isEditing,
  toggleEdit,
  onValidate,
  onCancel,
  disabled,
}: {
  isEditing: boolean;
  toggleEdit: () => void;
  onValidate?: () => void;
  onCancel?: () => void;
  disabled?: boolean;
}) => {
  if (isEditing) {
    return (
      <div className="d-flex align-self-start">
        {onValidate && (
          <Button
            color="hubware"
            className="d-flex align-items-center align-self-start mr-2"
            onClick={onValidate}
            disabled={disabled}
          >
            <>
              <Check size="15" className="mr-2" />
              Valider
            </>
          </Button>
        )}
        <Button
          color="secondary"
          className="d-flex align-items-center align-self-start"
          outline
          onClick={onCancel || toggleEdit}
          disabled={disabled}
        >
          <>
            <XCircle size="15" className="mr-2" />
            Annuler
          </>
        </Button>
      </div>
    );
  }
  return (
    <Button
      color="hubware"
      className="d-flex align-items-center align-self-start"
      outline
      onClick={toggleEdit}
      disabled={disabled}
    >
      <>
        <Edit2 size="15" className="mr-2" />
        Modifier
      </>
    </Button>
  );
};

export default EditBtn;
