// @flow
import React from 'react';
import styled from 'styled-components';
import i18n from '../../../i18n';

import {Button, Col, Row, UncontrolledCollapse as Uc} from 'reactstrap';
import {ArrowRight, Shield} from 'react-feather';
import {rgpdInfo} from './utils';

const RGPD = ({
  isActive,
  updateOnboarding,
}: {
  updateOnboarding: (x?: string) => void,
  isActive: boolean,
}) => {
  const infos = rgpdInfo();

  return (
    <>
      <Row className="w-80">
        {infos.map((info, index) => (
          <Col md="8" key={index}>
            <Row className="ml-1">
              <h4 className="mt-5">
                <Shield
                  color="#ed484e"
                  style={{animationDelay: `${0 + index * 0.3}s`}}
                  className={`${
                    isActive ? 'animate__fadeIn' : ''
                  } animate__animated mr-3`}
                />
                {info.title}
              </h4>
              <Button
                size="sm"
                className="ml-2 mt-5 text-muted"
                color="gray"
                id={`target-${index}`}
              >
                {i18n.t('More...')}
              </Button>
            </Row>

            <Uc key={index} toggler={'target-' + index}>
              <StyledDiv className="lead ml-3 pl-3 text-muted">
                {info.description}
              </StyledDiv>
            </Uc>
          </Col>
        ))}
      </Row>

      <Button
        color="hubware"
        style={{animationDelay: `1.2s`}}
        className={`${
          isActive ? 'animate__fadeInLeft' : ''
        } animate__animated animate__faster mt-5`}
        onClick={() => updateOnboarding()}
      >
        {i18n.t('Next')} <ArrowRight size="22" />
      </Button>
    </>
  );
};

export default RGPD;

const StyledDiv = styled.p`
  border-left: 4px solid var(--gray-300);
`;
