// @flow
import React from 'react';
import {
  Alert,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip as Ut,
} from 'reactstrap';
import {RefreshCw} from 'react-feather';

import OperatorsList from './list';
import useModalOperator from './useModal';

const OperatorModal = ({
  modal,
  setModal,
  selectedValue,
  handleClick,
  enableGroups = true,
}: {
  modal: boolean,
  setModal: Function,
  selectedValue: Object,
  handleClick: Function,
  enableGroups?: boolean,
}) => {
  const {loading, needUpdate, handleRefresh, updatedAt} = useModalOperator();

  return (
    <Modal isOpen={modal} toggle={() => setModal(!modal)} size="md">
      <ModalHeader toggle={() => setModal(!modal)}>
        Sélectionner un conseiller {enableGroups && 'ou groupe de conseillers'}
      </ModalHeader>
      <ModalBody>
        {needUpdate && (
          <Alert color="warning" className="mt-2 p-1" style={{opacity: 0.8}}>
            <small>
              Attention, la mise à jour de la liste des conseillers et des
              groupes de conseillers date de plus de 30 jours.
            </small>
            <Button
              color="link"
              className="p-0 m-0 text-primary ml-1"
              style={{fontSize: '85%'}}
              onClick={() => handleRefresh()}
              disabled={loading}
            >
              {loading ? 'Chargement...' : 'Rafraîchir la liste'}
            </Button>
          </Alert>
        )}

        <div className="d-flex justify-content-center">
          <OperatorsList
            loading={loading}
            selectedValue={selectedValue}
            handleClick={handleClick}
            enableGroups={enableGroups}
          />
        </div>

        {!needUpdate && (
          <div className="d-flex justify-content-end align-items-center">
            <small className="text-muted">Mis à jour {updatedAt}</small>
            <Button
              color="link"
              className="p-0 m-0"
              onClick={() => handleRefresh()}
              disabled={loading}
            >
              <RefreshCw
                id="refresh"
                className={`ml-2 text-hubware ${loading ? 'rotating' : ''}`}
                size={12}
                style={{outline: 0}}
              />
            </Button>
            <Ut target="refresh" placement="top">
              Rafraîchir la liste
            </Ut>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default OperatorModal;
