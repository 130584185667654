// @flow
import React from 'react';
import {UncontrolledTooltip as Ut} from 'reactstrap';

import {Card} from '../../../components/card';
import {EVENT_VALUES} from '../../../lib/analytics';
import Trend from '../components/trend';
import Bubble from '../components/bubble';

const Number = (props: {
  value: number,
  unit?: string,
  usage: string,
  isLoading: boolean,
  historyVal?: number,
  historyRange?: {startDate: Date, endDate: Date},
}) => {
  const {value, usage, isLoading, unit} = props;
  const {title, symbol, color} = EVENT_VALUES?.[usage] ?? {};

  let child;

  if (isLoading) {
    child = (
      <div className="display-3 text-muted animate__animated animate__flash animate__infinite animate__slower">
        <span style={{fontSize: '2rem'}}>...</span>
      </div>
    );
  } else {
    child = (
      <>
        <div
          id={`value-${usage.split(' ').join('')}`}
          className="display-3 clic"
        >
          {value < 1000 ? value : (value / 1000).toFixed(1) + 'k'}
          {unit}
        </div>
        {value > 1000 && (
          <Ut target={`value-${usage.split(' ').join('')}`}> {value} </Ut>
        )}
        <Trend {...props} />
      </>
    );
  }

  return (
    <Card className="mb-4">
      <div className="card-body">
        <div className="d-flex align-items-center">
          <div className="text-muted">{title || usage}</div>
          {symbol && <Bubble color={color} symbol={symbol} />}
        </div>
        <div className="d-flex justify-content-between">{child}</div>
      </div>
    </Card>
  );
};

export default Number;
