// @flow
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// $FlowFixMe
import GButton from 'react-google-button';
import {FormGroup, Input, Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import {signInWithGoogle, signUpWithEmail} from '../../lib/firebase';
import {useTranslation} from 'react-i18next';

import {getFromZendesk} from '../../lib/utils';
import {setUiState} from '../../actions/app';
import SignWrapper from './sign-wrapper';

export const SignUp = ({
  debug = false,
  setUiState,
}: {
  debug?: boolean,
  setUiState: (x: Object) => void,
}) => {
  const [loading, setLoading] = useState(false);
  const [invalidForm, setInvalidform] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [form, setForm] = useState({
    displayName: '',
    company: '',
    email: '',
  });

  const {t} = useTranslation();

  const handleSubmit = async (event) => {
    setInvalidform(false);
    setErrorMessage('');
    event.preventDefault();
    setLoading(true);
    await setUiState({signUpForm: form}); // Store user data in uiState in order to fill user object
    await signUpWithEmail(form.email, password).catch((err) => {
      setInvalidform(true);
      setErrorMessage(err.message);
      setLoading(false);
    });
  };

  const isFromZendesk = getFromZendesk(window.location.search);

  return (
    <SignWrapper colClassName="col-md-10">
      {!isFromZendesk && (
        <div className="text-muted text-center m-2 mr-4">
          {t('Already have an account?')}{' '}
          <Link
            to={{
              pathname: '/sign-in',
              search: window.location.search,
            }}
          >
            {t('Log in')}
          </Link>
        </div>
      )}
      {isFromZendesk && (
        <div className="mt-2 mb-4 mx-4 text-center">
          <div className="text-muted mb-2" style={{fontSize: '90%'}}>
            {t(
              'Thank you for starting the installation process from your Zendesk app'
            )}
          </div>
          {t('Complete your registration in 3 minutes')}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Input
            bsSize="lg"
            type="text"
            id="name"
            name="name"
            value={form.displayName}
            onChange={(e) => setForm({...form, displayName: e.target.value})}
            placeholder={t('Name')}
            required
            invalid={invalidForm}
          />
        </FormGroup>
        <FormGroup>
          <Input
            bsSize="lg"
            type="email"
            id="email"
            name="email"
            value={form.email}
            onChange={(e) => setForm({...form, email: e.target.value})}
            placeholder="Email"
            required
            invalid={invalidForm}
          />
        </FormGroup>
        <FormGroup>
          <Input
            bsSize="lg"
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('Password')}
            required
            invalid={invalidForm}
          />
        </FormGroup>
        <FormGroup>
          <Input
            bsSize="lg"
            type="text"
            id="company"
            name="company"
            value={form.company}
            onChange={(e) => setForm({...form, company: e.target.value})}
            placeholder={t('Company')}
            required
            invalid={invalidForm}
          />
          <div className="invalid-feedback">{errorMessage}</div>
        </FormGroup>
        <FormGroup>
          <Button
            color="hubware"
            size="lg"
            className="w-100"
            disabled={loading}
          >
            {t('Sign up')}
          </Button>
        </FormGroup>
      </form>

      {debug && (
        <>
          <hr style={{margin: '30px 0 25px'}} />
          <GButton
            className="w-100"
            onClick={signInWithGoogle}
            type="light"
            label={t('Sign in with Google')}
          />
        </>
      )}
    </SignWrapper>
  );
};

export default connect(
  ({app}) => ({
    debug: app.debug,
  }),
  (dispatch) => ({
    ...bindActionCreators({setUiState}, dispatch),
  })
)(SignUp);
