// @flow
import {chain, sumBy, reduce, find} from 'lodash';

export const getTotalResponses = (
  data: Array<Object>,
  intents: Object
): number => sumBy(data, (d) => (intents[d.aggregation.intent] ? d.value : 0));

export const getTopIntentsPerTickets = (
  data: Array<Object>,
  intents: Object
): Array<{key: string, value: number}> =>
  groupedByIntent(data, intents)
    .map((values, key) => ({
      key: intents?.[key]?.title || key,
      value: sumBy(values, 'value'),
    }))
    .sortBy(['value'])
    .reverse()
    .value();

export const getTotalIntents = (data: Array<Object>, intents: Object): number =>
  groupedByIntent(data, intents).keys().value().length;

export const getTopIntentsPerTemplates = (
  data: Array<Object>,
  intents: Object
): Array<{key: string, value: number}> =>
  groupedByIntent(data, intents)
    .map((values, key) => ({
      key: intents?.[key]?.title || key,
      value: getTemplatesRatioPercent(values),
    }))
    .sortBy(['value'])
    .reverse()
    .value();

export const getTotalTemplatesUsage = (data: Array<Object>): number =>
  getTemplatesRatioPercent(data);

export const getTableHeaders = (
  data: Array<Object>,
  intents: Object
): Array<{
  Header: string,
  accessor: string,
}> => [
  {
    Header: 'Titre',
    accessor: 'title',
  },
  ...groupedByIntent(data, intents)
    .map((values, key) => ({
      key,
      value: sumBy(values, 'value'),
    }))
    .sortBy(['value'])
    .reverse()
    .map(({key}) => ({
      Header: intents?.[key]?.title || key,
      accessor: key,
    }))
    .value(),
];

export const getTableData = (
  data: Array<Object>,
  allTemplates: Array<Object>
): Array<Object> =>
  chain(data)
    .groupBy('aggregation.macroid')
    .map((values: Array<Object>, id: string) => ({
      id: id === 'null' ? undefined : id, // Hack to avoid having the zendesk link :/
      title: find(allTemplates, {id})?.title ?? id,
      // $FlowFixMe
      ...reduce(
        values,
        (agg, v) => ({
          ...agg,
          [v.aggregation.intent]: v.value,
        }),
        {}
      ),
    }))
    .value();

function groupedByIntent(data: Array<Object>, intents: Object) {
  return chain(data)
    .groupBy('aggregation.intent')
    .pickBy((_, key) => intents?.[key]); // filter only on listed intents
}

function getTemplatesRatioPercent(values: Array<Object>) {
  const v = reduce(
    values,
    (agg, n) => {
      const k = n.aggregation.macroid ? 'templates' : 'noTemplates';
      // $FlowFixMe
      return {...agg, [k]: agg[k] + n.value};
    },
    {templates: 0, noTemplates: 0}
  );

  const percent = v.templates / (v.noTemplates + v.templates);
  return isNaN(percent) ? 0 : Math.round(percent * 100);
}
