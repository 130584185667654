// @flow
import React, {useState} from 'react';
import {useStore} from 'react-redux';
import {Button} from 'reactstrap';
import {Plus} from 'react-feather';
import {get} from 'lodash';

import Modal from './modal';

const AddButton = () => {
  const store = useStore();
  const [modal, setModal] = useState(false);

  if (!get(store.getState(), 'firestore.setting.salesforce')) {
    return null;
  }

  return (
    <div>
      <Button color="hubware" onClick={() => setModal(true)}>
        <Plus size="15" className="mr-2" />
        Nouvelle réponse
      </Button>
      <Modal modal={modal} setModal={setModal} />
    </div>
  );
};

export default AddButton;
