// @flow
import React, {useState, useEffect, createRef} from 'react';
import styled from 'styled-components';
import {InputGroup, InputGroupText, InputGroupAddon, Button} from 'reactstrap';
import {ExternalLink} from 'react-feather';
import {useTranslation} from 'react-i18next';

import {getFromZendesk} from '../../../../lib/utils';
import BlockedPopupInfo from './blocked-popup-info';

const Form = ({
  onClick,
  isActive,
  domain = '',
}: {
  onClick: (x: string) => void,
  isActive: boolean,
  domain: string,
}): any => {
  const [nodeRef] = useState(createRef());
  const [input, setInput] = useState(domain);
  const [clicked, setClicked] = useState(false);

  const {t} = useTranslation();

  const zendeskDomain = getFromZendesk(window.location.search);

  useEffect(() => {
    setInput(domain || zendeskDomain || '');
  }, [domain, zendeskDomain]);

  useEffect(() => {
    if (isActive) {
      nodeRef.current && nodeRef.current.focus();
      window.onbeforeunload = () => true; // prevent closing window
    } else {
      window.onbeforeunload = undefined; // allow closing window
    }
  }, [isActive, nodeRef]);

  const handleClick = () => {
    onClick(input);
    setClicked(true);
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()} className="py-4">
        <StyledInputGroup>
          <input
            ref={nodeRef}
            placeholder="sous-domaine"
            className="form-control-lg form-control"
            onChange={(e) => setInput(cleanInput(e.target.value))}
            value={input}
            required
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>.zendesk.com</InputGroupText>
          </InputGroupAddon>
          <InputGroupAddon
            addonType="append"
            className={
              input !== ''
                ? 'animate__animated animate__fadeInLeft animate__faster'
                : 'invisible'
            }
          >
            <Button
              color="hubware"
              className="d-flex align-items-center"
              onClick={handleClick}
              disabled={!input === ''}
            >
              {t('Autorize Hubware')}{' '}
              <ExternalLink size="18" className="ml-3" />
            </Button>
          </InputGroupAddon>
        </StyledInputGroup>
      </form>

      <BlockedPopupInfo domain={domain} clicked={clicked} />
    </>
  );
};

export default Form;

const StyledInputGroup = styled(InputGroup)`
  display: inline-flex;
  width: auto;
  .input-group-text {
    background-color: #fff;
  }
`;

function cleanInput(str: string): string {
  let input = str;

  return (
    input
      // remove http(s)://
      .replace('https://', '')
      .replace('http://', '')
      // Remove pontential zendesk.com and following path
      .split('.zendesk.com')[0]
  );
}
