// @flow
import React from 'react';
import {connect} from 'react-redux';
import {renderRoutes, matchRoutes} from 'react-router-config';
import {Redirect} from 'react-router-dom';

import {getAllIntents} from '../../../selectors/intents';
import {getMappedTemplatesByIntents} from '../../../selectors/templates';

const Intents = ({
  route,
  intents,
  templatesByIntents,
}: {
  route: Object,
  intents: Object,
  templatesByIntents: Object,
}) => {
  if (matchRoutes(route.routes, window.location.pathname).length === 0) {
    return (
      <Redirect
        to={{
          pathname: '/config/_intents',
          search: window.location.search,
        }}
      />
    );
  }

  return (
    <div className="container">
      {renderRoutes(route.routes, {intents, templatesByIntents})}
    </div>
  );
};

export default connect((state) => ({
  intents: getAllIntents(state),
  templatesByIntents: getMappedTemplatesByIntents(state),
}))(Intents);
