// @flow
import React from 'react';
import styled from 'styled-components';
import {Shield, AlertCircle} from 'react-feather';
import i18n from '../../../../i18n';

const Alerts = ({oAuthError}: {oAuthError: Object}) => (
  <StyledAlert>
    {oAuthError ? (
      <AlertCircle size="50" className="mr-3 text-hubware" />
    ) : (
      <Shield size="50" className="mr-3 text-hubware" />
    )}
    <div>
      {oAuthError && (
        <p>
          <strong>
            {i18n.t(
              'We detected an error while authorizing the installation of the official Hubware extension on your Zendesk instance.'
            )}
            <br />
            {i18n.t(
              'This authorization is required for the installation to be completed'
            )}
            <br />
            {i18n.t(
              'Hubware guarantees access and rectification of data as well as their security and confidentiality at all times'
            )}
            <br />
            {i18n.t(
              'For any questions regarding confidentiality do not hesitate to'
            )}{' '}
            <a
              href="https://hubwa.re/contact-us/"
              target="blank"
              rel="noopener noreferrer"
              className="text-hubware"
            >
              {i18n.t('contact us')}
            </a>
            .
          </strong>
        </p>
      )}
      <p className="m-0 text-muted">
        {i18n.t(
          'You will be redirected to your Zendesk instance to continue activation'
        )}
        <br />
        {i18n.t('Cliking on')} <strong>{i18n.t('"Authorize"')}</strong>,{' '}
        {i18n.t(
          'you allow the installation of the official Hubware extension on Zendesk'
        )}
        .
        <br />
        {i18n.t(
          'For any questions regarding confidentiality do not hesitate to'
        )}{' '}
        <a
          href="https://hubwa.re/contact-us/"
          target="blank"
          rel="noopener noreferrer"
          className="text-hubware"
        >
          {i18n.t('contact us')}
        </a>
        .
      </p>
    </div>
  </StyledAlert>
);

export default Alerts;

const StyledAlert = styled.div.attrs({
  className: 'd-inline-flex align-items-center p-3',
})`
  border: 1px solid var(--hubware);
  border-radius: 3px;
  margin-top: 60px;
`;
