// @flow
import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import classnames from 'classnames';
import {Bell} from 'react-feather';

export const UserNotification = ({
  inList = false,
  userNotification,
}: {
  inList?: boolean,
  userNotification: boolean,
}) => {
  if (inList) {
    return (
      <Bell
        width={11}
        className={classnames('align-bottom mr-3', {
          'text-hubware': userNotification,
        })}
        style={{strokeWidth: userNotification ? '3px' : '2px'}}
      />
    );
  } else if (userNotification) {
    return (
      <AbsoluteWrapper>
        <Bell width={14} />
      </AbsoluteWrapper>
    );
  }

  return null;
};

export default connect(({app}) => ({
  userNotification: app.uiState.userNotification,
}))(UserNotification);

const AbsoluteWrapper = styled.span`
  color: var(--hubware);
  height: 100%;
  position: absolute;
  top: -8px;
  right: 9px;
  animation: bellshake 3s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: infinite;

  @keyframes bellshake {
    0% {
      transform: rotate(0);
    }
    1.5% {
      transform: rotate(10deg);
    }
    3.0% {
      transform: rotate(-10deg);
    }
    4.5% {
      transform: rotate(7deg);
    }
    6.0% {
      transform: rotate(-7deg);
    }
    7.5% {
      transform: rotate(5deg);
    }
    8.5% {
      transform: rotate(-5deg);
    }
    9.2% {
      transform: rotate(3deg);
    }
    10% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(0);
    }
  }
`;
