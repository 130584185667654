// @flow
import React from 'react';
import {useDispatch} from 'react-redux';
import {Prompt} from 'react-router-dom';
import {Redirect} from 'react-router-dom';

import {type TEMPLATE_INTERFACE} from '../../../../selectors/templates';
import {pairTemplates} from '../../../../lib/firestore/templates';
import {Card} from '../../../../components/card';
import Title from '../../components/title';
import TemplatesList from '../../components/templates-select-list';
import Chart from './bar-chart';
import AssistanceDropdown from './assistance-dropdown';
import AutoAssign from './auto-assign';

const IntentDetails = ({
  match,
  intents,
  allTemplates,
  templatesByIntents,
}: {
  match: Object,
  intents: Object,
  allTemplates: Array<TEMPLATE_INTERFACE>,
  templatesByIntents: Object,
}) => {
  const dispatch = useDispatch();

  const key = match.params.key;
  const intentExists = intents[key];
  const mappedTemplates = templatesByIntents?.[key] ?? [];

  if (!intentExists && mappedTemplates.length === 0) {
    return (
      <Redirect
        to={{
          pathname: '/config/_intents',
          search: window.location.search,
        }}
      />
    );
  }

  const intent = intents?.[key] ?? {key};
  const {title, description} = intent;
  const isNew = window.location.pathname.includes('new');

  return (
    <>
      <Prompt
        when={mappedTemplates.length === 0}
        message={(location) =>
          `Aucun modèle de réponse n'a été configuré pour cette catégorie. Êtes-vous sûr de vouloir quitter ?`
        }
      />

      <Title
        back={{pathname: '/config/_intents', listName: 'catégories'}}
        title={title || key}
        description={description}
        allowEdit={false}
      />

      {!isNew && intentExists && <Chart intent={intent} />}

      {!intentExists && (
        <Card>
          <div className="card-body">
            <h4>Important</h4>
            <hr />
            <p>Cette catégorie ne semble plus supportée par votre assistant.</p>
            <p>
              Vous devez dé-selectionner tous les modèles de réponses afin de
              retirer cette catégorie de l'assistant définitivement.
            </p>
            <p>
              Pour toute question vous pouvez contacter{' '}
              <a href="mailto:help@hubwa.re">help@hubwa.re</a>.
            </p>
          </div>
        </Card>
      )}

      <TemplatesList
        selectedTemplates={mappedTemplates.map(({id}) => id) || []}
        onValidate={(templatesId) =>
          dispatch(pairTemplates(templatesId, intent.key))
        }
        editOnMount={window.location.pathname.includes('new')}
      >
        {(template: TEMPLATE_INTERFACE) => (
          <>
            <td className="text-right align-middle">
              <AssistanceDropdown
                template={template}
                assistance={template.assistanceType}
              />
            </td>
            <td className="text-right align-middle">
              {template.filter ? (
                <small className="text-muted">Logique&nbsp;disponible</small>
              ) : (
                <small className="text-muted">Aucune&nbsp;logique</small>
              )}
            </td>
          </>
        )}
      </TemplatesList>

      <AutoAssign intent={intent} />
    </>
  );
};

export default IntentDetails;
