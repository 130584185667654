// @flow
import React, {useState} from 'react';
import {Button, Form, FormGroup, Input, FormText} from 'reactstrap';

import {type USER_INTERFACE} from '../../../selectors/user';
import {updatePassword} from '../../../lib/firebase';
import RecentLogin from './recent-login';

const PasswordForm = ({user}: {user: USER_INTERFACE}) => {
  const [password, setPassword] = useState('');
  const [formState, setFormState] = useState({});
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const hasEmailProvider = user.providers.find(({id}) => id === 'password');

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    setFormState({});
    try {
      setLoading(true);
      await updatePassword(password);
      setFormState({valid: true, error: false});
      setPassword('');
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        setModal(true);
        return;
      }
      setFormState({error: error.message});
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <hr />
      <h5>Mot de passe</h5>
      <div>
        <p className="text-muted">Modifier le mot de passe de connexion</p>

        {!hasEmailProvider && (
          <p className="text-muted">
            <em>Modification du mot de passe non disponible</em>
          </p>
        )}
        {hasEmailProvider && (
          <Form inline className="py-2" onSubmit={(e) => handleSubmit(e)}>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Input
                type="password"
                id="newpassword"
                placeholder="nouveau mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={user.isUserForced || loading}
                invalid={Boolean(formState.error)}
                required
              />
            </FormGroup>
            <Button color="hubware" outline disabled={user.isUserForced}>
              {loading ? 'Chargement...' : 'Valider'}
            </Button>
            {formState.error && (
              <FormText className="ml-2" color="error">
                {formState.error}
              </FormText>
            )}
            {formState.valid && (
              <FormText className="ml-2" color="success">
                Modification enregistrée
              </FormText>
            )}
          </Form>
        )}
        <RecentLogin
          user={user}
          modal={modal}
          setModal={setModal}
          callback={() => handleSubmit()}
        />
      </div>
    </>
  );
};

export default PasswordForm;
