// @flow
import React, {useState} from 'react';
import {Button} from 'reactstrap';
import {Plus} from 'react-feather';

import Modal from './modal';

const AddButton = () => {
  const [modal, setModal] = useState(false);

  return (
    <div>
      <Button color="hubware" onClick={() => setModal(true)}>
        <Plus size="15" className="mr-2" />
        Nouvelle action
      </Button>
      <Modal modal={modal} setModal={setModal} />
    </div>
  );
};

export default AddButton;
