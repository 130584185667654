// @flow
import {sortBy, split, join} from 'lodash';

function getMultipleTitle(key: string, intents: Object) {
  return key.includes(' & ')
    ? join(
        split(key, ' & ').map((k) => intents?.[k]?.title || k),
        ' & '
      )
    : intents?.[key]?.title || key;
}

export const getPredictionRatioPercent = (
  data: Array<Object>,
  intents: Object
): Array<{key: string, value: number}> => {
  const total = data.reduce((acc, v) => acc + v.value, 0);
  const percentValues = data.map((d) => ({
    key: getMultipleTitle(d.key, intents),
    value: Math.round((d.value / total) * 100),
  }));
  return sortBy(percentValues, ['value']).reverse();
};

export const formatTableData = (
  data: Array<Object>,
  intents: Object
): Array<{key: string, value: number}> => {
  const total = data.reduce((acc, v) => acc + v.value, 0);
  return data.map(({key, value}) => ({
    key: getMultipleTitle(key, intents),
    value: value,
    percent: Math.round((value / total) * 100) + '%',
  }));
};
