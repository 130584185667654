// @flow
import React from 'react';
import {UncontrolledTooltip as Ut} from 'reactstrap';
import {Info} from 'react-feather';
import {Row, Col} from 'reactstrap';

const Informations = () => (
  <>
    <h5 className="mb-3">Informations</h5>
    <div className="pb-2">
      <Row className="mb-2">
        <Col md="7">Durée de rétention des données</Col>
        <Col md="5">
          6 mois
          <Info
            id="retention-delay"
            size="12"
            className="clic ml-1 text-hubware"
          />
          <Ut target="retention-delay">
            Ce délais de rétention n'est pas valable pour les données
            nécessaires au bon fonctionnement des services une fois dépassé ce
            délai. En particulier les données nécessaires à l'apprentissage
            continue des modèles d'Intelligence Artificielle.
          </Ut>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col md="7">Emplacement des données</Col>
        <Col md="5">
          Europe <span className="text-muted">(Belgique)</span>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col md="7">Contact sécurité</Col>
        <Col md="5">
          <a href="mailto:security@hubwa.re">security@hubwa.re</a>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col md="7">Contact confidentialité des données</Col>
        <Col md="5">
          <a href="mailto:security@hubwa.re">privacy@hubwa.re</a>
        </Col>
      </Row>
    </div>
  </>
);

export default Informations;
