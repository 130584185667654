import React, {Fragment} from 'react';
import styled from 'styled-components';
import {ListGroup} from 'reactstrap';
import {v4 as uuidv4} from 'uuid';

import Link from './link';

const SideNav = ({
  nav,
}: {
  nav: Array<{
    title: string;
    links: {
      title: string | JSX.Element;
      pathname: string;
      disabled?: boolean;
    }[];
  }>;
}) => (
  <div>
    {nav.map(({title, links}) => (
      <Fragment key={uuidv4()}>
        <ListGroupSection>{title}</ListGroupSection>
        <ListGroup>
          {links.map(({title, pathname, disabled}) =>
            disabled ? null : (
              <Link key={uuidv4()} pathname={pathname}>
                {title}
              </Link>
            )
          )}
        </ListGroup>
        <hr />
      </Fragment>
    ))}
  </div>
);

export default SideNav;

const ListGroupSection = styled.h6`
  color: var(--gray);
  font-size: 85%;
  font-weight: 600;
  opacity: 0.85;
  text-transform: uppercase;
`;
