// @flow
import React, {useState} from 'react';

import {type TEMPLATE_INTERFACE} from '../../../../selectors/templates';

import ListItem from './list-item';

const UnselectedList = ({
  templates,
  toggleSelected,
}: {
  templates: Array<TEMPLATE_INTERFACE>,
  toggleSelected: Function,
}) => {
  const [searchInput, setSearchInput] = useState('');

  const searchResults = templates.filter(
    ({title}) => title.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
  );

  return (
    <>
      <tr>
        <td colSpan="100">
          <input
            className="form-control"
            type="search"
            placeholder="Response title"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </td>
      </tr>

      {searchResults.map((template, index) => (
        <ListItem
          key={`unselected-template-${index}`}
          template={template}
          edit={true}
          toggleSelected={toggleSelected}
        />
      ))}
      {searchResults.length === 0 && (
        <tr>
          <td colSpan="100%">Aucun résultat</td>
        </tr>
      )}
    </>
  );
};

export default UnselectedList;
