// @flow
import React from 'react';
import {connect} from 'react-redux';
import {Container, Row, Col} from 'reactstrap';
import {renderRoutes, matchRoutes} from 'react-router-config';
import {Redirect} from 'react-router-dom';

import {getAllIntents} from '../../selectors/intents';
import SideNav from '../../components/sidenav';

const NAV = [
  {
    title: 'Performances',
    links: [
      {
        pathname: '/ai/no-answer-intents',
        title: 'Messages non-répondus',
      },
      {
        pathname: '/ai/annotations/list',
        title: 'Annotations',
      },
    ],
  },
];

export const Ai = ({
  intents,
  route,
  uiState,
}: {
  intents: Object,
  route: Object,
  uiState: Object,
}) => {
  if (matchRoutes(route.routes, window.location.pathname).length === 0) {
    return (
      <Redirect
        to={{
          pathname: '/ai/no-answer-intents',
          search: window.location.search,
        }}
      />
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col md="2">
          <SideNav nav={NAV} />
        </Col>
        <Col md="10">
          {renderRoutes(route.routes, {
            intents,
          })}
        </Col>
      </Row>
    </Container>
  );
};

export default connect((state, props) => ({
  intents: {
    ...getAllIntents(state),
  },
}))(Ai);
