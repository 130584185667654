// @flow
import React from 'react';
import {connect} from 'react-redux';
import {DateTime} from 'luxon';

import {Card} from '../../../components/card';
import {getUser, type USER_INTERFACE} from '../../../selectors/user';
import Google from './google';
import EmailForm from './email-form';
import PasswordForm from './password-form';

const User = ({user}: {user: USER_INTERFACE}) => (
  <Card>
    <div className="card-body">
      <h4 className="card-title">{user.email}</h4>
      <h6 className="card-subtitle mb-2 text-muted">
        Compte créé le{' '}
        {DateTime.fromJSDate(user.created).toLocaleString(DateTime.DATE_MED)}
      </h6>
      <Google user={user} />
      <EmailForm user={user} />
      <PasswordForm user={user} />
    </div>
  </Card>
);

export default connect((state) => ({
  user: getUser(state),
}))(User);
