// @flow
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {CheckCircle, Loader, XOctagon} from 'react-feather';
import i18n from '../../../../i18n';

const TITLES = [
  i18n.t('Zendesk authorization recovery'),
  i18n.t('Installing the official Hubware extension on Zendesk'),
  i18n.t('Security key creation'),
  i18n.t('Creating Hubware HTTP triggers and targets'),
  i18n.t('Checking the installation'),
];

const Checks = ({
  started,
  finished,
  error,
  setEndChecks,
}: {
  started: boolean,
  finished: boolean,
  error?: string,
  setEndChecks: Function,
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const loader = (n) => {
      if (n > 0) {
        setProgress((p) => p + 1);
        setTimeout(() => {
          loader(n - 1);
        }, 1000 + 2000 * Math.random());
      } else {
        setEndChecks();
      }
    };

    if (started && progress === 0) {
      loader(TITLES.length);
    }
  }, [progress, setEndChecks, started]);

  const displayCheck = (index) => {
    if (index === TITLES.length - 1) {
      return progress > index && finished;
    }

    return progress > index;
  };

  return (
    <StyledList className="list-unstyled">
      {!error &&
        TITLES.map((title, index) => (
          <li className="py-3 d-flex align-items-center" key={index}>
            {displayCheck(index) ? (
              <CheckCircle size="25" className="text-hubware mr-3" />
            ) : (
              <Loader size="25" className="text-muted rotating mr-3" />
            )}
            {TITLES[index]}
          </li>
        ))}
      {error && (
        <li className="py-3 d-flex align-items-center text-hubware">
          <XOctagon size="25" className="mr-3" />
          {error}
        </li>
      )}
    </StyledList>
  );
};

export default Checks;

const StyledList = styled.ul.attrs({
  className: 'list-unstyled',
})`
  font-size: 120%;

  .rotating {
    animation: rotating 3s linear infinite;
    @keyframes rotating {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;
