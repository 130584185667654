// @flow
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {DateTime} from 'luxon';
import {Row, Col} from 'reactstrap';
import {Circle} from 'react-feather';
import {isUndefined} from 'lodash';
import {v4 as uuidv4} from 'uuid';

import {Card} from '../../../components/card';
import {fetchSystemsStatus} from '../../../actions/data';

const User = ({
  systemsStatus,
  fetchSystemsStatus,
}: {
  systemsStatus: Object,
  fetchSystemsStatus: Function,
}) => {
  if (isUndefined(systemsStatus)) {
    fetchSystemsStatus();
  }

  const components = TITLES_MAP.map(({id, title}) => {
    const component = (systemsStatus?.components ?? []).find(
      (c) => c.id === id
    );
    const status = component?.status ?? 'loading';
    return {
      title,
      color: STATUS_MAP[status].color,
      status: STATUS_MAP[status].title,
    };
  });

  const scheduledMaintenances = systemsStatus?.scheduled_maintenances ?? [];
  return (
    <Card>
      <div className="card-body">
        <h4 className="card-title">Statut des services & Maintenance</h4>
        <hr />

        <h5 className="mb-3">Services</h5>
        <div>
          {components.map(({title, color, status}) => (
            <Row key={uuidv4()}>
              <Col sm="3" className="mb-2">
                <strong>{title}</strong>
              </Col>
              <Col sm="9" className={`d-flex align-items-center text-${color}`}>
                <Circle
                  size="12"
                  className="mr-2"
                  style={{fill: `var(--${color})`}}
                />
                {status}
              </Col>
            </Row>
          ))}
        </div>

        <div className="mt-2">
          <a
            href="http://status.hubwa.re"
            target="_blank"
            rel="noopener noreferrer"
          >
            Détails
          </a>
        </div>
        <hr />

        <h5 className="mb-3">Maintenance</h5>
        <div>
          {scheduledMaintenances.length === 0 && (
            <p>Aucune maintenance prévue</p>
          )}

          {scheduledMaintenances.length > 0 && (
            <ul>
              {scheduledMaintenances.map(
                ({scheduled_for, scheduled_until, shortlink}) => (
                  <li className="mb-2" key={uuidv4()}>
                    <a
                      href={shortlink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Maintenance prévu du{' '}
                      {DateTime.fromISO(scheduled_for).toLocaleString(
                        DateTime.DATETIME_SHORT
                      )}{' '}
                      au{' '}
                      {DateTime.fromISO(scheduled_until).toLocaleString(
                        DateTime.DATETIME_SHORT
                      )}
                    </a>
                  </li>
                )
              )}
            </ul>
          )}
        </div>
      </div>
    </Card>
  );
};

export default connect(
  ({data}) => ({systemsStatus: data.systemsStatus}),
  (dispatch) => ({...bindActionCreators({fetchSystemsStatus}, dispatch)})
)(User);

const TITLES_MAP = [
  {id: 'bttcf3chgldq', title: 'API principale'},
  {id: 'v03psr739pm2', title: 'Intelligence Artificielle'},
];

const STATUS_MAP = {
  loading: {
    title: 'Chargement...',
    color: 'secondary',
  },
  operational: {
    title: 'Opérationnel',
    color: 'success',
  },
  degraded_performance: {
    title: 'Performances dégradées',
    color: 'danger',
  },
  partial_outage: {
    title: 'Incident mineur',
    color: 'danger',
  },
  major_outage: {
    title: 'Incident majeur',
    color: 'error',
  },
};
