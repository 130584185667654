// @flow
import React from 'react';
import {UncontrolledTooltip as Ut} from 'reactstrap';
import {ArrowRight, ArrowDownRight, ArrowUpRight} from 'react-feather';
import classnames from 'classnames';
import {v4 as uuidv4} from 'uuid';

const Trend = ({
  historyVal,
  value,
  percent,
  historyRange,
}: {
  historyVal: ?number,
  value: number,
  percent?: boolean,
  historyRange?: {startDate: Date, endDate: Date},
}) => {
  if (!historyVal || value === 0) {
    return null;
  }

  let child;
  const trend = percent
    ? value - historyVal
    : Math.round((value / historyVal - 1) * 100);

  if (isNaN(trend)) {
    return null;
  }

  if (trend === 0) {
    child = <ArrowRight size="17" />;
  } else {
    child =
      trend > 0 ? <ArrowUpRight size="17" /> : <ArrowDownRight size="17" />;
  }

  const dateRange = historyRange
    ? `${historyRange.startDate.toLocaleString()} - ${historyRange.endDate.toLocaleString()}`
    : null;

  const id = uuidv4();

  return (
    <div
      id={`trend-${id}`}
      className={classnames('align-self-end mb-1 clic', {
        'text-success': trend > 0,
        'text-danger': trend < 0,
        'text-muted': trend === 0,
      })}
    >
      {child}
      {trend}%
      <Ut target={`trend-${id}`}>
        {dateRange}
        <br />
        {historyVal}
        {percent && '%'}
      </Ut>
    </div>
  );
};

export default Trend;
