// @flow
import React from 'react';
import styled from 'styled-components';

const Filter = ({columns}: {columns: Array<Object>}) => {
  if (columns.filter(({Filter}) => Filter).length === 0) {
    return null;
  }

  return (
    <StyledFilters>
      <small>Filtres :</small>
      {columns
        .filter(({Filter}) => !!Filter)
        .map(({Filter, render, id}, index) => (
          <div key={`${id}-${index}`} className="d-inline-block ml-2">
            {render('Filter')}
          </div>
        ))}
    </StyledFilters>
  );
};

export default Filter;

const StyledFilters = styled.div.attrs({className: 'p-2 text-muted'})`
  border-bottom: 1px solid var(--gray-300);
`;
