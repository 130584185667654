// @flow
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useQuery, useManualQuery} from 'graphql-hooks';
import {Row, Col} from 'reactstrap';
import {get, chain, sumBy} from 'lodash';
import {v4 as uuidv4} from 'uuid';

import {getAllIntents} from '../../../../selectors/intents';
import {CardError} from '../../../../components/card';
import NumberChart from '../../components/number';
import TopList from '../../components/top-list';
import getDataHook from '../../../../lib/graphQL';

const Links = ({
  dateRange,
  diffDateRange,
  intents,
}: {
  dateRange: Object,
  diffDateRange: Object,
  intents: Object,
}) => {
  const [slice, setSlice] = useState(5);

  const {loading, error, data} = useQuery(
    ...getDataHook('getClics', {date: dateRange})
  );
  const [fetchHistoryData, historyState] = useManualQuery(
    ...getDataHook('getClicsTrend', {date: diffDateRange})
  );

  if (error || historyState.error) {
    return <CardError />;
  }

  if (
    !loading &&
    !historyState.data &&
    !historyState.loading &&
    !historyState.error
  ) {
    fetchHistoryData();
  }

  const key = 'LINK_CLICKED';
  const values = data?.linkClicked ?? [];
  const totalValue = sumBy(values, 'value');
  const topByIntent = getToplist(values, 'intent')
    .map(({key, value}) => ({key: key === 'null' ? 'no_intent' : key, value}))
    .map(({key, value}) => ({
      key: get(intents, [key, 'title'], key),
      value,
    }));
  const topByOperator = getToplist(values, 'operator');

  return (
    <>
      <Row>
        <Col key={uuidv4()}>
          <NumberChart
            usage={key}
            value={totalValue}
            isLoading={loading}
            historyRange={diffDateRange}
            historyVal={get(historyState, 'data.linkClicked.count', 0)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TopList
            values={topByIntent}
            slice={slice}
            setSlice={setSlice}
            isLoading={loading}
            title="Nombre de liens cliqués par catégorie"
            subTitle="Liens disponibles dans l'assistant"
            unit=" messages"
          />
        </Col>
        <Col>
          <TopList
            values={topByOperator}
            slice={slice}
            setSlice={setSlice}
            isLoading={loading}
            title="Nombre de liens cliqués par opérateur"
            subTitle="Liens disponibles dans l'assistant"
            unit=" messages"
          />
        </Col>
      </Row>
    </>
  );
};

export default connect((state, props) => ({
  intents: getAllIntents(state),
}))(Links);

function getToplist(values: Array<Object>, by: string) {
  return chain(values)
    .groupBy(`aggregation.${by}`)
    .map((v, key) => ({key, value: sumBy(v, 'value')}))
    .sortBy(['value'])
    .reverse()
    .value();
}
