// @flow
import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Navbar} from 'reactstrap';

import logo from '../../logo.svg';
import {isUserFreemium, hasUserFinishedOnboarding} from '../../../lib/user';

import MainLinks from './main-links';
import UserDropdown from './user-dropdown';
import OfflineBanner from './offline-banner';

export const Header = ({
  setting,
  neuralConfig,
  user,
  loggedUser,
  offline,
  salesforceProvider,
}: {
  setting: Object,
  neuralConfig: string,
  user?: Object,
  loggedUser?: Object,
  offline?: Boolean,
  salesforceProvider?: boolean,
}) => {
  if (!loggedUser || !hasUserFinishedOnboarding(user)) {
    return null;
  }

  return (
    <>
      {offline && <OfflineBanner />}
      <StyledHeader>
        <Link
          className="mr-4 mb-1 navbar-brand"
          to={{
            pathname: '/',
            search: window.location.search,
          }}
        >
          <img src={logo} alt="logo" height="25px" width="22px" />
        </Link>

        {!isUserFreemium(setting) && (
          <>
            <MainLinks
              setting={setting}
              salesforceProvider={salesforceProvider}
            />
            <UserDropdown user={user} />
          </>
        )}
      </StyledHeader>
    </>
  );
};

export default connect(({firestore, app, data}) => ({
  setting: firestore.setting,
  user: firestore.user,
  loggedUser: app.loggedUser,
  offline: app.offline,
  salesforceProvider: data.provider === 'salesforce',
}))(Header);

const StyledHeader = styled(Navbar).attrs({
  color: 'light',
  id: 'header',
  className: 'py-0',
  expand: 'md',
  light: true,
})`
  background-color: var(--white) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  height: 67px;

  @media (max-width: 767.98px) {
    .navbar-brand {
      display: none;
    }
  }
`;
