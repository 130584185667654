// @flow
import React from 'react';

import {Card} from '../../../../components/card';
import Informations from './informations';
import Export from './export';
import AccountDeletion from './account-deletion';

const Privacy = () => {
  return (
    <Card>
      <div className="card-body">
        <h4 className="card-title">Protection des données - RGPD</h4>
        <hr />

        <Informations />
        <hr />
        <Export />
        <hr />
        <AccountDeletion />
      </div>
    </Card>
  );
};

export default Privacy;
