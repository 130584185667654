import {addRealtimeRef, resetRealtimeRef} from '../../actions/firestore';
import {collectionToData, docToData} from './utils';

export const onSnapshot = (ref, cb) => (dispatch, getState) => {
  const unsubscribe = ref.onSnapshot((res) => {
    if (res.forEach) {
      dispatch(cb(collectionToData(res)));
    } else {
      dispatch(cb(docToData(res)));
    }
  });
  // Store unsubscribe Reference if we want to disconnect realtime updates in future
  dispatch(addRealtimeRef(unsubscribe));
};

export const disconnectRealtimeRefs = (name) => (dispatch, getState) => {
  getState().firestore.realtimeRefs.map((r) => r());
  dispatch(resetRealtimeRef());
};
