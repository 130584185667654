// @flow
import React, {useEffect, useState} from 'react';
import firebase from 'firebase/app';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Route} from 'react-router-dom';
import {renderRoutes} from 'react-router-config';
import {ClientContext} from 'graphql-hooks';
import {isUndefined} from 'lodash';
import styled from 'styled-components';

import {resetStore, setLoadOfflineData} from './actions/app';
import init from './lib/init';
import Header from './components/architecture/header';
import Debugger from './components/architecture/debugger';
import ProtectRoutes from './components/protect-routes';
import Error from './views/error';
import Loading from './views/loading';
import {getClient} from './lib/graphQL/client';
import {Calendly} from './lib/calendly';
import {hasUserFinishedOnboarding} from './lib/user';
import {GAinitialPageView} from './lib/google-analytics';

export const App = (props: {
  route: Object,
  resetStore: Function,
  init: Function,
  setLoadOfflineData: Function,
  history: Object,
  firestore: Object,
  error: string,
  debug: boolean,
  offline: boolean,
  loggedUser?: Object,
}) => {
  const {resetStore, init, setLoadOfflineData, offline} = props;
  const [isSignedIn, setIsSignedIn] = useState(undefined);

  useEffect(() => {
    resetStore();
    if (!offline) {
      console.warn(' ⚠️ Setauth check render !! should be printed once');
      init();
      // send initial pageview for google analytics
      console.warn(' ⚠️⚠️⚠️⚠️ Check render app (with GA initial pageView'); // change GAinitialPageView if called multiples times
      GAinitialPageView();
    } else {
      setLoadOfflineData();
    }
  }, [init, offline, resetStore, setLoadOfflineData]);

  useEffect(() => {
    if (props.loggedUser) {
      setIsSignedIn(offline || Boolean(firebase.auth().currentUser));
    }
  }, [offline, props.loggedUser]);

  useEffect(() => {
    if (offline) {
      Calendly(offline);
    }
  }, [offline]);

  const isAppLoading = () => {
    // Todo : move some checks in lib (share code with init/utils.js)!!
    const {firestore = {}, loggedUser} = props;
    if (!loggedUser) {
      // Waiting for firebase initialization
      return true;
    }

    if (isSignedIn === false) {
      return false;
    }

    if (!hasUserFinishedOnboarding(firestore?.user)) {
      // user to onboard
      return false;
    }

    return (
      isUndefined(firestore.setting) ||
      isUndefined(firestore.templates) ||
      isUndefined(firestore.user) ||
      isUndefined(firestore.jwt)
    );
  };

  const {route, error} = props;

  if (error) {
    return <Error error={error} />;
  }
  if (isAppLoading()) {
    return <Loading />;
  }

  return (
    <ClientContext.Provider value={getClient()}>
      <MainWrapper>
        {isSignedIn && <Route component={Header} />}
        <ProtectRoutes routes={route.routes}>
          {renderRoutes(route.routes)}
        </ProtectRoutes>
      </MainWrapper>
      <Debugger />
    </ClientContext.Provider>
  );
};

export default connect(
  ({firestore, data, app}) => ({
    firestore: firestore,
    error: app.error,
    debug: app.debug,
    offline: app.offline,
    loggedUser: app.loggedUser,
  }),
  (dispatch) => ({
    ...bindActionCreators(
      {
        resetStore,
        init,
        setLoadOfflineData,
      },
      dispatch
    ),
  })
)(App);

const MainWrapper = styled.div.attrs({className: 'w-100 h-100'})`
  #header + .container,
  #header + .container-fluid {
    margin-top: 30px;
    &:first-child {
      margin-top: 60px;
    }
  }
`;
