import React from 'react';
import {DateTime} from 'luxon';
import styled from 'styled-components';

const Item = ({
  title,
  creation,
  children,
}: {
  title: string;
  creation: string;
  children: JSX.Element;
}) => (
  <StyledItem>
    <StyledTitle>
      <h6 className="title">{title}</h6>
      <h6 className="text-muted card-subtitle">
        {DateTime.fromISO(creation).setLocale('fr').toFormat('LLL yy')}
      </h6>
    </StyledTitle>
    {children}
  </StyledItem>
);

export default Item;

const StyledItem = styled.div.attrs({className: 'feature-item'})`
  img {
    box-shadow: 0px 0px 5px var(--gray-300);
    display: block;
    margin: 20px auto 30px;
    max-height: 250px;
    max-width: 80%;
  }

  p {
    color: var(--gray);
  }
`;

const StyledTitle = styled.div.attrs({
  className:
    'feature-title d-flex justify-content-between align-items-center mb-3 pt-3 pb-2',
})`
  border-bottom: 1px solid var(--gray-300);

  h6.title {
    color: var(--hubware);
    font-weight: bold;
  }
`;
