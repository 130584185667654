// @flow
import React from 'react';
import {matchRoutes, renderRoutes} from 'react-router-config';
import {Redirect} from 'react-router-dom';
import App from './app';
import asyncComponent from './components/async-component';
import AnalyticsActivity from './views/analytics/views/activity';
import AnalyticsCoverage from './views/analytics/views/coverage';
import AnalyticsIntents from './views/analytics/views/intents';
import AnalyticsLinks from './views/analytics/views/links';
import AnalyticsOperators from './views/analytics/views/operators';
import AnalyticsSabotage from './views/analytics/views/sabotage';
import AnalyticsLiveAutoReplies from './views/analytics/views/live-auto-replies';
import AnalyticsVariables from './views/analytics/views/variables';
import Config from './views/configuration/index.js';
import Intents from './views/configuration/intents';
import IntentsDetails from './views/configuration/intents/details';
import Freemium from './views/freemium';
import FreemiumActivity from './views/freemium/views/activity';
import FreemiumIntents from './views/freemium/views/intents';
import FreemiumOperators from './views/freemium/views/operators';
import FreemiumTemplates from './views/freemium/views/templates';

import AI from './views/ai';
import PredictionRequests from './views/ai/predictionsView/requests';
import UnansweredIntents from './views/ai/no-answer-intents';

import Home from './views/home';
import SignIn from './views/sign/sign-in';
import SignUp from './views/sign/sign-up';
import RecoverPassword from './views/sign/recover-password';
import SignOut from './views/sign/sign-out';
import OAuth from './views/oauth';
import UserMenu from './views/user-menu';
import UserMenuMetadata from './views/user-menu/settings/metadata';
import UserMenuLogic from './views/user-menu/settings/logic';
import UserMenuPrivacy from './views/user-menu/settings/privacy';
import UserMenuProvider from './views/user-menu/settings/provider';
import UserMenuStatus from './views/user-menu/status';
import UserMenuUser from './views/user-menu/user';
import WhatsNew from './views/user-menu/whats-new';
import WelcomeSteps from './views/welcome/steps';

const AsyncAnalytics = asyncComponent(() => import('./views/analytics'));
const AsyncMainAutoReplySettings = asyncComponent(() =>
  import('./views/user-menu/settings/auto-reply')
);
const AsyncTemplates = asyncComponent(() =>
  import('./views/configuration/templates')
);
const AsyncTemplatesDetails = asyncComponent(() =>
  import('./views/configuration/templates/details')
);
const AsyncTemplateContent = asyncComponent(() =>
  import('./views/configuration/templates/details/content')
);
const AsyncTemplateLogic = asyncComponent(() =>
  import('./views/configuration/templates/details/logic')
);
const AsyncTemplateAction = asyncComponent(() =>
  import('./views/configuration/templates/details/actions')
);
const AsyncActionsDetails = asyncComponent(() =>
  import('./views/configuration/actions/details')
);
const AsyncAnnotationList = asyncComponent(() =>
  import('./components/annotation/list')
);
const AsyncAnnotationDetails = asyncComponent(() =>
  import('./components/annotation/details')
);

const Root = ({route}: {route: Object}) => {
  if (matchRoutes(route.routes, window.location.pathname).length === 0) {
    return (
      <Redirect
        to={{
          pathname: route.fallback || '/',
          search: window.location.search,
        }}
      />
    );
  }

  return renderRoutes(route.routes);
};

const Routes = [
  {
    component: App,
    routes: [
      {
        path: '/sign-in',
        exact: true,
        component: SignIn,
        allowUnAuth: true,
        onlyUnAuth: true,
      },
      {
        path: '/sign-up',
        exact: true,
        component: SignUp,
        allowUnAuth: true,
        onlyUnAuth: true,
      },
      {
        path: '/recover-password',
        exact: true,
        component: RecoverPassword,
        allowUnAuth: true,
        onlyUnAuth: true,
      },
      {
        path: '/sign-out',
        exact: true,
        component: SignOut,
        allowOnboarding: true,
        allowFreemium: true,
      },
      {
        path: '/oauth',
        exact: true,
        component: OAuth,
        allowOnboarding: true,
      },
      {
        path: '/welcome',
        component: Root,
        fallback: '/welcome/steps',
        allowOnboarding: true,
        routes: [
          {
            path: '/welcome/steps',
            search: window.location.search,
            component: WelcomeSteps,
            exact: true,
            allowOnboarding: true,
          },
        ],
      },
      {
        path: '/home',
        exact: true,
        component: Home,
      },
      {
        path: '/freemium',
        component: Freemium,
        allowFreemium: true,
        routes: [
          {
            path: '/freemium/activity',
            exact: true,
            component: FreemiumActivity,
            allowFreemium: true,
          },
          {
            path: '/freemium/intents',
            exact: true,
            component: FreemiumIntents,
            allowFreemium: true,
          },
          {
            path: '/freemium/operators',
            exact: true,
            component: FreemiumOperators,
            allowFreemium: true,
          },
          {
            path: '/freemium/templates',
            exact: true,
            component: FreemiumTemplates,
            allowFreemium: true,
          },
          {
            path: '/freemium/ai/annotations/list',
            exact: true,
            component: AsyncAnnotationList,
            allowFreemium: true,
          },
          {
            path: '/freemium/ai/annotations/details/:id',
            exact: true,
            component: AsyncAnnotationDetails,
            allowFreemium: true,
          },
        ],
      },
      {
        path: '/analytics',
        component: AsyncAnalytics,
        routes: [
          {
            path: '/analytics/activity',
            exact: true,
            component: AnalyticsActivity,
          },
          {
            path: '/analytics/coverage',
            exact: true,
            component: AnalyticsCoverage,
          },
          {
            path: '/analytics/operators',
            exact: true,
            component: AnalyticsOperators,
          },
          {
            path: '/analytics/intents',
            exact: true,
            component: AnalyticsIntents,
          },
          {
            path: '/analytics/variables',
            exact: true,
            component: AnalyticsVariables,
          },
          {
            path: '/analytics/links',
            exact: true,
            component: AnalyticsLinks,
          },
          {
            path: '/analytics/live-auto-replies',
            exact: true,
            component: AnalyticsLiveAutoReplies,
          },
          {
            path: '/analytics/sabotage',
            exact: true,
            component: AnalyticsSabotage,
          },
        ],
      },
      {
        path: '/ai',
        component: AI,
        routes: [
          {
            path: '/ai/no-answer-intents',
            exact: true,
            component: UnansweredIntents,
          },
          {
            path: '/ai/requests',
            exact: true,
            component: PredictionRequests,
          },
          {
            path: '/ai/annotations/list',
            exact: true,
            component: AsyncAnnotationList,
          },
          {
            path: '/ai/annotations/details/:id',
            exact: true,
            component: AsyncAnnotationDetails,
          },
        ],
      },
      {
        path: '/config',
        component: Root,
        fallback: '/config/_intents',
        routes: [
          {
            path: '/config/_intents',
            component: Config,
            key: 'config',
            exact: true,
          },
          {
            path: '/config/_templates',
            component: Config,
            key: 'config',
            exact: true,
          },
          {
            path: '/config/_actions',
            component: Config,
            key: 'config',
            exact: true,
          },
          {
            path: '/config/intents',
            component: Intents,
            routes: [
              {
                path: '/config/intents/:key/(new)?',
                exact: false,
                component: IntentsDetails,
              },
            ],
          },
          {
            path: '/config/templates/:id',
            component: AsyncTemplates,
            routes: [
              {
                path: '/config/templates/:id/details',
                exact: true,
                component: AsyncTemplatesDetails,
              },
              {
                path: '/config/templates/:id/content',
                exact: true,
                component: AsyncTemplateContent,
              },
              {
                path: '/config/templates/:id/logic',
                exact: true,
                component: AsyncTemplateLogic,
              },
              {
                path: '/config/templates/:id/actions',
                exact: true,
                component: AsyncTemplateAction,
              },
            ],
          },
          {
            path: '/config/actions',
            component: Root,
            fallback: '/config/_actions',
            routes: [
              {
                path: '/config/actions/:id/details',
                exact: true,
                component: AsyncActionsDetails,
              },
            ],
          },
        ],
      },
      {
        path: '/user',
        component: UserMenu,
        routes: [
          {
            path: '/user/profile',
            component: UserMenuUser,
            exact: true,
          },
          {
            path: '/user/services-status',
            component: UserMenuStatus,
            exact: true,
          },
          {
            path: '/user/settings/privacy',
            exact: true,
            component: UserMenuPrivacy,
          },
          {
            path: '/user/settings/provider',
            exact: true,
            component: UserMenuProvider,
          },
          {
            path: '/user/settings/metadata',
            exact: true,
            component: UserMenuMetadata,
          },
          {
            path: '/user/settings/logic',
            exact: true,
            component: UserMenuLogic,
          },
          {
            path: '/user/settings/auto-reply',
            exact: true,
            component: AsyncMainAutoReplySettings,
          },
        ],
      },
      {
        path: '/whats-new',
        exact: true,
        allowUnAuth: true,
        allowFreemium: true,
        component: WhatsNew,
      },
      {
        path: '*',
        component: () => (
          <Redirect
            to={{
              pathname: '/home',
              search: window.location.search,
            }}
          />
        ),
      },
    ],
  },
];

export default Routes;
