import React from 'react';
import {v4 as uuidv4} from 'uuid';
import styled from 'styled-components';

import FeatureItem from './item';

const StyledA = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  border-bottom: 1px dashed #999;
  color: var(--body-color);
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const Features = [
  <FeatureItem
    key={uuidv4()}
    title="Configuration - Filtre de logique par mots-clefs"
    creation="2021-01-06"
  >
    <>
      <p>
        Nouveau !! Vous pouvez dès à présent créer des filtres par mots-clefs
        pour vos logiques de réponses !
      </p>

      <p>Sélectionnez un modèle de réponse, puis "Mots-clefs".</p>
      <img
        src={require('./medias/keyword-filter-condition.png')}
        alt="Keyword Condition"
      />
      <p>
        {' '}
        Vous avez ainsi la possibilité de renseigner un ou des mots qu'un ticket
        doit, ou ne doit pas contenir pour que la logique soit appliquée.
      </p>

      <img
        src={require('./medias/keyword-filter-option.png')}
        alt="Keyword Option"
      />

      <p>
        Rendez-vous sur l'onglet{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/config/_templates?${window.location.search}`}
        >
          Configuration
        </StyledA>{' '}
        !
      </p>
      <img
        src={require('./medias/keyword-filter-example.png')}
        alt="Keyword Example"
      />
    </>
  </FeatureItem>,
  <FeatureItem
    key={uuidv4()}
    title="Paramètres - Création de règle métier personnalisée"
    creation="2020-12-15"
  >
    <>
      <p></p>
      <img src={require('./medias/customlogic.gif')} alt="custom logic" />
      <p>
        Créez dès à présent vos blocs de logiques personnalisés depuis l'onglet{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/user/settings/logic${window.location.search}`}
        >
          Paramètres
        </StyledA>
        .
      </p>
      <p>
        {' '}
        Vous pourrez ainsi les utiliser lors de la création ou l'édition des
        logiques de réponses dans{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/config/_templates?${window.location.search}`}
        >
          Configuration
        </StyledA>{' '}
      </p>
    </>
  </FeatureItem>,
  <FeatureItem
    key={uuidv4()}
    title="Configuration - Modélisation des règles métiers"
    creation="2020-11-20"
  >
    <>
      <p>
        Nouveau !! Vous avez désormais la possibilité d'éditer les logiques de
        réponses directement depuis l'interface !
      </p>
      <img src={require('./medias/logic.gif')} alt="Actions list" />
      <p>
        Sélectionnez un modèle de réponse, puis ajoutez, modifier ou supprimer
        les logiques rattachées.
      </p>
      <p>
        {' '}
        Vous avez ainsi la possibilité d'éditer des logiques préparée (la
        présence ou non d'une pièce jointe dans un ticket par exemple) ou en
        créer des personnalisées.
      </p>

      <p>
        Rendez-vous sur l'onglet{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/config/_templates?${window.location.search}`}
        >
          Configuration
        </StyledA>{' '}
        !
      </p>
    </>
  </FeatureItem>,
  <FeatureItem
    key={uuidv4()}
    title="Paramètres - Association Opérateurs / Réponses automatiques"
    creation="2020-09-20"
  >
    <>
      <p>
        Vous pouvez définir dès à présent l'auteur des réponses automatiques
        directement depuis la section{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/user/settings/auto-reply${window.location.search}`}
        >
          paramètres
        </StyledA>{' '}
        de l'interface.
      </p>

      <img src={require('./medias/auto-reply-author.gif')} alt="Actions list" />

      <p>
        Cette configuration, nécessaire, permet de désigner l'opérateur qui sera
        associé aux réponses automatiques.
      </p>
    </>
  </FeatureItem>,
  <FeatureItem
    key={uuidv4()}
    title="Configuration - Association Opérateurs / Catégories"
    creation="2020-07-30"
  >
    <>
      <p>Une nouvelle fonctionnalité est disponible depuis l'interface :</p>
      <p>
        Il est désormais possible de paramétrer l'assignation automatique des
        tickets d'une même catégorie à un opérateur ou groupe d'opérateurs !
      </p>
      <img src={require('./medias/association.gif')} alt="association" />
      <p>
        Pour cela, sélectionner une catégorie depuis la page{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/config/_intents${window.location.search}`}
        >
          Configuration{''}
        </StyledA>
        . Puis cliquez sur "Détails" et choisissez quel opérateur ou groupe
        d'opérateurs à associer.
      </p>
    </>
  </FeatureItem>,
  <FeatureItem
    key={uuidv4()}
    title="Performances - Annotations"
    creation="2020-06-05"
  >
    <>
      <p>
        Améliorez les performances de votre assistant en annotant des messages
        directement depuis l'interface! Cela permettra d'améliorer le modèle,
        détecter de nouvelles catégories ou encore subdiviser une catégorie en
        sous-catégorie.
      </p>
      <p>
        Rendez-vous sur le nouvel onglet{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/ai/annotations/list${window.location.search}`}
        >
          Performances {''}
        </StyledA>
        !
      </p>
      <img src={require('./medias/annotations.gif')} alt="annotations" />
    </>
  </FeatureItem>,

  <FeatureItem
    key={uuidv4()}
    title="Performances - Catégories détectées sur les messages non-répondus"
    creation="2020-05-11"
  >
    <>
      <p>
        L'interface évolue ! Un nouvel onglet est désormais disponible pour
        suivre les{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/ai/no-answer-intents${window.location.search}`}
        >
          performances
        </StyledA>{' '}
        de l'Assistant !
      </p>
      <img
        src={require('./medias/no-answer-intents.png')}
        alt="no-answer-intents"
      />
      <p>
        Vous pouvez dès à présent consulter les catégories détectées par
        l'assistant sur les messages pas encore répondus par vos conseillers.
      </p>
    </>
  </FeatureItem>,

  <FeatureItem
    key={uuidv4()}
    title="Éléments variables - Manipulation de date"
    creation="2020-02-27"
  >
    <>
      <p>
        Il est désormais possible de manipuler des dates via les{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/user/settings/metadata${window.location.search}`}
        >
          éléments variables
        </StyledA>
        .
      </p>
      <img
        src={require('./medias/metadatas-luxon.png')}
        alt="metadatas luxon"
      />
      <p>
        Afin de faciliter la prise en main, certains exemples sont disponibles
        via l'interface et les documentations complètes (en anglais) sont
        également accessibles.
      </p>
    </>
  </FeatureItem>,

  <FeatureItem
    key={uuidv4()}
    title="Statistiques - Type d'assistance par modèle de réponse"
    creation="2020-01-28"
  >
    <>
      <p>
        L'affichage du détail des statistiques de vos modèles de réponses par
        catégorie évolue&nbsp;! Nous avons ajouté une icône vous permettant de
        différencier les modèles de réponses sélectionnés par un de vos
        conseiller, sans l'assistance, à ceux proposés par l'assistance.
      </p>
      <p>
        Rendez-vous dès à présent dans la section{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/analytics/intents${window.location.search}`}
        >
          catégories
        </StyledA>{' '}
        des statistiques de l'Assistance.
      </p>

      <img
        src={require('./medias/analytics-intents-disting-macros.png')}
        alt="Live-auto-replies"
      />
    </>
  </FeatureItem>,

  <FeatureItem
    key={uuidv4()}
    title="Statistiques - Suivi des réponses automatiques"
    creation="2019-12-01"
  >
    <>
      <p>
        Le suivi des réponses automatiques est désormais disponible depuis
        l'onglet Statistiques&nbsp;! Vous pouvez suivre en temps réel le détail
        des réponses automatiques programmées, envoyées ou rejetées.
      </p>
      <p>
        Rendez-vous dès à présent dans la section{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/analytics/live-auto-replies${window.location.search}`}
        >
          Réponses automatiques
        </StyledA>{' '}
        des statistiques de l'Assistance.
      </p>

      <img
        src={require('./medias/live-auto-replies.gif')}
        alt="Live-auto-replies"
      />
    </>
  </FeatureItem>,

  <FeatureItem
    key={uuidv4()}
    title="Statistiques - Assistance par modèle de réponse"
    creation="2019-11-01"
  >
    <>
      <p>
        Nous avons amélioré l'affichage de vos statistiques par catégorie&nbsp;!
        Vous pouvez désormais afficher le détail de l'Assistance, par modèle de
        réponse, et pour chaque catégorie.
      </p>
      <p>
        Rendez-vous dès à présent dans la section{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/analytics/intents${window.location.search}`}
        >
          catégories
        </StyledA>{' '}
        des statistiques de l'Assistance afin d'explorer les performances pour
        chaque modèle de réponse.
      </p>

      <img
        src={require('./medias/analytics-intents-details.gif')}
        alt="Details analytics"
      />
    </>
  </FeatureItem>,

  <FeatureItem key={uuidv4()} title="Actions Backoffice" creation="2019-11-01">
    <>
      <p>
        La création des Actions Backoffice est disponible directement depuis la
        section{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/config/_actions${window.location.search}`}
        >
          configuration
        </StyledA>
        .
      </p>

      <img src={require('./medias/actions-list.png')} alt="Actions list" />

      <p>
        Vous pouvez définir le comportement de chaque action, en spécifier la
        disponibilité et les adapter à vos modèles de réponse.
      </p>

      <img
        src={require('./medias/actions-parameters.png')}
        alt="Actions parameters"
      />
    </>
  </FeatureItem>,

  <FeatureItem key={uuidv4()} title="Filtres et tableaux" creation="2019-10-01">
    <>
      {' '}
      <p>
        Nous avons amélioré les listes des catégories, des modèles de réponse
        ainsi que des Actions Backoffice en ajoutant la possibilité de filtrer
        les résultats et l'affichage en fonction de nombreux critères.
      </p>
      <img src={require('./medias/table-filters.png')} alt="Table filters" />
      <p>
        Ces nouveaux filtres permettent une gestion plus efficace et une
        visualisation plus pertinente de la configuration de votre Assistant.
      </p>
    </>
  </FeatureItem>,

  <FeatureItem
    key={uuidv4()}
    title="Analyse de la pertinence"
    creation="2019-09-01"
  >
    <>
      <p>
        Il est possible de suivre la pertinence de l'Assistant via une toute
        nouvelle fonctionnalité&nbsp;!
      </p>
      <img src={require('./medias/sabotage.gif')} alt="Sabotage" />
      <p>
        Rendez-vous dans les{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/user/settings/auto-reply${window.location.search}`}
        >
          paramètres
        </StyledA>{' '}
        afin de définir une quantité de messages à analyser.
      </p>
      <p>
        Un rapport sera disponible dans la section{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/user/settings/auto-reply${window.location.search}`}
        >
          statistiques
        </StyledA>{' '}
        une fois le rapport généré.
      </p>
    </>
  </FeatureItem>,

  <FeatureItem key={uuidv4()} title="Éléments variables" creation="2019-08-01">
    <>
      {' '}
      <p>
        Gagnez du temps en personnalisant et en enrichissant automatiquement vos
        réponses grâce aux éléments variables&nbsp;!
      </p>
      <img src={require('./medias/metadata.gif')} alt="metadata" />
      <p>
        Rendez-vous dans les{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/user/settings/metadata${window.location.search}`}
        >
          paramètres
        </StyledA>{' '}
        afin de définir les éléments que vous souhaitez rendre variables dans
        vos modèles de réponse.
      </p>
      <p>
        Ces éléments variables seront remplacés par les informations issues de
        vos APIs ou de votre backoffice.
      </p>
    </>
  </FeatureItem>,

  <FeatureItem key={uuidv4()} title="Nouvel accueil" creation="2019-07-01">
    <>
      {' '}
      <p>
        La{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/home${window.location.search}`}
        >
          page d'accueil
        </StyledA>{' '}
        présente désormais des KPIs mettant en avant les gains de productivité
        de vos équipes et le temps gagné pour vos clients.
      </p>
      <img src={require('./medias/home.png')} alt="homepage" />
      <p>Ces données sont calculées sur les 30 derniers jours.</p>
    </>
  </FeatureItem>,

  <FeatureItem
    key={uuidv4()}
    title="RGPD - Export des données"
    creation="2019-06-01"
  >
    <>
      <p>
        Une page{' '}
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubwa.re/user/settings/privacy${window.location.search}`}
        >
          protection des données
        </StyledA>{' '}
        est disponible et vous permet de gérer vos archivages de données, ainsi
        que la suppression de celles-ci et de votre compte le cas échéant.
      </p>

      <img src={require('./medias/data-export.png')} alt="Data export" />
    </>
  </FeatureItem>,
];

export default Features;
