// @flow
import {
  chain,
  find,
  set,
  get,
  sumBy,
  groupBy,
  map,
  mapValues,
  keyBy,
} from 'lodash';
import {EVENT_VALUES} from '../../../../lib/analytics';

export const formatValues = (
  data: Object,
  keys: Array<string>
): Array<Object> =>
  chain(data)
    .get('botUsage', [])
    .groupBy('aggregation.intent')
    .map((usages, intent) => {
      const res = {
        intent: intent === 'null' ? 'no_intent' : intent,
      };

      keys.forEach((key) => {
        set(
          res,
          key,
          find(usages, (u) => u.aggregation.value === key)?.value ?? 0
        );
      });

      return res;
    })
    .value();

export const getDoughnutRatio = (
  formatedValues: Array<Object>,
  usage1: string,
  usage2: string
): Array<{data: Array<number>}> => [
  {
    data: [sumBy(formatedValues, usage1), sumBy(formatedValues, usage2)],
    backgroundColor: [EVENT_VALUES[usage1].color, '#dee2e6'],
  },
];

export const getHistoryRatio = (
  formatedValues: Array<Object>,
  usage1: string,
  usage2: string
): number => {
  const sum1 = sumBy(formatedValues, usage1);
  const sum2 = sumBy(formatedValues, usage2);
  const total = sum1 + sum2;
  return Math.round((sum1 / total) * 100);
};

export const formatTemplateData = (
  data: Object,
  keys: Array<string>,
  intent: ?string
): Array<Object> => {
  const groupedByIntent = groupBy(
    get(data, 'botUsage', []),
    'aggregation.intent'
  );

  const groupedByTemplate = groupBy(
    get(groupedByIntent, intent === 'no_intent' ? 'null' : intent, []),
    'aggregation.macroid'
  );

  const mappedTmp = map(groupedByTemplate, (value, key) => ({
    macroid: key,
    ...mapValues(
      keyBy(
        value.filter(({aggregation}) => keys.includes(aggregation.value)),
        'aggregation.value'
      ),
      'value'
    ),
  }));

  return mappedTmp;
};
