import React, {useEffect, useState} from 'react';
import CodeFlask from 'codeflask';
import js, {html} from 'js-beautify';
import {debounce} from 'lodash';
import {Button} from 'reactstrap';
import {Code} from 'react-feather';
import styled from 'styled-components';

const CodeEditor = ({
  code = '',
  onChange = () => {},
  setEditorRef,
  language = 'js',
  readOnly = false,
  id = 'react-codeflask-root',
}: {
  code?: string;
  onChange?: (x: string) => void;
  setEditorRef?: (x: Object) => void;
  language?: string;
  readOnly?: boolean;
  id?: string;
}) => {
  const [codeFlask, setCodeFlask] = useState(undefined);

  useEffect(() => {
    const flaskRef = new CodeFlask(`#${id}`, {
      language: language,
      readOnly: readOnly,
    });
    setCodeFlask(flaskRef);
  }, [id, language, readOnly]);

  useEffect(() => {
    if (codeFlask) {
      if (readOnly) {
        codeFlask.enableReadonlyMode();
      }

      if (setEditorRef) {
        setEditorRef(codeFlask);
      }
      const debounced = debounce((setCode, code: string) => {
        return setCode(code);
      }, 400);
      codeFlask.onUpdate((value: string) => debounced(onChange, value));
    }
  }, [codeFlask, onChange, readOnly, setEditorRef]);

  useEffect(() => {
    if (codeFlask) {
      if (code !== codeFlask.getCode()) {
        codeFlask.updateCode(beautify(code, language));
      }
    }
  }, [code, codeFlask, language]);

  return (
    <div className="d-flex position-relative h-100">
      {!readOnly && codeFlask && (
        <StyledFormatButton
          onClick={() =>
            codeFlask.updateCode(beautify(codeFlask.getCode(), language))
          }
        >
          <Code size="15" /> Formater
        </StyledFormatButton>
      )}
      <StyledEditorWrapper id={id} />
    </div>
  );
};

export default CodeEditor;

function beautify(code: string, language: string) {
  const opts = {
    indent_size: 2,
    wrap_line_length: 100,
  };

  if (language === 'html') {
    return html(code, opts);
  }

  return js(code, opts);
}

const StyledEditorWrapper = styled.div`
  margin-top: 10px;
  position: relative;
  width: 100%;

  .codeflask {
    .token.tag {
      color: var(--primary);
    }
    .token.punctuation {
      color: var(--info);
    }
    .token.attr-name {
      color: var(--hubware);
    }
  }
`;

const StyledFormatButton = styled(Button).attrs({
  outline: true,
  color: 'secondary',
  size: 'sm',
})`
  background-color: #fff;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1000;
`;
