// @flow
import firebase from 'firebase/app';
import {pick} from 'lodash';

import {parseObjectWithDates} from './utils';

export const getReplies = (
  range?: {
    startDate?: Date,
    endDate?: Date,
  },
  cb: Function
) => async (dispatch: Function, getState: Function) => {
  let query = await firebase
    .firestore()
    .collection('replies')
    .where('settingsId', '==', getState().firestore.setting.id);

  if (range && range.startDate) {
    query = query.where('replyAt', '>=', range.startDate);
  }
  if (range && range.endDate) {
    query = query.where('replyAt', '<=', range.endDate);
  }

  if (range) {
    query = query.limit(100);
  }

  return query
    .orderBy('replyAt', 'desc')
    .onSnapshot(({docs}) =>
      cb(
        docs.map((doc) =>
          pick(parseObjectWithDates(doc.data()), [
            'externalId',
            'replyAt',
            'status',
            'macroId',
            'endUserEmail',
            'reason',
            'replyToAddress',
          ])
        )
      )
    );
};
