// @flow
import React from 'react';
import {UncontrolledTooltip as Ut} from 'reactstrap';
import {connect} from 'react-redux';
import {Row, Col} from 'reactstrap';
import {get, chain} from 'lodash';
import {Zap, Clock, Users} from 'react-feather';

import {
  getBoardconfiguration,
  type BOARDCONFIG_INTERFACE,
} from '../../../selectors/board-configuration';
import {Card} from '../../../components/card';
import {EVENT_VALUES} from '../../../lib/analytics';
import Number from './number';
import {DateTime} from 'luxon';

const Numbers = ({
  data,
  isLoading,
  dateRange,
  autoReplyDelay,
  boardConfiguration,
}: {
  data: Object,
  isLoading: boolean,
  dateRange: Object,
  autoReplyDelay: number,
  boardConfiguration: BOARDCONFIG_INTERFACE,
}) => {
  const {
    DELAYS,
    avgProcessingDuration,
    avgProcessingDelay,
  } = boardConfiguration.assistanceBenefits;

  const getCustomersHoursSaved = (usages: Object): number =>
    Math.round(
      (usages.AUTO_REPLY * (avgProcessingDelay - autoReplyDelay)) / 60
    ) + getTeammatesHoursSaved(usages);

  const getTeammatesHoursSaved = (usages: Object): number =>
    Math.round(
      chain(DELAYS)
        .map((value, key) => value * avgProcessingDuration * usages[key] || 0)
        .sum()
        .value() / 60
    );

  return (
    <Card className="mb-3">
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between mb-4 pb-3">
          <h6 className="m-0">
            Impact de l'Assistant Professionel -{' '}
            <strong id="last30Days">30 derniers jours</strong>
            <Ut target="last30Days">
              Du {dateRange.startDate.toLocaleString(DateTime.DATE_MED)} au{' '}
              {dateRange.endDate.toLocaleString(DateTime.DATE_MED)}
            </Ut>
          </h6>
        </div>

        <Row>
          <Col md="4">
            <Number
              isLoading={isLoading}
              color={EVENT_VALUES.AUTO_REPLY.color}
              value={data.AUTO_REPLY}
              title="Conversations répondues immédiatement"
              description="L'assistant à répondu automatiquement à ces conversations sans besoin d'intervention des conseillers"
            >
              <Zap size="17" />
            </Number>
          </Col>

          <Col md="4">
            <Number
              isLoading={isLoading}
              color="var(--warning)"
              value={getCustomersHoursSaved(data)}
              unit="heures"
              title="Temps économisé par vos clients"
              description="Basé sur le temps moyen de réponse à un message par les conseillers"
            >
              <Clock size="17" />
            </Number>
          </Col>

          <Col md="4">
            <Number
              isLoading={isLoading}
              color="var(--success)"
              value={getTeammatesHoursSaved(data)}
              unit="heures"
              title="Temps économisé par vos conseillers"
              description="Basé sur les gains de temps réalisés en fonction du type d'assistance pour chaque message"
            >
              <Users size="17" />
            </Number>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default connect((state) => ({
  boardConfiguration: getBoardconfiguration(state),
  autoReplyDelay: get(
    state,
    'firestore.setting.autoReplyConfiguration.delay.minutes',
    0
  ),
}))(Numbers);
