import React, {useState} from 'react';
import {AlertCircle} from 'react-feather';
import * as jwt from 'jsonwebtoken';

import {Button, Row, Col} from 'reactstrap';
import {DateTime} from 'luxon';

import {RootState} from '../../../../reducer';
import Modal from '../../../../components/modal';

const RefreshToken = ({token}: {token: RootState['firestore']['jwt']}) => {
  const [modal, setModal] = useState(false);
  const expirationWarning =
    DateTime.fromSeconds(jwt.decode(token).exp) <
    DateTime.local().minus({day: 30}).startOf('day');

  return (
    <>
      <h5 className="mb-3">Jeton d'authentification</h5>

      <Row className="mb-2">
        <Col md="7">
          {expirationWarning && <AlertCircle size="12px" className="mr-2" />}
          Expire {DateTime.fromSeconds(jwt.decode(token).exp).toRelative()}
        </Col>
        <Col
          md="5"
          className="align-items-center justify-content-center d-flex"
        >
          <div className="text-center">
            <Button color={'hubware'} onClick={() => setModal(!modal)}>
              {modal ? 'Masquer le token' : 'Afficher le token'}
            </Button>
            {modal && (
              <Modal
                title="Jeton d'authentification"
                modal={modal}
                setModal={setModal}
              >
                <code>{token}</code>
              </Modal>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RefreshToken;
