// @flow
import i18n from '../../../i18n';

export const rgpdInfo = (): Array<{
  title: string,
  description: string,
}> => {
  return [
    {
      title: i18n.t('Your data belongs to you'),
      description: i18n.t(
        `The customer remains the owner of all data transmitted to Hubware, whether it is transmitted, processed or stored by Hubware's services`
      ),
    },
    {
      title: i18n.t(`They are stored in Europe`),
      description: i18n.t(
        `Hubware exclusively uses data centers located in Europe. They are located in Ireland, Netherlands, Finland, and Belgium`
      ),
    },
    {
      title: i18n.t(`They are not shared`),
      description: i18n.t(
        `The data collected allows Hubware to analyze your customer service, make recommendations and produce analyzes as well as assist advisers in order to improve the performance and quality of your customer service.`
      ),
    },
    {
      title: i18n.t('And Hubware respects GDPR regulations'),
      description: i18n.t(
        `Compliance with GDPR regulations is essential for Hubware and our team works on a daily basis to fulfil these requirements. An independent audit is carried out each year on our solution. Hubware guarantees access and rectification of data, as well as their security and confidentiality at all times.`
      ),
    },
  ];
};
