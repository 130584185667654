import {DateTime} from 'luxon';

import queries from './queries';

const getDataHook = (
  operation: string,
  {date: {startDate, endDate}, ...remainingConfig}: Object = {},
  variables?: Object = {}
) => {
  const {config: variablesConfig = {}, ...rest} = variables;
  const {config: defaultVariablesConfig = {}, ...defaultRest} = queries[
    operation
  ].variables;

  const result = [
    queries[operation].query,
    {
      variables: {
        config: {
          date: {
            start: startDate || defaultDateRange.startDate.toISO(),
            end: endDate || defaultDateRange.endDate.toISO(),
          },
          ...defaultVariablesConfig,
          ...remainingConfig,
          ...variablesConfig,
        },
        ...defaultRest,
        ...rest,
      },
    },
  ];

  return result;
};

export default getDataHook;

export const defaultDateRange = {
  startDate: DateTime.local().minus({day: 30}).startOf('day'),
  endDate: DateTime.local().minus({day: 1}).endOf('day'),
};
