// @flow
import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import analyse from './analyse.svg';
import assistance from './assistance.svg';
import auto from './auto.svg';
import identification from './identification.svg';
import jumbotron from './jumbotron.png';

const Ads = () => {
  const {t} = useTranslation();

  return (
    <StyledWrapper>
      <StyledBenefit>
        <img src={analyse} alt="analyse" height="85px" width="85px" />
        <div>
          <h4>{t('Analysis')}</h4>
          <p>
            {t(
              "We analyze your customer's requests as soon as they are received"
            )}
          </p>
        </div>
      </StyledBenefit>
      <StyledBenefit>
        <img src={assistance} alt="assistance" height="85px" width="85px" />
        <div>
          <h4>{t('Identification')}</h4>
          <p>{t('We detect the reason and the context of their requests')}</p>
        </div>
      </StyledBenefit>
      <StyledBenefit>
        <img src={auto} alt="auto" height="85px" width="85px" />
        <div>
          <h4>{t('Assistance')}</h4>
          <p>{t('We provide relevant information and support for reply')}</p>
        </div>
      </StyledBenefit>
      <StyledBenefit>
        <img
          src={identification}
          alt="identification"
          height="85px"
          width="85px"
        />
        <div>
          <h4>{t('Automation')}</h4>
          <p>
            {t('We automate reccuring tasks while controlling your process')}
          </p>
        </div>
      </StyledBenefit>
    </StyledWrapper>
  );
};

export default Ads;

const StyledWrapper = styled.div.attrs({
  className: 'd-none d-md-flex flex-column justify-content-center',
})`
  background: url(${jumbotron}) rgb(243, 243, 245);
  background-clip: padding-box;
  background-position: bottom right;
  background-size: cover;
  border: 10px solid transparent;
  position: relative;

  &::before {
    background: linear-gradient(-225deg, #ffc796, #ff6b95);
    bottom: 0;
    content: '';
    left: 0;
    margin: -10px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
`;

const StyledBenefit = styled.div.attrs({
  className: 'mb-4 ml-4 d-flex align-items-center',
})`
  > div {
    background: rgba(243, 243, 245, 0.4);
    border-radius: 4px;
    width: 50%;

    h4 {
      margin-bottom: 0;
      margin-left: 5px;
    }

    p {
      margin: 0;
    }
  }
`;
