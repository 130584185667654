// @flow
import React, {useState} from 'react';
// $FlowFixMe
import GButton from 'react-google-button';
import {
  FormGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import {type USER_INTERFACE} from '../../../selectors/user';
import {signInWithGoogle, signInWithEmail} from '../../../lib/firebase';

const RecentLogin = ({
  user,
  modal,
  setModal,
  callback,
}: {
  user: USER_INTERFACE,
  modal: boolean,
  setModal: Function,
  callback: Function,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [password, setPassword] = useState('');

  const hasEmailProvider = user.providers.find(({id}) => id === 'password');
  const hasgoogleProvider = user.providers.find(({id}) => id === 'google.com');

  const handleEmailSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
    setError(undefined);

    signInWithEmail(user.email, password)
      .then(async () => {
        await callback();
        setModal(!modal);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoogleSubmit = () => {
    setLoading(true);

    signInWithGoogle()
      .then(async () => {
        await callback();
        setModal(!modal);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={modal} toggle={() => setModal(!modal)} size="md">
      <ModalHeader toggle={() => setModal(!modal)}>
        Authentification nécessaire
      </ModalHeader>
      <ModalBody>
        {hasEmailProvider && (
          <form id="sign-with-email" onSubmit={handleEmailSubmit}>
            <FormGroup>
              <Input
                bsSize="lg"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Mot de passe"
                required
                invalid={Boolean(error)}
                disabled={loading}
              />
              <div className="invalid-feedback">{error}</div>
            </FormGroup>
            <FormGroup>
              <Button color="hubware" size="lg" className="w-100">
                {loading ? 'Chargement...' : 'Valider'}
              </Button>
            </FormGroup>
          </form>
        )}

        {user.providers.length > 1 && (
          <div>
            <hr />
            ou <hr />
          </div>
        )}

        {hasgoogleProvider && (
          <GButton
            className="w-100"
            onClick={handleGoogleSubmit}
            type="light"
            label="Se connecter avec Google"
            disabled={loading}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default RecentLogin;
