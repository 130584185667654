// @flow
import React, {useState, useEffect, type Node} from 'react';
import {useSelector, useStore} from 'react-redux';
import {without} from 'lodash';
import {AlertCircle} from 'react-feather';
import {Button} from 'reactstrap';

import {
  getAllTemplates,
  type TEMPLATE_INTERFACE,
} from '../../../../selectors/templates';
import {Card} from '../../../../components/card';
import EditBtn from '../../../../components/edit-btn';
import ListItem from './list-item';
import UnselectedList from './unselected-list';

const TemplatesSelectList = ({
  selectedTemplates,
  onValidate,
  editOnMount = false,
  children,
}: {
  selectedTemplates: Array<string>,
  onValidate: (x: Array<string>) => void,
  editOnMount?: boolean,
  children?: (x: TEMPLATE_INTERFACE) => Node,
}): Node => {
  const templates = useSelector(getAllTemplates);

  const [edit, setEdit] = useState(editOnMount);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const store = useStore();
  const autoReplyAuthorSet = store.getState()?.firestore?.setting
    ?.autoReplyConfiguration?.authorIds;
  const salesforceProvider = store.getState()?.data?.provider === 'salesforce';

  useEffect(() => {
    setSelectedIds(selectedTemplates);
  }, [selectedTemplates]);

  const handleValidate = async () => {
    setLoading(true);
    await onValidate(selectedIds);
    setEdit(false);
    setLoading(false);
  };

  const handleCancel = () => {
    setSelectedIds(selectedTemplates);
    setEdit(false);
  };

  const toggleSelectedTmpsIds = (id: string) => {
    selectedIds.includes(id)
      ? setSelectedIds(without(selectedIds, id))
      : setSelectedIds([...selectedIds, id]);
  };

  return (
    <Card>
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div>
            <h4>Modèles de réponses</h4>
            <h6 className="card-subtitle mb-2 text-muted">
              Sélectionnez les modèles de réponses à associer
            </h6>

            {!salesforceProvider && !autoReplyAuthorSet && (
              <div
                className="d-flex align-items-center justify-content-between py-2 px-2"
                style={{backgroundColor: '#fafafa'}}
              >
                <AlertCircle size={18} className="mr-2" />
                Pour activer les réponses automatiques, un auteur doit être
                sélectionné.
                <Button
                  size="sm"
                  color="link"
                  className="text-hubware"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://app.hubwa.re/user/settings/auto-reply${window.location.search}`}
                >
                  Configurer
                </Button>
              </div>
            )}
          </div>
          <EditBtn
            isEditing={edit}
            toggleEdit={() => setEdit(!edit)}
            onValidate={handleValidate}
            onCancel={handleCancel}
            disabled={loading}
          />
        </div>
        <table className="table my-4 light-table">
          <tbody>
            {!edit && selectedTemplates.length === 0 && (
              <tr>
                <td colSpan="100%" className="text-center text-muted">
                  Aucun modèle de réponse sélectionné
                </td>
              </tr>
            )}
            {selectedIds.map((id, index) => (
              <ListItem
                key={`template-${index}`}
                template={templates.find((t) => t.id === id)}
                edit={edit}
                isSelected={selectedIds.includes(id)}
                toggleSelected={toggleSelectedTmpsIds}
              >
                {children && children(templates.find((t) => t.id === id))}
              </ListItem>
            ))}
            {edit && (
              <UnselectedList
                templates={templates.filter(
                  ({id}) => !selectedIds.includes(id)
                )}
                toggleSelected={toggleSelectedTmpsIds}
              />
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default TemplatesSelectList;
