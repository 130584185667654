// @flow
import React from 'react';
import {PlusCircle, User, Users, Check} from 'react-feather';
import {connect} from 'react-redux';
import styled from 'styled-components';

import Table from '../table';
import filters from '../table/filters/filters';

export const OperatorsList = ({
  assignees,
  loading,
  selectedValue,
  handleClick,
  enableGroups,
}: {
  assignees: {
    groups: Array<Object> | null,
    operators: Array<Object> | null,
  },
  loading: boolean,
  selectedValue: Object,
  handleClick: Function,
  enableGroups: boolean,
}) => (
  <StyledTable>
    <Table
      isLoading={loading}
      data={prepareTableValues(assignees, enableGroups)}
      hiddenColumns={['operator', 'group']}
      columns={[
        {
          Header: '',
          accessor: 'type',
          Cell: ({cell: {row}}: {cell: {row?: Object}}) => {
            if (String(row?.original?.id) === String(selectedValue?.id)) {
              return <Check color="green" size="15px" />;
            }
            return row?.original?.type === 'groupId' ? (
              <Users color="grey" size="15px" />
            ) : (
              <User color="grey" size="15px" />
            );
          },
        },
        {
          Header: 'Nom',
          accessor: 'name',
          Filter: filters.input,
          Cell: ({row}: {row: Object}) =>
            row?.original?.name +
            (row?.original?.email ? ` - ${row?.original?.email}` : ''),
        },
        {
          Header: 'Operateurs',
          id: 'operator',
          accessor: (row: Object) => row?.type === 'operatorId',
          filter: 'bool',
          Filter: enableGroups && filters.toggle,
        },
        {
          Header: 'Groupes',
          id: 'group',
          accessor: (row: Object) => row?.type === 'groupId',
          filter: 'bool',
          Filter: enableGroups && filters.toggle,
        },
        {
          Header: '',
          accessor: 'id',
          disableSorting: true,
          Cell: ({row}: {row: Object}) =>
            String(row?.original?.id) === String(selectedValue?.id) ? (
              <div />
            ) : (
              <div
                onClick={() => handleClick(row.original)}
                className="clic text-center ml-auto text-hubware"
                style={{width: '20px'}}
              >
                <PlusCircle size="15px" />
              </div>
            ),
        },
      ]}
    />
  </StyledTable>
);

export default connect(({firestore}) => ({
  assignees: {
    groups: firestore?.zendeskGroups,
    operators: firestore?.zendeskAuthors,
  },
}))(OperatorsList);

function prepareTableValues(
  {
    operators,
    groups,
  }: {
    operators: Array<Object> | null,
    groups: Array<Object> | null,
  },
  enableGroups: boolean
) {
  const tagOperators = (operators || []).map((operator) => ({
    ...operator,
    type: 'operatorId',
  }));
  const tagGroups = (groups || []).map((group) => ({
    ...group,
    type: 'groupId',
  }));
  if (enableGroups) {
    return [...tagOperators, ...tagGroups];
  }

  return tagOperators;
}

const StyledTable = styled.div`
  th:first-child,
  td:first-child {
    width: 50px;
  }
  th:last-child,
  td:last-child {
    text-align: right;
    width: 50px;
  }
  margin-bottom: 2rem;
`;
