// @flow
import {chain} from 'lodash';

import {
  REFUSED_ASSISTANCE_USAGES,
  ASSISTANCE_USAGES,
} from '../../../../lib/analytics';

export const getTotalCount = (analytics: Object): number =>
  chain(analytics)
    .pick([...ASSISTANCE_USAGES, ...REFUSED_ASSISTANCE_USAGES, 'NO_ASSISTANCE'])
    .reduce((sum, n) => sum + n)
    .value() || 0;

export const getTotalRefusedAssistance = (analytics: Object): number =>
  chain(analytics)
    .pick(REFUSED_ASSISTANCE_USAGES)
    .reduce((sum, n) => sum + n)
    .value() || 0;

export const getFullAssistanceCoverage = (
  analytics: Object,
  totalCount: number
): number => {
  if (totalCount === 0) {
    return 0;
  }

  return (
    (chain(analytics)
      .pick([...ASSISTANCE_USAGES, ...REFUSED_ASSISTANCE_USAGES])
      .reduce((sum, n) => sum + n)
      .value() /
      totalCount) *
    100
  );
};
