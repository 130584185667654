// @flow
import React, {useMemo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Button} from 'reactstrap';
import {some} from 'lodash';
import {Check, UserX} from 'react-feather';

import {deleteOperatorIntentPair} from '../../../../../lib/firestore/operators';

const Paired = ({
  assignees,
  intent,
  loading,
  pair,
}: {
  assignees: {groups: Array<Object> | null, operators: Array<Object> | null},
  intent: Object,
  loading: boolean,
  pair: {id: string, name: string, type: string},
}) => {
  const dispatch = useDispatch();

  const isValid = useMemo(() => {
    const key = pair.type === 'operatorId' ? 'operators' : 'groups';
    return some(assignees[key], ['id', Number(pair.id)]);
  }, [pair, assignees]);

  const typei18n = useMemo(
    () => (pair.type === 'groupId' ? 'Le groupe' : `Le conseiller`),
    [pair]
  );

  const onDelete = useCallback(
    async (intentKey: string) => {
      await dispatch(deleteOperatorIntentPair(intentKey));
    },
    [dispatch]
  );

  return (
    <>
      <p className="mt-3 mb-2 d-flex align-items-center">
        {isValid ? (
          <>
            <Check size={20} className="text-success mr-2" />
            {typei18n} <strong className="mx-1">{pair.name}</strong> est assigné
            automatiquement à la catégorie {intent.title.toLowerCase()}.
          </>
        ) : (
          <>
            <UserX
              size={20}
              className="text-danger mr-3"
              style={{opacity: loading ? 0 : 1}}
            />
            {typei18n} <strong className="mx-1">{pair.name}</strong> n'est plus
            disponible dans votre instance Zendesk.
          </>
        )}
      </p>
      <div className="mt-4 text-right">
        <Button
          size="sm"
          color="link"
          className="text-hubware"
          onClick={() => onDelete(intent.key)}
        >
          Supprimer l'assignation automatique
        </Button>
      </div>
    </>
  );
};

export default Paired;
