// @flow
import React from 'react';
import styled from 'styled-components';

export const Card = styled.div.attrs({
  className: 'card',
})`
  margin-bottom: 50px;

  .card-title {
    font-weight: 600;
  }

  input,
  select {
    &.input-form {
      background-color: $body-bg;
      font-size: 1rem;
    }
  }

  label {
    font-weight: 600;
  }
`;

export const CardLoading = () => (
  <div className="d-flex align-items-center h-100 justify-content-center">
    Chargement
  </div>
);

export const CardError = () => (
  <Card className="mx-auto">
    <div className="card-body text-center">
      <div className="card-title my-2">
        Une erreur est survenue. Merci de réessayer ultèrieurement.
      </div>
    </div>
  </Card>
);
