// @flow
import React, {useState, type Node} from 'react';
import styled from 'styled-components';
import {Row, Col, Input, Button, Label} from 'reactstrap';
import {ArrowRight, Server} from 'react-feather';
import {useTranslation} from 'react-i18next';

import zendesk from './medias/zendesk.png';
import salesforce from './medias/salesforce.png';
import intercom from './medias/intercom.png';

const Provider = ({
  updateOnboarding,
}: {
  updateOnboarding: (x?: string) => void,
}) => {
  const [radioValue, setRadioValue] = useState(undefined);
  const [otherValue, setOtherValue] = useState('');

  const {t} = useTranslation();

  const enableNext = () => {
    if (!radioValue) {
      return false;
    } else if (radioValue !== 'other') {
      return true;
    }

    return otherValue !== '';
  };

  const handleClick = async () => {
    updateOnboarding(otherValue || radioValue);
  };

  const choices = [
    {
      provider: 'zendesk',
      children: () => <img src={zendesk} alt="zendesk" />,
    },
    {
      provider: 'salesforce',
      children: () => <img src={salesforce} alt="salesforce" />,
    },
    {
      provider: 'intercom',
      children: () => <img src={intercom} alt="intercom" />,
    },
    {
      provider: 'other',
      children: () => (
        <div id="other-wrapper">
          <Server size="45" className="text-muted mb-4" />
          <div>{t('Other solutions')}</div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Row>
        {choices.map(({provider, children}) => (
          <Col sm="auto" className="py-4" key={provider}>
            <ProviderChoice
              radioValue={radioValue}
              setRadioValue={setRadioValue}
              provider={provider}
            >
              {children()}
            </ProviderChoice>
          </Col>
        ))}
      </Row>

      <Row>
        <Input
          className={`d-flex align-items-center w-25 ml-3 ${
            radioValue === 'other'
              ? 'animate__animated animate__fadeInLeft animate__faster'
              : 'invisible'
          }`}
          type="text"
          value={otherValue}
          onChange={(e) => setOtherValue(e.target.value)}
          placeholder="Précisez la solution"
        />
      </Row>

      <Button
        color="hubware"
        className={`d-flex align-items-center ${
          enableNext()
            ? 'animate__animated animate__fadeInLeft animate__faster'
            : 'invisible'
        }`}
        disabled={!enableNext()}
        style={{marginTop: '40px'}}
        onClick={handleClick}
      >
        {t('Next')} <ArrowRight size="22" />
      </Button>
    </>
  );
};

export default Provider;

const ProviderChoice = ({
  radioValue,
  setRadioValue,
  provider,
  children,
}: {
  radioValue?: string,
  setRadioValue: (x: string) => void,
  provider: string,
  children: Node,
}) => (
  <StyledLabel active={(radioValue === provider).toString()}>
    {children}
    <input
      className="d-none"
      type="radio"
      name="provider"
      value={provider}
      onChange={(e) => setRadioValue(e.target.value)}
      checked={radioValue === provider}
    />
  </StyledLabel>
);

const StyledLabel = styled(Label).attrs({className: 'clic', check: true})`
  border: 1px solid
    var(${({active}) => (active === 'true' ? '--hubware' : '--gray-300')});
  border-radius: 4px;
  box-sizing: content-box;
  display: block;
  height: 150px;
  margin: auto;
  text-align: center;
  width: 150px;
  background: #fff;
  color: var(--);
  box-shadow: 1px 1px 4px #ddd;

  img {
    height: 150px;
    pointer-events: none;
    width: 150px;
  }

  #other-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: center;
    width: 150px;

    input {
      border-left-width: 0;
      border-radius: 0;
      border-right-width: 0;

      &:focus {
        border-color: var(--hubware);
      }
    }

    svg line {
      animation: colorChange 3s infinite step-start;
    }
    @keyframes colorChange {
      0% {
        stroke: var(--gray);
      }
      5% {
        stroke: var(--hubware);
      }
      10% {
        stroke: var(--gray);
      }
      15% {
        stroke: var(--hubware);
      }
      20% {
        stroke: var(--gray);
      }
      100% {
        stroke: var(--gray);
      }
    }
  }
`;
