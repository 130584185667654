// @flow
import React, {useState} from 'react';
import {Row, Col} from 'reactstrap';
import {useQuery, useManualQuery} from 'graphql-hooks';
import {sumBy, chain} from 'lodash';

import {CardError} from '../../../../components/card';
import ButtonsGroup from '../../components/buttons-group';
import LineChart from '../../components/line';
import Progress from '../../components/progress';
import getDataHook from '../../../../lib/graphQL';

import {
  buildLineDataset,
  getProgressData,
  getHsitoryProgressData,
} from './utils';

const AnalyticsCoverage = ({
  dateRange,
  diffDateRange,
}: {
  dateRange: Object,
  diffDateRange: Object,
}) => {
  // in-view controller
  const [view, setView] = useState('global');
  const views = [
    {key: 'global', title: 'Couverture globale'},
    {key: 'details', title: 'Couverture détaillée'},
  ];

  const {loading, error, data} = useQuery(
    ...getDataHook('getBotUsage', {date: dateRange})
  );

  const [fetchHistoryData, historyState] = useManualQuery(
    ...getDataHook('getBotUsageTrend', {date: diffDateRange})
  );

  if (error || historyState.error) {
    return <CardError />;
  }

  if (
    !loading &&
    !historyState.data &&
    !historyState.loading &&
    !historyState.error
  ) {
    fetchHistoryData();
  }

  // set const with uniq yvalues (here time values)
  const yLabels = chain(data)
    .get('botUsage')
    .map(({date}) => date)
    .uniq()
    .value();

  return (
    <>
      <Row>
        <ButtonsGroup view={view} setView={setView} views={views} />
      </Row>

      <Row>
        <Col>
          <LineChart
            labels={yLabels}
            yaxes={['coverage']}
            options={{animation: {duration: 0}}}
            datasets={buildLineDataset(yLabels, data, view).filter(
              (dataset) => sumBy(dataset.data, 'y') > 0
            )}
            isLoading={loading}
          />

          <Progress
            data={getProgressData(data)}
            isLoading={loading}
            historyData={getHsitoryProgressData(historyState.data)}
            historyRange={diffDateRange}
          />
        </Col>
      </Row>
    </>
  );
};

export default AnalyticsCoverage;
