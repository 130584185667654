// @flow
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import UsersList from './users-list';

const UsersModal = ({user, history, children}) => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <div onClick={() => setModal(true)} className="text-hubware clic">
        {children}
      </div>
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <ModalHeader toggle={() => setModal(!modal)}>Users</ModalHeader>
        <ModalBody>
          <UsersList connectedUser={user} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default withRouter(
  connect(({firestore}) => ({user: firestore.user}))(UsersModal)
);

UsersModal.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
