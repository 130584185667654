import {AppState} from '../../reducer/types/app-types';

export const RESET_STORE = 'RESET_STORE';
export const SET_LOGGED_USER = 'SET_LOGGED_USER';
export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export const SET_ERROR = 'SET_ERROR';
export const REFRESH_PARAMS = 'REFRESH_PARAMS';
export const SET_UI_STATE = 'SET_UI_STATE';

interface ResetStore {
  type: typeof RESET_STORE;
  stores: AppState['stores'];
}

interface SetLoggedUser {
  type: typeof SET_LOGGED_USER;
  user: AppState['loggedUser'];
}
interface SetAdmin {
  type: typeof SET_IS_ADMIN;
  isAdmin: AppState['isAdmin'];
}
interface SetError {
  type: typeof SET_ERROR;
  error: AppState['error'];
}
interface RefreshParams {
  type: typeof REFRESH_PARAMS;
  debug?: AppState['debug'];
  offline?: AppState['offline'];
}
interface SetUiState {
  type: typeof SET_UI_STATE;
  uiState: AppState['uiState'];
  force?: AppState['force'];
}

export type AppActionsTypes =
  | SetLoggedUser
  | SetAdmin
  | SetError
  | RefreshParams
  | SetUiState
  | ResetStore;
