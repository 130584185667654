// @flow
import {get, omitBy, isNil} from 'lodash';

import {updateSettingAtPath} from './setting';

export const addAction = ({
  title,
  description,
  id,
}: {
  title: string,
  description?: string,
  id: string,
}) => async (dispatch: Function, getState: Function) =>
  dispatch(
    updateSettingAtPath('actions', [
      ...get(getState(), 'firestore.setting.actions', []),
      removeEmptyFields({
        id,
        title,
        description: description || undefined, // action.description can't be empty string
        isActive: false,
      }),
    ])
  );

export const updateAction = ({id, ...rest}: {id: string}) => async (
  dispatch: Function,
  getState: Function
) => {
  const actions = get(getState(), 'firestore.setting.actions', []);

  if (!actions.find((action) => action.id === id)) {
    return;
  }

  return dispatch(
    updateSettingAtPath(
      'actions',
      get(getState(), 'firestore.setting.actions', []).map((action) =>
        action.id === id ? removeEmptyFields({...action, ...rest}) : action
      )
    )
  );
};

export const deleteAction = (id: string) => async (
  dispatch: Function,
  getState: Function
) =>
  dispatch(
    updateSettingAtPath('actions', [
      ...get(getState(), 'firestore.setting.actions', []).filter(
        (action) => action.id !== id
      ),
    ])
  );

function removeEmptyFields(obj: Object): Object {
  return omitBy(obj, isNil);
}
