// @flow
import {sum} from 'lodash';

import {EVENT_VALUES} from '../../../../lib/analytics';

export const buildBarsDataset = (
  yLabels: Array<string>,
  groupedValues: Object,
  usages: Array<string>
): Array<Object> =>
  usages.map((usage, i) => {
    const {shortTitle, color} = EVENT_VALUES[usage];
    const values = groupedValues[usage] || [];
    const data = yLabels.map(
      (date) => values.find((v) => v.date === date)?.value ?? 0
    );

    return {
      yAxisID: 'responses',
      label: `${shortTitle} (${sum(data)})`,
      backgroundColor: color,
      borderColor: 'transparent',
      data: yLabels.map((date) => ({
        x: date,
        y: values.find((v) => v.date === date)?.value ?? 0,
      })),
    };
  });
