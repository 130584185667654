// @flow
import queryString from 'query-string';

export const percent = (fl: number = 0, digits: number = 2): string => {
  const res = (Math.round(fl * 10000) / 100).toFixed(digits);
  return isNaN(res) ? '-' : res;
};

export const getAppSetting = (param: string): boolean =>
  Object.keys(queryString.parse(window.location.search)).includes(param) ||
  localStorage.getItem(param) === 'true';

export const NEW_DATE_FORMAT = 'dd-MM-yyyy';
export const DATE_FORMAT = 'DD-MM-YYYY';

export const downloadFile = (
  content: string,
  filename: string,
  type: string
) => {
  const blob = new Blob([content], {type: `text/${type};charset=utf-8;`});
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `${filename}.${type}`);
  link.style.visibility = 'hidden';
  // $FlowFixMe
  document.body.appendChild(link);
  link.click();
  // $FlowFixMe
  document.body.removeChild(link);
};

export const getFromZendesk = (url: string): ?string =>
  queryString.parse(window.location.search)?.fromZendesk;
