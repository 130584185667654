import {useState, useCallback} from 'react';
import {useDispatch, useStore} from 'react-redux';
import i18n from '../../../../i18n';

import {updateSettingAtPath} from '../../../../lib/firestore/setting';
import {
  addCustomLogicBloc,
  deleteCustomLogicBloc,
  updateCustomLogicBloc,
} from '../../../../lib/firestore/logic';
import {Bloclogic} from '../../../../reducer/types/firestore-types';
import {RootState} from '../../../../reducer/index';

import {getCustomedMacros, updateMacroFilters} from './utils';

const NEWBLOC = {
  id: 'new',
  type: '',
  description: '',
  code: i18n.t("// Create your own logic : Should('...') //"),
};

const useCustomBloc = () => {
  const [modal, setModal] = useState(false);
  const [selectBloc, setSelectBloc] = useState<Bloclogic>();

  const dispatch = useDispatch();

  const store = useStore<RootState>();
  const macroFilters = store.getState().firestore.setting.macroFilters;

  const getBlocUsage = useCallback(
    (editLogic: Bloclogic) =>
      getCustomedMacros(macroFilters, editLogic).map((id) =>
        macroFilters.find((mf) => mf.id === id)
      ),
    [macroFilters]
  );
  const handleDelete = useCallback(
    (editLogic: Bloclogic) => {
      if (editLogic?.id) {
        dispatch(deleteCustomLogicBloc(editLogic.id));
      }
      setModal(false);
    },
    [dispatch]
  );

  const handleValidate = useCallback(
    (editLogic: Bloclogic) => {
      if (editLogic?.id === 'new') {
        // 💩 maybe use type ?
        dispatch(
          addCustomLogicBloc({
            ...editLogic,
            type: `customlogicbloc-${editLogic.type}`,
          })
        );
      } else {
        dispatch(updateCustomLogicBloc(editLogic));
        dispatch(
          updateSettingAtPath(
            'macroFilters',
            updateMacroFilters(macroFilters, editLogic)
          )
        );
      }
      setModal(false);
    },
    [dispatch, macroFilters]
  );

  const openModal = useCallback(
    (bloc: Bloclogic = NEWBLOC) => {
      setModal(!modal);
      setSelectBloc(bloc);
    },
    [modal, setModal]
  );

  return {
    handleValidate,
    handleDelete,
    openModal,
    setModal,
    modal,
    getBlocUsage,
    selectBloc,
  };
};

export default useCustomBloc;
