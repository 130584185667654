import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {CornerLeftUp} from 'react-feather';
import {UncontrolledTooltip as Ut} from 'reactstrap';

const BackBtn = ({
  to,
  listName,
}: {
  to: {
    pathname: string;
    search: string;
  };
  listName: string;
}) => (
  <>
    <BackLink to={to} id="back-btn">
      <CornerLeftUp size={18} />
    </BackLink>
    <Ut placement="right" target="back-btn">
      Retour à la liste des {listName}
    </Ut>
  </>
);

export default BackBtn;

const BackLink = styled(Link).attrs({
  className:
    'text-hubware text-center mr-2 d-flex align-items-center justify-content-center',
})`
  border-radius: 30px;
  height: 25px;
  position: relative;
  vertical-align: middle;
  width: 30px;
`;
