// @flow
import React from 'react';
import {connect} from 'react-redux';
import {get, findLast} from 'lodash';

import {oauthConstants} from '../../../../lib/zendesk';
import i18n from '../../../../i18n';

import Form from './form';
import Alerts from './alerts';

const ZendeskDomainStep = ({
  isActive,
  updateOnboarding,
  onboarding,
}: {
  isActive: boolean,
  updateOnboarding: (x?: string) => void,
  onboarding: Array<Object>,
}) => {
  const handleClick = (domain) => {
    openPopup(domain);
    updateOnboarding(domain);
  };

  return (
    <>
      <Form
        onClick={handleClick}
        isActive={isActive}
        domain={get(findLast(onboarding, {id: 'ZENDESK_DOMAIN_STEP'}), 'value')}
      />
      <Alerts oAuthError={onboarding.find((o) => o.id === 'OAUTH_ERROR')} />
    </>
  );
};

export default connect(({firestore}) => ({
  onboarding: get(firestore.user, 'uiSettings.onboarding', []),
}))(ZendeskDomainStep);

function openPopup(domain: string) {
  const link = oauthConstants(domain).link;
  const newWindow = window.open(link, 'popup', 'width=800,height=500');
  if (
    !newWindow ||
    newWindow.closed ||
    typeof newWindow.closed == 'undefined'
  ) {
    alert(i18n.t('the authorization pop-up has been blocked by your browser'));
  }
}
