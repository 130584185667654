// @flow
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Form, FormGroup, Input, FormText} from 'reactstrap';

import {type USER_INTERFACE} from '../../../selectors/user';
import {updateEmail} from '../../../lib/firebase';
import RecentLogin from './recent-login';

const EmailForm = ({
  user,
  updateEmail,
}: {
  user: USER_INTERFACE,
  updateEmail: Function,
}) => {
  const [email, setEmail] = useState('');
  const [formState, setFormState] = useState({});
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const hasEmailProvider = user.providers.find(({id}) => id === 'password');

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    setFormState({});
    try {
      setLoading(true);
      await updateEmail(email);
      setFormState({valid: true, error: false});
      setEmail('');
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        setModal(true);
        return;
      }
      setFormState({error: error.message});
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <hr />
      <h5>Adresse email</h5>
      <div>
        <p className="text-muted">Modifier l'adresse email de connexion</p>

        {!hasEmailProvider && (
          <p className="text-muted">
            <em>Modification de l'adresse email non disponible</em>
          </p>
        )}
        {hasEmailProvider && (
          <Form inline className="py-2" onSubmit={(e) => handleSubmit(e)}>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Input
                type="email"
                id="newEmail"
                placeholder="nouvelle adresse email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={user.isUserForced || loading}
                invalid={Boolean(formState.error)}
                required
              />
            </FormGroup>
            <Button color="hubware" outline disabled={user.isUserForced}>
              {loading ? 'Chargement...' : 'Valider'}
            </Button>
            {formState.error && (
              <FormText className="ml-2" color="error">
                {formState.error}
              </FormText>
            )}
            {formState.valid && (
              <FormText className="ml-2" color="success">
                Modification enregistrée
              </FormText>
            )}
          </Form>
        )}
        <RecentLogin
          user={user}
          modal={modal}
          setModal={setModal}
          callback={() => handleSubmit()}
        />
      </div>
    </>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => ({...bindActionCreators({updateEmail}, dispatch)})
)(EmailForm);
