// @flow
import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {useQuery} from 'graphql-hooks';
import {reduce} from 'lodash';
import {User} from 'react-feather';
import {UncontrolledTooltip as Ut} from 'reactstrap';

import {EVENT_VALUES} from '../../../../lib/analytics';
import {formatTemplateData} from './utils';
import getDataHook from '../../../../lib/graphQL';
import Table from '../../../../components/table';
import {
  getAllTemplates,
  getMappedTemplatesByIntents,
  type TEMPLATE_INTERFACE,
} from '../../../../selectors/templates';

export const TemplateTable = ({
  allTemplates,
  dateRange,
  intent,
  templatesByIntents,
  usagesList,
}: {
  allTemplates: Array<Object>,
  dateRange: {startDate: Date, endDate: Date},
  intent: ?string,
  templatesByIntents: Object,
  usagesList: Array<string>,
}) => {
  const {data, loading} = useQuery(
    ...getDataHook('getMacroIdAgg', {date: dateRange})
  );
  const formatedValue = formatTemplateData(data, usagesList, intent);
  const mappedTmp = templatesByIntents?.[intent];
  const isMapped = formatedValue
    .map((v) => v.macroid)
    .filter((id) => mappedTmp?.map((t) => t.id).includes(id));

  return (
    <StyledTable>
      <Table
        isLoading={loading}
        data={prepareTableValues(formatedValue, allTemplates)}
        columns={useMemo(
          () => [
            {
              accessor: 'mappedTmp',
              Header: '',
              Cell: ({cell: {value}}: {cell: {value: string}}) =>
                isMapped.includes(value) ? (
                  <div />
                ) : (
                  <div>
                    <User size={10} id={`id-${value}`} />
                    <Ut target={`id-${value}`}>
                      Modèle de réponse sélectionné par un conseiller, hors
                      assistance
                    </Ut>
                  </div>
                ),
            },
            {
              accessor: 'macroid',
              Header: 'Templates',
              Cell: ({cell: {value}}: {cell: {value?: string}}) =>
                value === 'null' ? <em>Réponse libre</em> : value,
            },
            ...usagesList.map((accessor) => ({
              accessor,
              Header: EVENT_VALUES[accessor].shortTitle,
              Cell: ({cell: {value}}: {cell: {value?: string}}) =>
                value ? value : <span>0</span>,
            })),
            {
              Header: 'Total',
              accessor: 'total',
            },
          ],
          [isMapped, usagesList]
        )}
        sortBy={['macroid']}
        csvFilename="Templates"
      />
    </StyledTable>
  );
};

export default connect((state) => ({
  allTemplates: getAllTemplates(state),
  templatesByIntents: getMappedTemplatesByIntents(state),
}))(TemplateTable);

function prepareTableValues(
  formatedValues,
  allTemplates: Array<TEMPLATE_INTERFACE>
) {
  return formatedValues.map(({macroid, ...rest}) => ({
    mappedTmp: allTemplates.find(({id}) => id === macroid)?.id ?? macroid,
    macroid: allTemplates.find(({id}) => id === macroid)?.title ?? macroid,
    ...rest,
    total: reduce(rest, (sum, n) => sum + n),
  }));
}

const StyledTable = styled.div`
  th:last-child,
  td:last-child {
    text-align: right;
    font-weight: bold;
  }

  th:first-child {
    width: 2px;
  }

  margin-bottom: 2rem;
`;
