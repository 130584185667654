// @flow
import React from 'react';
import {ChevronRight, ChevronLeft} from 'react-feather';

const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageOptions,
  nextPage,
  previousPage,
  pageIndex,
}: {
  data: Array<Object>,
  canPreviousPage: Function,
  canNextPage: Function,
  pageOptions: Array<number>,
  nextPage: Function,
  previousPage: Function,
  pageIndex: Function,
}) => (
  <div className="d-flex align-items-center">
    <button
      className="btn btn-link mr-1"
      onClick={previousPage}
      disabled={!canPreviousPage}
    >
      <ChevronLeft size="15" />
    </button>
    <button
      className="btn btn-link mr-1"
      onClick={nextPage}
      disabled={!canNextPage}
    >
      <ChevronRight size="15" />
    </button>
    <div className="mt-1">
      {pageIndex + 1} sur {pageOptions.length}
    </div>
  </div>
);

export default Pagination;
