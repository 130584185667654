// @flow
import React, {useState, useEffect} from 'react';
import {Prompt} from 'react-router-dom';
import {connect} from 'react-redux';
import {get, findLast} from 'lodash';
import i18n from '../../../../i18n';

import {configureZendeskFromBoard} from '../../../../lib/callable-functions';

import Checks from './checks';

const ERROR_MSG = i18n.t(
  'An error has occured. Please refresh the page or contact us.'
);

const IN_PROGRESS_MSG = i18n.t(
  'Installation in progress, please do not exit or reload this window'
);

export const ZendeskInstallation = ({
  isActive,
  updateOnboarding,
  onboarding,
}: {
  isActive: boolean,
  updateOnboarding: (x?: string) => void,
  onboarding: Array<Object>,
}) => {
  const [status, setStatus] = useState({
    started: false,
    finished: false,
    checksEnded: false,
    error: undefined,
  });

  const domain = get(
    findLast(onboarding, {id: 'ZENDESK_DOMAIN_STEP'}),
    'value'
  );
  const code = get(findLast(onboarding, {id: 'OAUTH_SUCCESS'}), 'value');

  useEffect(() => {
    const triggerInstallation = async () => {
      setStatus((s) => ({...s, started: true}));
      try {
        await configureZendeskFromBoard(code, domain);
        updateOnboarding('VALIDATED');
        setStatus((s) => ({...s, finished: true}));
      } catch (e) {
        updateOnboarding('ERROR');
        setStatus((s) => ({
          ...s,
          error: ERROR_MSG,
        }));
      }
    };

    if (isActive && !status.started) {
      triggerInstallation();
    }
  }, [code, domain, isActive, status.started, updateOnboarding]);

  return (
    <>
      <Prompt
        when={!(status.checksEnded || status.finished)}
        message={IN_PROGRESS_MSG}
      />
      <Checks
        {...status}
        setEndChecks={() => setStatus((s) => ({...s, checksEnded: true}))}
      />
    </>
  );
};

export default connect(({firestore}) => ({
  onboarding: get(firestore, 'user.uiSettings.onboarding', []),
}))(ZendeskInstallation);
