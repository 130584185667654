// @flow
import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {ExternalLink} from 'react-feather';
import styled from 'styled-components';

import {Card} from '../../../../components/card';
import Table from '../../../../components/table';

export const IntentsTable = ({
  zendeskBaseUrl,
  loading,
  tableHeaders = [],
  tableData = [],
}: {
  zendeskBaseUrl?: string,
  loading: boolean,
  tableHeaders?: Array<Object>,
  tableData?: Array<Object>,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        Cell: ({cell: {value}}: {cell: {value: string}}) =>
          zendeskBaseUrl && value ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${zendeskBaseUrl}/agent/admin/macros/${value}`}
            >
              <ExternalLink size={14} />
            </a>
          ) : null,
      },
      ...tableHeaders,
    ],
    [tableHeaders, zendeskBaseUrl]
  );

  return (
    <Card className="mb-4">
      <div className="card-body">
        <h4>Utilisation des modèles de réponses par catégorie</h4>

        <StyledTable
          data={tableData}
          isLoading={loading}
          columns={columns}
          csvFilename="templates"
          sortBy={[`-${columns[2]?.accessor}`]}
        />
      </div>
    </Card>
  );
};

export default connect((state) => ({
  zendeskBaseUrl: state?.firestore?.setting?.zendesk?.baseUrl,
}))(IntentsTable);

const StyledTable = styled(Table)`
  th:first-child,
  td:first-child {
    text-align: left;
    font-weight: bold;
    width: 20px;
  }
`;
