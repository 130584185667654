// @flow
import {get} from 'lodash';
import {getJwt} from '../lib/firestore/jwt';
import {setClient, getClient} from '../lib/graphQL/client';
import queries from '../lib/graphQL/queries';
import {GASetSettingId} from '../lib/google-analytics';
import {setError} from './app';
import {setIntents} from './data';

import {FirestoreState} from '../reducer/types/firestore-types';

import {
  FirestoreActionsTypes,
  SET_JWT,
  SET_USER,
  SET_SETTING,
  SET_TEMPLATES,
  SET_BLOC_LOGIC,
  SET_OPERATORS_GROUPS,
  ADD_REALTIME_REF,
  RESET_REALTIME_REF,
} from './types/firestore-types';

export const setUser = ({
  id,
  ...rest
}: FirestoreState['user']): FirestoreActionsTypes => ({
  // We destruct here because id not in validation schema
  type: SET_USER,
  user: rest,
  id,
});

export const setJwt = (jwt: string) => ({
  type: SET_JWT,
  jwt,
});

export const setSetting = (setting: FirestoreState['setting']) => async (
  dispatch: Function,
  getState: Function
) => {
  const oldSettingId = get(getState(), 'firestore.setting.id');
  const newSettingID = setting.id;

  if (oldSettingId !== newSettingID) {
    // Set settingId in Google Anaytics
    GASetSettingId(newSettingID);
    const jwt = await dispatch(getJwt(newSettingID));

    if (!jwt) {
      await dispatch(
        setError(
          `Vous ne disposez pas de clef d'authentification valide, merci de contacter help@hubwa.re`
        )
      );
      return;
    }

    await Promise.all([dispatch(setClient(jwt)), dispatch(setJwt(jwt))]);

    // Fetch intents i18n
    const client = getClient();
    const response = await client.request(queries.getIntents);
    await dispatch(setIntents(response?.data?.intents ?? {}));
  }

  await dispatch({
    type: SET_SETTING,
    setting,
  });
};

export const setTemplates = (
  templates: FirestoreState['templates']
): FirestoreActionsTypes => ({
  type: SET_TEMPLATES,
  templates,
});

export const setBlocLogic = (
  blocLogics: FirestoreState['blocLogics']
): FirestoreActionsTypes => ({
  type: SET_BLOC_LOGIC,
  blocLogics,
});

export const setZendeskOperatorsGroups = (
  operators: FirestoreState['zendeskAuthors'],
  groups: FirestoreState['zendeskGroups']
): FirestoreActionsTypes => ({
  type: SET_OPERATORS_GROUPS,
  operators,
  groups,
});

export const addRealtimeRef = (ref: FirestoreState['realtimeRefs']) => ({
  type: ADD_REALTIME_REF,
  ref,
});

export const resetRealtimeRef = () => ({
  type: RESET_REALTIME_REF,
});
