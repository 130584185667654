// @flow
import React from 'react';
import {map, isUndefined} from 'lodash';
import {Nav, NavItem} from 'reactstrap';
import {v4 as uuidv4} from 'uuid';
import styled from 'styled-components';

import NavLink from './nav-link';

const TabNav = ({
  selectedTab,
  setTab,
  tabsMap,
  link,
}: {
  selectedTab?: string,
  setTab?: Function,
  tabsMap:
    | Object
    | Array<{
        key: string,
        title: string,
        value?: number,
        pathname?: string,
      }>,
  link?: Boolean,
}) => {
  if (setTab && selectedTab) {
    return (
      <StyledNav>
        {map(tabsMap, ({title, value, key}, mapKey: string) => (
          <NavItem key={uuidv4()} onClick={() => setTab(key || mapKey)}>
            <NavLink active={selectedTab === (key || mapKey)} className="clic">
              {title}
              {!isUndefined(value) && (
                <span className="badge badge-hubware ml-2">{value}</span>
              )}
            </NavLink>
          </NavItem>
        ))}
      </StyledNav>
    );
  } else {
    return (
      <StyledNav>
        {map(tabsMap, ({title, value, pathname = '/'}) => (
          <NavItem key={uuidv4()}>
            <NavLink pathname={pathname} className="clic">
              {title}
              {!isUndefined(value) && (
                <span className="badge badge-hubware ml-2">{value}</span>
              )}
            </NavLink>
          </NavItem>
        ))}
      </StyledNav>
    );
  }
};

export default TabNav;

const StyledNav = styled(Nav)`
  border-bottom: 1px solid #eee;
`;
