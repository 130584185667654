// @flow
import firebase from 'firebase/app';
import {get} from 'lodash';
import {DateTime} from 'luxon';

import {parseObjectWithDates} from './utils';

export const getJwt = (settingId: string) => async (dispatch: Function) => {
  const now = DateTime.local();
  const [doc] = await firebase
    .firestore()
    .collection('jwts')
    .where('settings', '==', settingId)
    .where('expires', '>', now.toJSDate())
    .where('revoke', '==', false)
    .orderBy('expires', 'desc')
    .limit(1)
    .get()
    .then(({docs}) => {
      return docs.map((doc) => parseObjectWithDates(doc.data()));
    });
  return get(doc, 'token');
};
