// @flow

const filterTypes = () => ({
  bool: (rows: any, id: string, filterValue: number) => {
    if (!filterValue) {
      return rows;
    }

    return rows.filter((row: Object): boolean => {
      const rowValue = row.values[id] === '-' ? false : row.values[id];
      return Boolean(rowValue);
    });
  },

  reverseIncludes: (rows: any, id: string, filterValue: any) => {
    return rows.filter((row: Object) => {
      const rowValue = row.values[id];
      return rowValue.includes(filterValue);
    });
  },
});

export default filterTypes;
