// @flow
import React from 'react';

const LoadingState = () => (
  <div className="d-flex align-items-center h-100 justify-content-center">
    <div className="text-muted loading">Chargement</div>
  </div>
);

export default LoadingState;
