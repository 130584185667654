// @flow
import React from 'react';
import {Link} from 'react-router-dom';
import {MoreHorizontal} from 'react-feather';
import AddButton from './add-button';

import filters from '../../../../components/table/filters/filters';

const columns = () => [
  {
    Header: 'Catégorie',
    accessor: ({title, key}: {title?: string, key: string}): string =>
      title || key,
    id: 'intent',
  },
  {
    Header: 'Opérateur assigné',
    accessor: (row: Object) => (
      <div className="text-decoration-none text-dark">
        {row.pair?.name ?? '-'}
      </div>
    ),
  },
  {
    Header: 'Automatiques',
    accessor: (row: Object) => row?.count.autoReplied ?? '-',
    filter: 'exist',
    Filter: filters.toggle,
    filterValue: 0,
  },

  {
    Header: 'Préparées',
    accessor: (row: Object) => row?.count.prepared ?? '-',
    filter: 'exist',
    Filter: filters.toggle,
    filterValue: 0,
  },
  {
    Header: 'Assistées',
    accessor: (row: Object) => row.count.assisted ?? '-',
    filter: 'exist',
    Filter: filters.toggle,
    filterValue: 0,
  },
  {
    Header: 'Détails',
    accessor: 'key',
    disableSorting: true,
    Cell: ({cell: {value}}: {cell: {value: string}}) => (
      <Link
        to={{
          pathname: `/config/intents/${value}`,
          search: window.location.search,
        }}
      >
        <MoreHorizontal color="grey" />
      </Link>
    ),
  },
];

const tab = {
  key: 'intents',
  title: 'Catégories',
  pathname: '/config/_intents',
};

const intentsUtils = {
  tab,
  columns,
  AddButton,
};

export default intentsUtils;
