// @flow
import ReactGA from 'react-ga';

export const GAInit = (history: Object) => {
  if (isNotLocalhost()) {
    ReactGA.initialize('UA-72978910-3');

    history.listen((location) => {
      ReactGA.set({page: location.pathname}); // Update the user's current page
      ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
    });
  }
};

export const GAinitialPageView = () => {
  if (isNotLocalhost()) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

export const GASetUserId = (userId: string, email: string, admin?: boolean) => {
  if (isNotLocalhost()) {
    ReactGA.set({
      userId: `${isHubware(email, admin) ? 'HUB-' : ''}${userId}`,
      dimension1: isHubware(email, admin).toString(), // isHubware
      dimension3: email.split('@')[1], // userEmailDomain
    });
  }
};

export const GASetSettingId = (settingId: string) => {
  if (isNotLocalhost()) {
    ReactGA.set({
      dimension2: settingId, // settingId
    });
  }
};

function isHubware(email: string, admin: boolean = false) {
  return admin || email.includes('@hubwa');
}

function isNotLocalhost() {
  return window.location.hostname !== 'localhost';
}
