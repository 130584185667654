// @flow
import React, {useMemo} from 'react';
import styled from 'styled-components';

import {get, reduce} from 'lodash';
import {PlusCircle} from 'react-feather';

import {EVENT_VALUES} from '../../../../lib/analytics';

import Table from '../../../../components/table';

const IntentTable = ({
  intent,
  intents,
  isLoading,
  filteredFormatedValues,
  formatedValues,
  usagesList,
  salesforceProvider,
  setIntent,
}: {
  intent: ?String,
  intents: Object,
  isLoading: boolean,
  filteredFormatedValues: Array<Object>,
  formatedValues: Array<Object>,
  usagesList: Array<Object>,
  salesforceProvider: boolean,
  setIntent: Function,
}) => (
  <StyledTable>
    <Table
      isLoading={isLoading}
      data={prepareTableValues(filteredFormatedValues, intents).map((r) => ({
        ...r,
        macroDetails: r.key,
      }))}
      columns={useMemo(
        () => [
          {
            accessor: 'macroDetails',
            Header: '',
            Cell: ({cell: {value}}: {cell: {value: string}}) =>
              salesforceProvider ? (
                <div />
              ) : (
                <PlusCircle
                  size={15}
                  className="clic"
                  onClick={() => setIntent(value)}
                />
              ),
          },
          {
            accessor: 'intent',
            Header: 'Catégories',
          },
          ...usagesList.map((accessor) => ({
            accessor,
            Header: EVENT_VALUES[accessor].shortTitle,
          })),
          {
            Header: 'Total',
            accessor: 'total',
          },
        ],
        [salesforceProvider, setIntent, usagesList]
      )}
      csvFilename="catégories"
      sortBy={['intent']}
    />
  </StyledTable>
);

export default IntentTable;
const StyledTable = styled.div`
  th:last-child,
  td:last-child {
    text-align: right;
    font-weight: bold;
  }

  th:first-child {
    width: 2px;
  }
`;

function prepareTableValues(formatedValues, intents) {
  return formatedValues.map(({intent, ...rest}) => ({
    intent: get(intents, [intent, 'title'], intent),
    key: get(intents, [intent, 'key'], intent),
    ...rest,
    total: reduce(rest, (sum, n) => sum + n),
  }));
}
