// @flow
import firebase from 'firebase/app';

import {setLoggedUser} from '../actions/app';
import {updateUserEmail} from './firestore/user';

export const getProjectId = () => firebase.app().options.projectId;

export const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();

  provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  provider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
  provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

  return firebase.auth().signInWithPopup(provider);
};

export const signInWithEmail = (email: string, password: string) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

export const signUpWithEmail = (email: string, password: string) =>
  firebase.auth().createUserWithEmailAndPassword(email, password);

export const signOut = () => firebase.auth().signOut();

export const sendPasswordResetEmail = (email: string) => {
  firebase.auth().languageCode = 'fr';
  return firebase.auth().sendPasswordResetEmail(email);
};

export const updateEmail = (email: string) => async (
  dispatch: Function,
  getState: Function
) => {
  const userId = getState().firestore.user.id;

  return firebase
    .auth()
    .currentUser.updateEmail(email)
    .then(function () {
      return updateUserEmail(userId, email).then(async () => {
        const loggedUser = firebase.auth().currentUser;
        await dispatch(setLoggedUser(loggedUser.toJSON()));
      });
    });
};

export const updatePassword = (password: string) =>
  firebase.auth().currentUser.updatePassword(password);
