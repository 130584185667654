// @flow
import React from 'react';
import {DateTime} from 'luxon';
import styled from 'styled-components';

const examples = [
  {
    title: 'Concaténer 2 éléments',
    jsonata: 'price & "€"',
    result: '13.37€',
  },
  {
    title: 'Concaténer plusieurs éléments',
    jsonata: 'firstname & " " & lastname',
    result: 'Jean Dupont',
  },
  {
    title: 'Date du jour',
    jsonata: '$moment().format("DD-MM-YYYY")',
    result: DateTime.local().toFormat('dd-MM-yyyy'),
  },
  {
    title: "Signature en fonction de l'heure",
    jsonata: `$moment().hour() > 17 ? 'Bonne fin de journée' : 'Bonne journée'`,
    result:
      DateTime.local().toFormat('HH') > 17
        ? 'Bonne fin de journée'
        : 'Bonne journée',
  },
  {
    title: 'Signature en fonction du jour de la semaine',
    jsonata: `$moment().isoWeekday() > 3 ?
    'Bonne fin de semaine' :
    'Bonne semaine'`,
    result:
      DateTime.local().toFormat('c') > 3
        ? 'Bonne fin de semaine'
        : 'Bonne semaine',
  },
];

const Examples = () => (
  <>
    <h5 className="mb-3 mt-4 pt-4">Exemple d'utilisation</h5>

    <StyledTable>
      <thead>
        <tr>
          <th>Description</th>
          <th>JSONata</th>
          <th>Résultat</th>
        </tr>
      </thead>
      <tbody>
        {examples.map(({title, jsonata, result}, key) => (
          <tr key={key}>
            <td>{title}</td>
            <td>
              <code>{jsonata}</code>
            </td>
            <td>{result}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
    <div id="links">
      <p className="small">
        Hubware utilise{' '}
        <a
          href="https://docs.jsonata.org/overview.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          JSONata
        </a>{' '}
        pour interpréter ces élements variables.
      </p>
      <p className="small">
        La librairie{' '}
        <a
          href="https://momentjs.com/docs/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Moment.js
        </a>{' '}
        est disponible pour toutes les manipulations de date.
      </p>
    </div>
  </>
);

export default Examples;

const StyledTable = styled.table.attrs({
  className: 'table mb-4 light-table',
})`
  font-size: 90%;

  th,
  td {
    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 40%;
    }
    &:nth-child(3) {
      text-align: right;
      width: 20%;
    }
  }
`;
