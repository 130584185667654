import React from 'react';
import styled from 'styled-components';
import {Row, Col} from 'reactstrap';

import Debugger from '../components/architecture/debugger';
import {Card} from '../components/card';

const Error = ({error}: {error: string}) => (
  <CenteredRow>
    <Col md="4">
      <Card>
        <div className="card-body">
          <h4 className="mb-3">Une erreur est survenue</h4>
          <p>{error}</p>
        </div>
      </Card>
    </Col>
    <Debugger />
  </CenteredRow>
);

export default Error;

const CenteredRow = styled(Row).attrs({
  className: 'justify-content-center align-items-center text-center m-0',
})`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
