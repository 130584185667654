import {DataState} from '../../reducer/types/data-types';

export const SET_EVAL_JOB = 'SET_EVAL_JOB';
export const SET_INTENTS = 'SET_INTENTS';
export const SET_PROVIDER = 'SET_PROVIDER';
export const SET_SYSTEMS_STATUS = 'SET_SYSTEMS_STATUS';

interface SetEvalJobs {
  type: typeof SET_EVAL_JOB;
  key?: string;
  evalJob: DataState['ai'];
}

interface SetProvider {
  type: typeof SET_PROVIDER;
  provider: DataState['provider'];
}

interface SetIntents {
  type: typeof SET_INTENTS;
  intents: DataState['intents'];
}

interface SetSystemsStatus {
  type: typeof SET_SYSTEMS_STATUS;
  systemsStatus: DataState['systemsStatus'];
}

export type DataActionsTypes =
  | SetEvalJobs
  | SetProvider
  | SetIntents
  | SetSystemsStatus;
