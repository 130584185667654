import {get, last} from 'lodash';

import features from '../views/user-menu/whats-new/features';
import {getSteps} from '../views/welcome/utils';

export const isUserFreemium = (
  setting: {
    bot?: string,
    boardConfiguration?: {freemiumSteps?: Array<Object>},
  } = {}
): boolean =>
  Boolean(
    setting.bot === 'freemium' ||
      get(setting, 'boardConfiguration.freemiumSteps.0')
  );

export const hasUserFinishedOnboarding = (user: Object = {}): boolean => {
  const onboarding = get(user, 'uiSettings.onboarding');

  if (!onboarding) {
    // never has to do onboarding (ex: legacy users)
    return true;
  }

  const steps = getSteps(onboarding);
  return get(last(steps), 'done', false);
};

export const getUserNotification = () =>
  !(Number(localStorage.getItem('featuresLen')) >= features.length);
// do not change > for < !. if value is nan, it won't work
