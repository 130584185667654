// @flow
import {countBy, keyBy, find, map} from 'lodash';
import {createSelector} from 'reselect';
import {getMappedTemplatesByIntents} from '../templates';
import intentsList from './intents-list.json';
import specialIntentsList from './special-intents-list.json';
import type {TEMPLATE_INTERFACE} from '../templates';

export type INTENT_INTERFACE = {
  key: string,
  title: string,
  description?: string,
  special?: boolean,
};

const allIntents = (state) => {
  const customIntents = state?.data?.intents ?? {};
  // Add intent key
  const intentsWithKey = map(
    customIntents,
    (intent: Object, intentKey: string) => ({
      ...intent,
      key: intentKey,
    })
  );
  const intents = intentsWithKey.length > 0 ? intentsWithKey : intentsList;

  return keyBy([...intents, ...specialIntentsList].map(fillIntents), 'key');
};

export const getAllIntents = createSelector([allIntents], (intents) => intents);

export const getIntentsByKeys = createSelector(
  [getAllIntents, (_state, keys) => keys],
  (intents, keys) =>
    keys.reduce((acc, key) => ({...acc, [key]: intents[key] || {key}}), {})
);

export const getIntentsListWithTemplates = createSelector(
  [getAllIntents, getMappedTemplatesByIntents],
  (
    intents: {[string]: INTENT_INTERFACE},
    tmps
  ): Array<{
    ...INTENT_INTERFACE,
    count: Object,
    templates: Array<TEMPLATE_INTERFACE>,
  }> =>
    Object.values(intents).map((intent: Object) => ({
      ...intent,
      count: countBy(tmps[intent.key], 'assistanceType'),
      templates: tmps[intent.key] || [],
    }))
);

function fillIntents(intent: INTENT_INTERFACE) {
  const {key, title, description} = intent;

  if (title && description) {
    return intent;
  }

  const storedIntent = find(intentsList, {key}) || {};

  return {
    ...intent,
    title: title ? title : storedIntent.title,
    description: description ? description : storedIntent.description,
  };
}
