import {
  SET_JWT,
  SET_USER,
  SET_SETTING,
  SET_TEMPLATES,
} from '../actions/types/firestore-types';
import {
  AppActionsTypes,
  RESET_STORE,
  SET_IS_ADMIN,
  SET_LOGGED_USER,
  SET_ERROR,
  REFRESH_PARAMS,
  SET_UI_STATE,
} from './types/app-types';
import {AppState} from '../reducer/types/app-types';
import {setProvider} from './data';

export const setLoggedUser = (
  user: AppState['loggedUser']
): AppActionsTypes => ({
  type: SET_LOGGED_USER,
  user,
});

export const setUiState = (
  uiState: AppState['uiState'],
  force?: AppState['force']
): AppActionsTypes => ({
  type: SET_UI_STATE,
  uiState,
  force,
});

export const resetStore = (stores?: AppState['stores']): AppActionsTypes => ({
  type: RESET_STORE,
  stores,
});

export const setAdmin = (isAdmin: AppState['isAdmin']): AppActionsTypes => ({
  type: SET_IS_ADMIN,
  isAdmin,
});

export const setError = (error: AppState['error']): AppActionsTypes => ({
  type: SET_ERROR,
  error,
});

export const refreshParams = (): AppActionsTypes => ({
  type: REFRESH_PARAMS,
});

export const setLoadOfflineData = () => async (dispatch: Function) => {
  await dispatch({
    type: SET_USER,
    user: require('../offline/user'),
    id: require('../offline/user').uid,
  });
  await dispatch({
    type: SET_LOGGED_USER,
    user: require('../offline/logged-user'),
  });
  await dispatch({
    type: SET_SETTING,
    setting: require('../offline/setting'),
  });
  await dispatch(setProvider('salesforce')); // cf. setting content
  await dispatch({
    type: SET_TEMPLATES,
    templates: require('../offline/demo-macros'),
  });
  await dispatch({
    type: SET_JWT,
    jwt: '__offline__fake__JWT__',
  });
};
