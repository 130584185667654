// @flow
import React, {useState} from 'react';
import {connect} from 'react-redux';

import {Card} from '../../../../components/card';
import MetadataDetails from './details';
import MetadataExamples from './examples';
import MetadataList from './list';
import MetadataModal from './modal';

export const Metadata = ({metaDataMapping}: {metaDataMapping: Object}) => {
  const [modal, setModal] = useState(false);
  const [metadata, setMetadata] = useState(undefined);

  const handleclick = (value) => {
    setMetadata(value);
    setModal(true);
  };

  return (
    <Card>
      <div className="card-body">
        <h4 className="card-title m-0">Éléments variables</h4>
        <hr />
        <MetadataDetails />
        <hr />
        <MetadataList
          handleclick={handleclick}
          metaDataMapping={metaDataMapping}
        />
        <MetadataExamples />
      </div>
      <MetadataModal
        modal={modal}
        setModal={setModal}
        metadata={metadata}
        metaDataMapping={metaDataMapping}
      />
    </Card>
  );
};

export default connect(({firestore}) => ({
  metaDataMapping: firestore.setting.metaDataMapping || {},
}))(Metadata);
