// @flow
import React from 'react';
import {useQuery} from 'graphql-hooks';
import {DateTime} from 'luxon';

import getDataHook from '../../../lib/graphQL';
import {startEndOfDay} from '../../analytics/utils';
import {Card, CardLoading} from '../../../components/card';

import NoAnsweredIntents from './no-answer-intents';

const UnanswerIntents = ({
  intents,
  dateRange,
}: {
  intents: Object,
  dateRange: Object,
}) => {
  const lastWeekEndDate = startEndOfDay({
    startDate: DateTime.local().minus({week: 1}).endOf('week').minus({days: 1}),
    endDate: DateTime.local().minus({week: 1}).endOf('week'),
  });

  const {data, loading} = useQuery(
    ...getDataHook('getNoAnswerMessagePredictions', {
      date: lastWeekEndDate,
    })
  );

  const predictions = data?.predictions ?? [];

  if (predictions.length > 0) {
    return (
      <NoAnsweredIntents
        loading={loading}
        predictions={predictions}
        intents={intents}
        dateRange={lastWeekEndDate}
      />
    );
  } else if (loading) {
    return <CardLoading />;
  } else
    return (
      <Card className="mx-auto">
        <div className="card-body text-center">
          <div className="card-title my-2">Aucune donnée</div>
        </div>
      </Card>
    );
};

export default UnanswerIntents;
