// @flow
import {getProjectId} from './firebase';

export const oauthConstants = (domain?: string = '') => {
  const isStaging = getProjectId().includes('staging');

  const client_id = isStaging
    ? 'hubware_artificial_intelligence_staging'
    : 'hubware_artificial_intelligence';
  const redirect_uri = `${window.location.protocol}//${
    window.location.host
  }/oauth${isStaging ? '?staging=true' : ''}`;
  const scope = 'read write';

  return {
    client_id,
    redirect_uri,
    scope,
    link: `https://${
      isStaging ? 'hubware' : domain
    }.zendesk.com/oauth/authorizations/new?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}`,
  };
};
