// @flow
import React from 'react';
import {Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';

import {Card} from '../../../../components/card';
import Trend from '../trend';
import {
  getTotalCount,
  getTotalRefusedAssistance,
  getFullAssistanceCoverage,
} from './utils';
import Legend from './legend';
import ProgressBar from './progress-bar';

const Progress = ({
  data,
  isLoading,
  historyData,
  historyRange,
}: {
  data: Object,
  isLoading: boolean,
  historyData?: Object,
  historyRange?: {startDate: Date, endDate: Date},
}) => {
  const totalCount = getTotalCount(data);
  const totalRefusedAssistance = getTotalRefusedAssistance(data);
  const fullAssistanceCoverage = getFullAssistanceCoverage(data, totalCount);
  const fullAssistanceCoverageRounded = Math.round(fullAssistanceCoverage);
  const countFullAssistanceCoverage = Math.round(
    (fullAssistanceCoverage * totalCount) / 100
  );

  const historyFullAssistanceCoverage = historyData
    ? getFullAssistanceCoverage(historyData, getTotalCount(historyData))
    : undefined;

  return (
    <Card>
      <div className="card-body">
        <Row>
          <Col>
            <h6>Réponses couvertes par l'assistant</h6>
            <div className="d-flex mb-4 align-items-end">
              <h4 className="mb-1">
                <strong>
                  {isLoading && <span className="text-muted">_</span>}
                  {!isLoading && fullAssistanceCoverageRounded + '%'}
                </strong>
              </h4>
              <small className="mb-1 ml-2">
                {countFullAssistanceCoverage} réponse
                {countFullAssistanceCoverage > 1 && 's'}
              </small>

              {historyData && historyRange && (
                <Trend
                  historyRange={historyRange}
                  value={fullAssistanceCoverageRounded}
                  historyVal={historyFullAssistanceCoverage}
                />
              )}
            </div>
            <ProgressBar
              data={data}
              isLoading={isLoading}
              totalCount={totalCount}
              totalRefusedAssistance={totalRefusedAssistance}
            />
            <Legend
              isLoading={isLoading}
              data={data}
              totalRefusedAssistance={totalRefusedAssistance}
            />
            <hr />
            <div className="text-right">
              <Link
                to={{
                  pathname: `/config/intents/list`,
                  search: window.location.search,
                }}
              >
                Accédez à la liste des catégories afin d'augmenter la couverture
                de l'assistant
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default Progress;
