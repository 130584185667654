import {get} from 'lodash';

import {FirestoreState} from './types/firestore-types';
import {RESET_STORE} from '../actions/types/app-types';
import {
  FirestoreActionsTypes,
  SET_JWT,
  SET_USER,
  SET_SETTING,
  SET_TEMPLATES,
  SET_BLOC_LOGIC,
  SET_OPERATORS_GROUPS,
  ADD_REALTIME_REF,
  RESET_REALTIME_REF,
} from '../actions/types/firestore-types';
import {parseObjectWithDates} from '../lib/firestore/utils';

const schemas = require('@hubwa-re/joi-firebase')(require('joi-browser'));

const initialState: FirestoreState = {
  jwt: undefined,
  setting: undefined,
  templates: undefined,
  blocLogics: [],
  user: undefined,
  realtimeRefs: [],
  zendeskAuthors: null,
  zendeskGroups: null,
};

export function firestoreReducer(
  state = initialState,
  action: FirestoreActionsTypes
): FirestoreState {
  switch (action.type) {
    case SET_JWT: {
      return {
        ...state,
        jwt: action.jwt,
      };
    }
    case SET_USER: {
      const result = schemas.user.validate(parseObjectWithDates(action.user));
      if (result.error) {
        console.error(result.error);
      }
      return {
        ...state,
        user: {...result.value, id: action.id},
      };
    }
    case SET_SETTING: {
      const result = schemas.settings.validate(
        parseObjectWithDates(action.setting)
      );

      if (result.error) {
        throw result.error;
      }
      return {
        ...state,
        setting: result.value,
      };
    }

    case SET_TEMPLATES: {
      // If salesforce, validate schema
      if (get(state, 'firestore.setting.salesforce')) {
        const result = schemas.preparedReply.validate(
          parseObjectWithDates(action.templates)
        );

        if (result.error) {
          throw result.error;
        }
        return {
          ...state,
          templates: result.value,
        };
      }

      return {
        ...state,
        templates: action.templates,
      };
    }

    case SET_BLOC_LOGIC: {
      return {
        ...state,
        blocLogics: action.blocLogics,
      };
    }

    case SET_OPERATORS_GROUPS: {
      return {
        ...state,
        zendeskAuthors: action.operators,
        zendeskGroups: action.groups,
      };
    }

    case ADD_REALTIME_REF: {
      return {
        ...state,
        realtimeRefs: [...state.realtimeRefs, action.ref],
      };
    }
    case RESET_REALTIME_REF: {
      return {
        ...state,
        realtimeRefs: [],
      };
    }
    default:
      return state;
  }
}

const rootReducer = (state = initialState, action): FirestoreState => {
  if (action.type === RESET_STORE) {
    if (!action.stores || action.stores.includes('firestore')) {
      state = undefined;
    }
  }
  return firestoreReducer(state, action);
};

export default rootReducer;
