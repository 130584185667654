// @flow
import {get, isObject} from 'lodash';

export const getCodeFromActions = (actions: Object | Array<Object>): string => {
  if (Array.isArray(actions)) {
    // Zendesk
    const contentAction = get(
      actions.find(({field}) =>
        ['comment_value', 'comment_value_html'].includes(field)
      ),
      'value'
    );

    if (Array.isArray(contentAction)) {
      return contentAction[contentAction.length - 1];
    } else {
      return contentAction;
    }
  } else {
    if (isObject(actions)) {
      // Salesforce
      return actions?.email.body ?? '';
    }
  }
  return 'Une erreur est survenue';
};
