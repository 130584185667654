// @flow
import React from 'react';
import {Button, ButtonGroup} from 'reactstrap';
import styled from 'styled-components';

const ButtonsGroup = ({
  view,
  setView,
  views,
}: {
  view: string,
  setView: Function,
  views: Array<{key: string, title: string}>,
}) => (
  <StyledButtonGroup>
    {views.map(({key, title}) => (
      <Button
        key={key}
        outline={view !== key}
        onClick={() => setView(key)}
        color="hubware"
      >
        {title}
      </Button>
    ))}
  </StyledButtonGroup>
);

const StyledButtonGroup = styled(ButtonGroup).attrs({
  className: 'mx-auto',
})`
  margin-bottom: 30px;
`;

export default ButtonsGroup;
