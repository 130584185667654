// @flow
import React from 'react';
import {ChevronUp, ChevronDown} from 'react-feather';
import {v4 as uuidv4} from 'uuid';

const TableHeader = ({headerGroups}: {headerGroups: Array<Object>}) => (
  <thead>
    {headerGroups.map((headerGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()} key={uuidv4()}>
        {headerGroup.headers.map((column) => (
          <th
            key={uuidv4()}
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            {column.render('Header')}
            <span>
              {column.isSorted &&
                (column.isSortedDesc ? (
                  <ChevronDown className="chevrondown" size="15" />
                ) : (
                  <ChevronUp className="chevronup" size="15" />
                ))}
            </span>
          </th>
        ))}
      </tr>
    ))}
  </thead>
);

export default TableHeader;
