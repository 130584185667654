import React from 'react';
import {useStore} from 'react-redux';
import {useTranslation} from 'react-i18next';

import styled from 'styled-components';
import {MoreHorizontal} from 'react-feather';

import {RootState} from '../../../../reducer/index';
import Table from '../../../../components/table';
import Filters from '../../../../components/table/filters/filters';

const CustomLogicList = ({
  openModal,
  getBlocUsage,
}: {
  openModal: Function;
  getBlocUsage: Function;
}) => {
  const {t} = useTranslation();

  const store = useStore<RootState>();
  const customLogicList = store.getState().firestore.blocLogics;

  return (
    <>
      {!!customLogicList.length && (
        <StyledTableWrapper>
          <Table
            pageSize={8}
            columns={[
              {
                Header: t('Description'),
                accessor: 'description',
                Filter: Filters.input,
              },
              {
                Header: t('Usage'),
                accessor: '',
                Cell: ({row: {original}}: {row: {original: Object}}) => (
                  <div>{getBlocUsage(original).length}</div>
                ),
              },
              {
                Header: t('Edit'),
                accessor: 'id',
                disableSorting: true,
                Cell: ({row: {original}}: {row: {original: Object}}) => (
                  <span>
                    <MoreHorizontal
                      onClick={() => openModal(original)}
                      color="grey"
                    />
                  </span>
                ),
              },
            ]}
            data={customLogicList}
            sortBy={['description']}
          />
        </StyledTableWrapper>
      )}

      {!customLogicList.length && (
        <h6 className="text-muted mt-2">
          {t('No custom block has been created yet')}
        </h6>
      )}
    </>
  );
};

export default CustomLogicList;

const StyledTableWrapper = styled.div.attrs({
  className: 'mt-4',
})`
  table {
    th:first-child,
    td:first-child {
      text-align: left !important;
      width: 300px;
    }
    th:not(:last-child),
    td:not(:last-child) {
      text-align: center;
    }
    th:last-child,
    td:last-child {
      text-align: center;
      width: 80px;
    }
    svg:hover {
      cursor: pointer;
      transform: scale(1.25);
    }
  }
`;
