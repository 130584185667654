// @flow
import React, {useState, useEffect} from 'react';
import {Button} from 'reactstrap';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {useTranslation} from 'react-i18next';

import {Calendly} from '../../../lib/calendly';

export const CalendlyStep = ({
  isActive,
  updateOnboarding,
  user,
}: {
  updateOnboarding: (x?: string) => void,
  isActive: boolean,
  user: Object,
}) => {
  const [, setCEvents] = useState([]);
  const {t} = useTranslation();
  useEffect(() => {
    if (isActive) {
      Calendly();

      window.addEventListener('message', function (e) {
        function isCalendlyEvent(e) {
          return e.data.event && e.data.event.indexOf('calendly') === 0;
        }
        if (isCalendlyEvent(e)) {
          const event = e.data.event;
          setCEvents((events) => {
            if (!events.includes(event)) {
              updateOnboarding(event);
              return [...events, event];
            } else {
              return events;
            }
          });
        }
      });
    }
  }, [isActive, updateOnboarding]);

  const name = get(user, 'uiSettings.displayName', '');
  const email = get(user, 'uiSettings.email', '');

  return (
    <>
      <div>
        <Button
          color="link"
          onClick={() => updateOnboarding('calendly.event_scheduled')}
        >
          {t("I don't want to schedule an appointment")}
        </Button>
      </div>
      <div
        className="calendly-inline-widget"
        data-url={`https://calendly.com/hubware/30min?name=${name}&email=${email}&hide_event_type_details=0`}
        style={{minWidth: '320px', height: '600px'}}
      />
    </>
  );
};

export default connect(({firestore}) => ({
  user: firestore.user,
}))(CalendlyStep);
