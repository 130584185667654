// @flow
import React, {useState} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {User, LogOut, Activity, Settings, Shield} from 'react-feather';
import {NavLink} from 'react-router-dom';
import {Nav, Dropdown, DropdownToggle, DropdownMenu} from 'reactstrap';
import {getUser, type USER_INTERFACE} from '../../../../selectors/user';
import UserNotification from './user-notification';

export const UserDropdown = ({
  user,
  userNotification,
}: {
  user: USER_INTERFACE,
  userNotification: boolean,
}) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  if (!user) {
    return null;
  }

  return (
    <Nav className="ml-auto" navbar width="66px">
      <Dropdown nav inNavbar direction="down" isOpen={open} toggle={toggle}>
        <DropdownToggle nav caret>
          <User width={15} className="align-bottom" />
          <UserNotification />
        </DropdownToggle>
        <StyledDropdownMenu right className="position-absolute">
          <div className="my-3">
            <small>
              <strong style={{color: 'var(--dark)'}}>Connecté avec</strong>
            </small>
            <div>{user.email}</div>
          </div>

          <StyledNavLink
            onClick={toggle}
            to={{pathname: '/user/profile', search: window.location.search}}
          >
            <User width={11} className="align-bottom mr-3" />
            Compte
          </StyledNavLink>

          <StyledNavLink
            onClick={toggle}
            to={{
              pathname: '/user/services-status',
              search: window.location.search,
            }}
          >
            <Activity width={11} className="align-bottom mr-3" />
            Statut & maintenance
          </StyledNavLink>

          <StyledNavLink
            onClick={toggle}
            to={{
              pathname: '/user/settings/auto-reply',
              search: window.location.search,
            }}
          >
            <Settings width={11} className="align-bottom mr-3" />
            Paramètres
          </StyledNavLink>

          <StyledNavLink
            onClick={toggle}
            to={{
              pathname: '/user/settings/privacy',
              search: window.location.search,
            }}
          >
            <Shield width={11} className="align-bottom mr-3" />
            Protection des données
          </StyledNavLink>

          <StyledNavLink
            onClick={toggle}
            to={{pathname: '/whats-new', search: window.location.search}}
          >
            <UserNotification inList />
            Nouveautés
          </StyledNavLink>

          <StyledNavLink
            onClick={toggle}
            to={{pathname: '/sign-out', search: window.location.search}}
          >
            <LogOut width={11} className="align-bottom mr-3" />
            Se déconnecter
          </StyledNavLink>
        </StyledDropdownMenu>
      </Dropdown>
    </Nav>
  );
};

export default connect((state) => ({
  user: getUser(state),
  userNotification: state.app.uiState.userNotification,
}))(UserDropdown);

const StyledDropdownMenu = styled(DropdownMenu)`
  border-top-width: 0;
  border-radius: 0;
  margin-top: 15px;
  min-width: 250px;
  padding: 0;

  > div,
  a {
    border-top: 1px solid var(--gray-300);
    color: var(--gray);
    padding: 9px 30px;

    &:first-child {
      border-top-width: 0;
    }
  }
`;

const StyledNavLink = styled(NavLink).attrs({
  className: 'unstyled d-flex align-items-center',
  activeClassName: 'active',
})`
  &.active {
    border-left: 1px solid var(--hubware);
  }
`;
