import firebase from 'firebase/app';
import {set} from 'lodash';

import {SET_SETTING} from '../../actions/types/firestore-types';

const schemas = require('@hubwa-re/joi-firebase')(require('joi-browser'));

export const updateSetting = (setting) => async (dispatch, getState) => {
  const validatedSettings = await schemas.settings.validate(setting);

  // handle offline setting update
  if (getState().app.offline) {
    return dispatch({
      type: SET_SETTING,
      setting,
    });
  }

  return firebase
    .firestore()
    .collection('settings')
    .doc(getState().firestore.setting.id)
    .update(validatedSettings);
};

export const updateSettingAtPath = (path: string, value: any) => (
  dispatch,
  getState
) => {
  const setting = getState().firestore.setting;

  set(setting, path, value);
  return dispatch(updateSetting(setting));
};
