// @flow
import {useEffect, useState} from 'react';
import {useDispatch, useStore} from 'react-redux';
import firebase from 'firebase/app';
import {get, set, omit} from 'lodash';

import {setZendeskOperatorsGroups} from '../../actions/firestore';
import {updateSettingAtPath} from './setting';

const fetchSubCollection = (collectionName: string) => async (
  dispatch: Function,
  getState: Function
): Promise<Array<Object>> =>
  firebase
    .firestore()
    .collection('settings')
    .doc(getState().firestore.setting.id)
    .collection(collectionName)
    .get()
    .then((snap) => {
      const res = [];
      snap.forEach((doc) => res.push(doc.data()));
      return res;
    });

export const setOperatorsAndGroups = () => async (
  dispatch: Function,
  getState: Function
): Promise<void> => {
  const [operators, groups] = await Promise.all([
    dispatch(fetchSubCollection('zendeskAuthors')),
    dispatch(fetchSubCollection('zendeskGroups')),
  ]);

  await dispatch(setZendeskOperatorsGroups(operators, groups));
};

export const addOperatorIntentPair = (
  intentKey: string,
  id: number,
  name: string,
  type: string
) => async (dispatch: Function, getState: Function) => {
  const operatorsIntentPairs = get(
    getState(),
    'firestore.setting.operatorsIntentPairs',
    {}
  );
  const newPair = set(operatorsIntentPairs, [intentKey], {
    id: id.toString(),
    name: name,
    type: type,
  });

  dispatch(updateSettingAtPath('operatorsIntentPairs', newPair));
};

export const deleteOperatorIntentPair = (intentKey: string) => async (
  dispatch: Function,
  getState: Function
) =>
  dispatch(
    updateSettingAtPath(
      'operatorsIntentPairs',
      omit(
        get(getState(), 'firestore.setting.operatorsIntentPairs', {}),
        intentKey
      )
    )
  );

export const useAssignees = () => {
  const [loading, setLoading] = useState(false);
  const store = useStore();
  const dispatch = useDispatch();

  const {zendeskGroups, zendeskAuthors} = store.getState()?.firestore ?? {};

  useEffect(() => {
    if (zendeskAuthors === null || zendeskGroups === null) {
      setLoading(true);
      const init = async () => {
        await dispatch(setOperatorsAndGroups());
      };
      setLoading(false);

      init();
    }
  }, [zendeskAuthors, zendeskGroups, dispatch]);

  return {
    assignees: {groups: zendeskGroups || [], operators: zendeskAuthors || []},
    loading,
  };
};
