// @flow
import React, {useState} from 'react';
import {useQuery} from 'graphql-hooks';
import {Row, Col} from 'reactstrap';

import {Card, CardError} from '../../../../components/card';
import {
  EVENT_VALUES,
  ASSISTANCE_USAGES,
  REFUSED_ASSISTANCE_USAGES,
} from '../../../../lib/analytics';
import getDataHook from '../../../../lib/graphQL';
import {formatValues, findOperator, getTop, getTopRatio} from './utils';
import Table from '../../../../components/table';
import TopList from '../../components/top-list';

const AnalyticsOperators = ({
  dateRange,
  diffDateRange,
}: {
  dateRange: Object,
  diffDateRange: Object,
}) => {
  const {loading, error, data} = useQuery(
    ...getDataHook('getOperators', {date: dateRange})
  );
  const [slice, setSlice] = useState(5);

  if (error) {
    return <CardError />;
  }

  const columnsUsage = [
    'AUTO_REPLY_NOT_USED',
    'MACRO_AUTO_USED',
    'MACRO_AUTO_NOT_USED',
    'SUGGESTION_USED',
    'SUGGESTION_NOT_USED',
  ];

  const formatedValues = formatValues(data, columnsUsage);
  const topAsssistance = getTop(ASSISTANCE_USAGES, formatedValues);
  const topNonAssistance = getTop(REFUSED_ASSISTANCE_USAGES, formatedValues);
  const topRatio = getTopRatio(topAsssistance, topNonAssistance);

  return (
    <>
      <Row>
        <Col md="4">
          <TopList
            slice={slice}
            values={topRatio}
            setSlice={setSlice}
            isLoading={loading}
            title="Meilleur ratio d'assistance"
            subTitle="Ratios entre assistance utilisée et rejetée"
            unit="%"
          />
        </Col>
        <Col md="4">
          <TopList
            slice={slice}
            values={topAsssistance}
            setSlice={setSlice}
            isLoading={loading}
            title="Top assistance"
            subTitle="Réponses utilisant l'assistance"
          />
        </Col>
        <Col md="4">
          <TopList
            slice={slice}
            values={topNonAssistance}
            setSlice={setSlice}
            isLoading={loading}
            title="Top rejet assistance"
            subTitle="Réponses refusant l'assistance"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-4">
            <div className="card-body">
              <h4>Assistance par conseiller</h4>
              <Table
                data={formatedValues}
                isLoading={loading}
                columns={[
                  {accessor: 'operator', Header: 'Conseillers'},
                  ...columnsUsage.map((accessor) => ({
                    accessor,
                    Header: EVENT_VALUES[accessor].shortTitle,
                  })),
                ]}
                find={findOperator}
                csvFilename="conseillers"
                sortBy={['operator']}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AnalyticsOperators;
