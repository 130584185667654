import {
  splitScript,
  stringifyBlocs,
  Filter,
} from '../../../configuration/templates/details/logic/utils';
import {Bloclogic} from '../../../../reducer/types/firestore-types';

export const getCustomedMacros = (
  macroFilters: Filter[] = [],
  {type}: Bloclogic
): number[] =>
  macroFilters
    .filter((macro) => splitScript(macro.script).find((b) => b.type === type))
    .map((f) => f.id);

export const updateMacroFilter = (
  macroFilter: Filter,
  {type, description, code}: Bloclogic
): Filter => {
  const splittedScript = splitScript(macroFilter.script);
  const updatedBlocs = splittedScript.map((b) =>
    b.type === type ? {...b, description: description, code: code} : b
  );
  return {...macroFilter, script: stringifyBlocs(updatedBlocs)};
};

export const updateMacroFilters = (
  macroFilters: Filter[] = [],
  customBloc: Bloclogic
): Filter[] => {
  const macroIds = getCustomedMacros(macroFilters, customBloc);
  return macroFilters.map((m) =>
    macroIds.includes(m.id) ? updateMacroFilter(m, customBloc) : m
  );
};
