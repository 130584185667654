// @flow
import React from 'react';
import {v4 as uuidv4} from 'uuid';
import {Progress} from 'reactstrap';
import styled from 'styled-components';

import {Card} from '../../../components/card';
import LoadingState from './loading-state';

const TopList = ({
  title,
  slice,
  values,
  subTitle,
  setSlice,
  unit,
  isLoading,
}: {
  title: string,
  slice: number,
  values: Array<{key: string, value: number}>,
  subTitle?: string,
  setSlice: Function,
  unit?: string,
  isLoading: boolean,
}) => {
  let child;
  if (isLoading) {
    child = <LoadingState />;
  } else if (values.length === 0) {
    child = <div className="text-muted">Aucune donnée</div>;
  } else {
    child = (
      <>
        <div>
          {values.slice(0, slice).map(({key, value}) => (
            <StyledListItem key={uuidv4()}>
              <div className="d-flex align justify-content-between">
                <div className="key">{key}</div>
                <div>
                  {value}
                  {unit && <small>{unit}</small>}
                </div>
              </div>
              <StyledProgress value={(value / values[0].value) * 100} />
            </StyledListItem>
          ))}
        </div>
        {slice < values.length && (
          <div
            className="py-2 text-muted text-center clic"
            onClick={() => setSlice(slice * 2)}
          >
            Plus de résultats
          </div>
        )}
      </>
    );
  }

  return (
    <Card className="mb-4">
      <div className="card-body">
        <h4>{title}</h4>
        <h6 className="card-subtitle mb-3 text-muted">{subTitle}</h6>
        {child}
      </div>
    </Card>
  );
};

export default TopList;

const StyledListItem = styled.div.attrs({
  className: 'py-2',
})`
  :first-child {
    font-size: 1.2rem;
  }
  &:not(:first-child) {
    opacity: 0.65;
  }

  .key {
    margin-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledProgress = styled(Progress).attrs({
  className: 'my-2',
})`
  background: transparent;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 5px;

  .progress-bar {
    background-color: hsl(214, 69%, 60%);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
