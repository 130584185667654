// @flow
import React, {useState} from 'react';
import {Row, Col} from 'reactstrap';
import styled from 'styled-components';
import {DateTime} from 'luxon';

import TopList from '../../analytics/components/top-list';
import Table from '../../../components/table';
import {Card} from '../../../components/card';
import {formatTableData, getPredictionRatioPercent} from './utils';

const NoAnsweredIntents = ({
  intents,
  loading,
  predictions,
  dateRange,
}: {
  intents: Object,
  loading: boolean,
  predictions: Object,
  dateRange: Object,
}) => {
  const [slice, setSlice] = useState(5);
  const topListData = predictions.filter((i) => i.key !== 'no_intent');
  const total = predictions.reduce((acc, v) => acc + v.value, 0);

  return (
    <>
      <Row>
        <Col>
          <TopList
            slice={slice}
            values={getPredictionRatioPercent(topListData, intents)}
            setSlice={setSlice}
            isLoading={loading}
            title="Top des catégories détectées des messages non-répondus du dernier weekend"
            subTitle={`Catégories détectées des messages créés du samedi ${dateRange.startDate.toLocaleString(
              DateTime.DATE_MED
            )} au dimanche
            ${dateRange.endDate.toLocaleString(
              DateTime.DATE_MED
            )} et non-répondus par les conseillers`}
            unit="%"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-4">
            <div className="card-body">
              <h4>
                Catégories détectées des messages non-répondus du dernier
                weekend
              </h4>
              <h6 className="card-subtitle mb-3 text-muted">
                Pour les messages créés du samedi{' '}
                {dateRange.startDate.toLocaleString(DateTime.DATE_MED)} au
                dimanche {dateRange.endDate.toLocaleString(DateTime.DATE_MED)}{' '}
                et non-répondus par les conseillers
              </h6>
            </div>
            <StyledTableWrapper>
              <Table
                isLoading={loading}
                data={formatTableData(topListData, intents)}
                columns={[
                  {
                    Header: 'Catégorie détectée',
                    accessor: 'key',
                  },
                  {
                    Header: 'Nombre de messages',
                    accessor: 'value',
                  },
                  {
                    Header: 'Pourcentage',
                    accessor: 'percent',
                  },
                ]}
                csvFilename="catégories"
              />
            </StyledTableWrapper>
          </Card>
          <Card className="mb-4">
            <div className="card-body d-flex">
              <div className="d-flex align-items-center text-muted">
                Nombre total de messages non-répondus : {total} (dont{' '}
                {predictions.map((p) => p.key === 'no_intent' && p.value)} sans
                catégories détectées).
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default NoAnsweredIntents;
const StyledTableWrapper = styled.div`
  table {
    th:first-child,
    td:first-child {
      width: 500px;
    }
  }
`;
