// @flow
import firebase from 'firebase/app';
import queryString from 'query-string';
import {get} from 'lodash';

import {setProvider} from '../../actions/data';
import {setLoggedUser, resetStore, setError, setAdmin} from '../../actions/app';
import {
  setUser,
  setSetting,
  setTemplates,
  setBlocLogic,
} from '../../actions/firestore';
import {GASetUserId} from '../google-analytics';
import {getProjectId} from '../firebase';
import {hasUserFinishedOnboarding} from '../user';
import {getUser} from '../firestore/user';
import {disconnectRealtimeRefs, onSnapshot} from '../firestore';

export const handleDisconnected = () => (
  dispatch: Function,
  getState: Function
) => {
  const previousLoggedUser = getState().app.loggedUser;
  if (previousLoggedUser) {
    // Previously set user
    getState().firestore.realtimeRefs.map((f) => f());
    dispatch(resetStore());
  }
  dispatch(setLoggedUser({}));
};

export const handleUser = (loggedUser: {email: string, uid: string}) => async (
  dispatch: Function,
  getState: Function
) => {
  let loggedUserIsAdmin;
  dispatch(setLoggedUser(loggedUser));

  const isUIDForced: boolean = Boolean(
    queryString.parse(window.location.search).uid
  );
  const uid = isUIDForced
    ? queryString.parse(window.location.search).uid
    : loggedUser.uid;

  if (isUIDForced) {
    // Disconnect potential previously firestore data connections
    loggedUserIsAdmin = get(await dispatch(getUser(loggedUser.uid)), 'admin');
    dispatch(disconnectRealtimeRefs());
  }

  // set the user (from which the data will be collected)
  const user: {
    admin: boolean,
    created: Date,
    uiSettings?: {email: string},
  } = await dispatch(getUser(uid));

  if (!user) {
    return await dispatch(
      setError(`No user ${uid} found on project ${getProjectId()}`)
    );
  }
  const db = firebase.firestore().collection('users').doc(uid);
  await dispatch(onSnapshot(db, setUser));

  dispatch(setUser({...user, id: uid}));
  dispatch(setAdmin(Boolean(loggedUserIsAdmin || user.admin)));

  // Set user Id and isHubware dimension in Google Anaytics
  GASetUserId(uid, get(user, 'uiSettings.email', ''), user.admin);
};

export const getDBConnection = () => async (
  dispatch: Function,
  getState: Function
) => {
  const user = get(getState(), 'firestore.user', {});

  const selectedSetting = get(user, 'uiSettings.selectedSetting');
  if (selectedSetting) {
    return firebase.firestore().collection('settings').doc(selectedSetting);
  }

  if (!selectedSetting) {
    if (hasUserFinishedOnboarding(user)) {
      dispatch(
        setError(
          `No selected setting for the account ${
            user.id || 'ERROR'
          } on project ${getProjectId()}`
        )
      );
      return;
    }
    return;
  }
};

export const handleProvider = (db: Object) => async (
  dispatch: Function,
  getState: Function
) => {
  const setting = (await db.get()).data(); // Fetch setting

  if (setting.salesforce) {
    return await dispatch(setProvider('salesforce'));
  }
  if (setting.zendesk) {
    return await dispatch(setProvider('zendesk'));
  }

  return await dispatch(setProvider('unknown'));
};

export const handleFirestoreConnection = (db: Object) => async (
  dispatch: Function,
  getState: Function
) => {
  //Connect to setting's data
  dispatch(onSnapshot(db, setSetting));
  // connect to subcollections
  if (getState().data.provider === 'salesforce') {
    dispatch(onSnapshot(db.collection('preparedReplies'), setTemplates));
  } else if (getState().data.provider === 'zendesk') {
    dispatch(onSnapshot(db.collection('zendeskMacros'), setTemplates));
  } else {
    dispatch(setTemplates([]));
  }
  return dispatch(onSnapshot(db.collection('customLogicBloc'), setBlocLogic));
};
