import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'reactstrap';
import {renderRoutes, matchRoutes, RouteConfig} from 'react-router-config';
import {Redirect} from 'react-router-dom';

import {RootState} from '../../reducer';
import SideNav from '../../components/sidenav';

const UserMenu = ({
  route,
  provider,
}: {
  route: RouteConfig;
  provider: string;
}) => {
  const nav = useMemo(
    () => [
      {
        title: 'Général',
        links: [
          {
            pathname: '/user/profile',
            title: 'Compte',
          },
          {
            pathname: '/user/services-status',
            title: 'Statut & Maintenance',
          },
        ],
      },
      {
        title: 'Paramètres',
        links: [
          {
            pathname: '/user/settings/auto-reply',
            title: 'Réponses automatiques',
          },
          {
            pathname: '/user/settings/metadata',
            title: 'Éléments variables',
          },
          {
            pathname: '/user/settings/logic',
            title: 'Logiques personnalisées',
          },
          {
            pathname: '/user/settings/privacy',
            title: 'Protection des données',
          },
          {
            pathname: '/user/settings/provider',
            title: provider === 'zendesk' ? 'Zendesk' : 'Salesforce',
          },
        ],
      },
    ],
    [provider]
  );

  if (matchRoutes(route.routes, window.location.pathname).length === 0) {
    return (
      <Redirect
        to={{
          pathname: '/user/profile',
          search: window.location.search,
        }}
      />
    );
  }

  return (
    <div className="container">
      <Row>
        <Col md="3">
          <SideNav nav={nav} />
        </Col>
        <Col md="9">
          {renderRoutes(route.routes, {
            provider,
          })}
        </Col>
      </Row>
    </div>
  );
};

export default connect((state: RootState) => ({
  provider: state.data.provider,
}))(UserMenu);
