// @flow
import React from 'react';
import {Button} from 'reactstrap';
import {Download} from 'react-feather';
import {mapValues} from 'lodash';

import {downloadFile} from '../../lib/utils';

const CsvButton = ({
  columns,
  values,
  filename,
}: {
  columns: Array<{accessor: string, Header: string}>,
  values: Array<Object>,
  filename: string,
}) => {
  const stringifyValues = values.map((row) =>
    mapValues(row, (value) =>
      typeof value !== 'number' ? JSON.stringify(value) : value
    )
  );
  return (
    <Button
      color="link"
      className="d-flex align-items-center"
      onClick={() =>
        downloadFile(convertToCSV(stringifyValues, columns), filename, 'csv')
      }
    >
      CSV
      <Download size="15" className="ml-2" />
    </Button>
  );
};

export default CsvButton;

function convertToCSV(
  array: Array<Object>,
  columns: Array<{accessor: string, Header: string}>,
  delimiter: string = ','
): string {
  const colNames = columns.map((column) => column.Header);
  const rows = array.map((row) =>
    columns.map(({accessor}) => row[accessor]).join(delimiter)
  );
  rows.unshift(colNames.join(delimiter));
  return rows.join('\r\n');
}
