// @flow
import React from 'react';
import {v4 as uuidv4} from 'uuid';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import styled from 'styled-components';

const Dropdown = ({
  intents,
  intent,
  setIntent,
}: {
  intents: Array<{key: string, title: ?string}>,
  intent: ?string,
  setIntent: Function,
}) => (
  <StyledDropdown>
    {intent ? (
      <DropdownToggle caret color="hubware">
        {intents.find(({key}) => key === intent)?.title ?? intent}
      </DropdownToggle>
    ) : (
      <DropdownToggle caret color="hubware">
        Toutes les catégories
      </DropdownToggle>
    )}

    <DropdownMenu>
      <DropdownItem active={!intent} onClick={() => setIntent(undefined)}>
        Toutes les catégories
      </DropdownItem>
      <DropdownItem divider />
      {intents.map(({key, title}) => (
        <DropdownItem
          key={uuidv4()}
          active={intent === key}
          onClick={() => setIntent(key)}
        >
          {title || key}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </StyledDropdown>
);

export default Dropdown;

const StyledDropdown = styled(UncontrolledDropdown).attrs({
  className: 'mx-auto',
})`
  margin-bottom: 30px;

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: var(--hubware);
  }
`;
