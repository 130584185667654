import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
} from 'reactstrap';

import CodeEditor from '../../../configuration/components/code-editor';
import FormFooter from './form-footer';
import {Bloclogic} from '../../../../reducer/types/firestore-types';

const EditLogicBloc = ({
  editLogic,
  setEditLogic,
  handleDelete,
  getBlocUsage,
}: {
  editLogic: Bloclogic;
  setEditLogic: Function;
  handleDelete: any;
  getBlocUsage: Function;
}) => {
  const {t} = useTranslation();

  const [, setCodeFlaskRef] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!/^[0-9A-Za-z_-]*$/.test(editLogic.type)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [editLogic]);

  return (
    <div>
      <Form>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="description">{t('Description')}</Label>
              <StyledInput
                type="text"
                name="description"
                id="description"
                value={editLogic.description}
                onChange={(e) =>
                  setEditLogic({...editLogic, description: e.target.value})
                }
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="id">{t('Id')}</Label>
              <StyledInput
                invalid={error}
                disabled={editLogic.type.includes('customlogicbloc-')}
                type="text"
                name="id"
                id="logicId"
                value={editLogic.type.split('customlogicbloc-')[1]}
                onChange={(e) =>
                  setEditLogic({...editLogic, type: e.target.value})
                }
              />
              <small className="text-muted">
                {t('Must not contain spaces, special characters, or accents')}
              </small>
              <FormFeedback>{t('Invalid format')}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <StyledCodeWrapper style={{height: 200}}>
          <CodeEditor
            onChange={(c) => setEditLogic({...editLogic, code: c})}
            setEditorRef={setCodeFlaskRef}
            code={editLogic.code}
          />
        </StyledCodeWrapper>
      </Form>
      <FormFooter
        editLogic={editLogic}
        handleDelete={handleDelete}
        getBlocUsage={getBlocUsage}
      />
    </div>
  );
};

export default EditLogicBloc;

const StyledInput = styled(Input)`
  &::placeholder {
    font-style: italic;
  }
`;

const StyledCodeWrapper = styled.div.attrs({
  className: 'mt-4 mb-2',
})`
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.16);
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
  position: relative;

  #more {
    align-items: flex-end;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 55%,
      rgba(255, 255, 255, 0) 100%
    );
    bottom: 0;
    display: flex;
    height: 38px;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 100;
  }
`;
