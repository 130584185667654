// @flow
import {chain, find, set, get, sortBy} from 'lodash';

export const formatValues = (
  data: Object,
  keys: Array<string>
): Array<Object> =>
  chain(data)
    .get('botUsage', [])
    .groupBy('aggregation.operator')
    .map((usages, operator) => {
      const res = {
        operator,
      };

      keys.forEach((key) => {
        set(
          res,
          key,
          find(usages, (u) => u.aggregation.value === key)?.value ?? 0
        );
      });

      return res;
    })
    .value();

export const findOperator = (
  values: Array<Object>,
  search: string
): Array<Object> =>
  values.filter(
    ({operator}) => operator.toLowerCase().indexOf(search.toLowerCase()) !== -1
  );

export const getTop = (
  usage: Array<string>,
  formatedValues: Array<Object>
): Array<{key: string, value: number}> => {
  const summedValues = formatedValues.map((v) => ({
    key: v.operator,
    value: chain(v).pick(usage).values().sum().value(),
  }));
  return sortBy(summedValues, ['value']).reverse();
};

export const getTopRatio = (
  topAsssistance: Array<{key: string, value: number}>,
  topNonAssistance: Array<{key: string, value: number}>
): Array<{key: string, value: number}> => {
  const summedValues = topAsssistance.map(({key, value}) => {
    const nValue = get(find(topNonAssistance, {key}), 'value', 0);
    const ratio = Math.round((value / (value + nValue)) * 100);
    return {
      key,
      value: isNaN(ratio) ? 0 : ratio,
    };
  });

  return sortBy(summedValues, ['value']).reverse();
};
