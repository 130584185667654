// @flow
import React, {Fragment} from 'react';
import {UncontrolledTooltip as Ut} from 'reactstrap';
import styled from 'styled-components';
import {v4 as uuidv4} from 'uuid';

import {EVENT_VALUES, ASSISTANCE_USAGES} from '../../../../lib/analytics';
import {percent} from '../../../../lib/utils';

const ProgressBar = ({
  data,
  isLoading,
  totalCount,
  totalRefusedAssistance,
}: {
  data: Object,
  isLoading: boolean,
  totalCount: number,
  totalRefusedAssistance: number,
}) => {
  const refusedAssistanceValue = totalRefusedAssistance / totalCount || 0;
  const noAssistanceValue = getNoAssistanceValue(data, totalCount);

  return (
    <div>
      <StyledProgress isLoading={isLoading}>
        {ASSISTANCE_USAGES.map(
          (usage) =>
            data[usage] > 0 && (
              <Fragment key={uuidv4()}>
                <StyledProgressBar
                  id={usage}
                  color={EVENT_VALUES[usage].color}
                  value={data[usage] / totalCount}
                />
                <Ut target={usage}>
                  {EVENT_VALUES[usage].title}
                  <br />
                  {percent(data[usage] / totalCount, 0)}% - {data[usage]}{' '}
                  réponses
                </Ut>
              </Fragment>
            )
        )}
        <StyledProgressBar
          striped
          color="#00000073"
          value={refusedAssistanceValue}
          id="refusedAssistanceValue"
        />
        <Ut target="refusedAssistanceValue">
          Assistance non utilisée <br />
          {percent(refusedAssistanceValue, 0)}% - {totalRefusedAssistance}{' '}
          réponses
        </Ut>
        <StyledProgressBar
          striped
          color="var(--gray-300)"
          value={noAssistanceValue}
          id="noAssistanceValue"
        />
        <Ut target="noAssistanceValue">
          Pas d'assistance à la réponse <br />
          {percent(noAssistanceValue, 0)}% - {data.NO_ASSISTANCE} réponses
        </Ut>
      </StyledProgress>
    </div>
  );
};

export default ProgressBar;

const StyledProgress = styled.div.attrs(({isLoading}) => ({
  className: `my-3 progress ${isLoading ? 'isLoading' : ''}`,
}))`
  background-color: #fff;
  height: 35px;

  &.loading {
    border: 1px solid var(--gray-300);
  }
`;

const StyledProgressBar = styled.div.attrs(({striped}) => ({
  className: `progress-bar clic ${striped ? 'progress-bar-striped' : ''}`,
}))`
  background-color: ${(props) => props.color};
  width: ${(props) => props.value * 100}%;
`;

function getNoAssistanceValue(data: Object, totalCount: number): number {
  if (totalCount === 0) {
    return 1;
  }

  const noAssistance = data?.NO_ASSISTANCE ?? 0;
  return noAssistance / totalCount;
}
