import {FirestoreState} from '../../reducer/types/firestore-types';

export const SET_JWT = 'SET_JWT';
export const SET_USER = 'SET_USER';
export const SET_SETTING = 'SET_SETTING';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_BLOC_LOGIC = 'SET_BLOC_LOGIC';
export const SET_OPERATORS_GROUPS = 'SET_OPERATORS_GROUPS';
export const ADD_REALTIME_REF = 'ADD_REALTIME_REF';
export const RESET_REALTIME_REF = 'RESET_REALTIME_REF';

interface SetUser {
  type: typeof SET_USER;
  user: FirestoreState['user'];
  id: string;
}
interface SetJwt {
  type: typeof SET_JWT;
  jwt: FirestoreState['jwt'];
}

interface SetSetting {
  type: typeof SET_SETTING;
  setting: FirestoreState['setting'];
}

interface SetTemplates {
  type: typeof SET_TEMPLATES;
  templates: FirestoreState['templates'];
}

interface SetBlocLogic {
  type: typeof SET_BLOC_LOGIC;
  blocLogics: FirestoreState['blocLogics'];
}

interface SetZendeskOperatorsGroups {
  type: typeof SET_OPERATORS_GROUPS;
  operators: FirestoreState['zendeskAuthors'];
  groups: FirestoreState['zendeskGroups'];
}

interface AddRealTimeRef {
  type: typeof ADD_REALTIME_REF;
  ref: FirestoreState['realtimeRefs'];
}

interface ResetRealTimeRef {
  type: typeof RESET_REALTIME_REF;
}

export type FirestoreActionsTypes =
  | SetUser
  | SetJwt
  | SetSetting
  | SetTemplates
  | SetBlocLogic
  | SetZendeskOperatorsGroups
  | AddRealTimeRef
  | ResetRealTimeRef;
