// @flow
import React from 'react';
import {Row, Col} from 'reactstrap';

import NumberChart from '../../../analytics/components/number';

const Numbers = ({
  loading,
  totalResponses = 0,
  totalIntents = 0,
  totalTemplatesUsage = 0,
}: {
  loading: boolean,
  totalResponses?: number,
  totalIntents?: number,
  totalTemplatesUsage?: number,
}) => (
  <Row>
    <Col md={3}>
      <NumberChart
        usage="Nombre de réponses"
        isLoading={loading}
        value={totalResponses}
      />
    </Col>
    <Col md={3}>
      <NumberChart
        usage="Catégories"
        isLoading={loading}
        value={totalIntents}
      />
    </Col>
    <Col md={6}>
      <NumberChart
        usage="Utilisation de modèles de réponse"
        unit="%"
        isLoading={loading}
        value={totalTemplatesUsage}
      />
    </Col>
  </Row>
);

export default Numbers;
