// @flow
import React, {useEffect, useState} from 'react';
import firebase from 'firebase/app';
import styled from 'styled-components';

const OfflineBanner = () => {
  const [notLoggedIn, setnotLoggedIn] = useState(undefined);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        setnotLoggedIn(true);
      }
    });
  }, []);

  return (
    <StyledBanner>
      Version de démonstration
      {notLoggedIn && (
        <a
          link="isLoggedIn"
          target="_blank"
          rel="noopener noreferrer"
          href="https://app.hubwa.re/sign-up"
          className="ml-2 animate__animated animate__fadeInRight position-absolute"
          style={{textDecoration: 'underline'}}
        >
          Inscrivez-vous
        </a>
      )}
    </StyledBanner>
  );
};

export default OfflineBanner;

const StyledBanner = styled.div.attrs({
  className: 'p-2 text-center position-relative',
})`
  background: rgba(255, 193, 7, 0.5);
  color: var(--gray-dark);
  margin: 0;
`;
