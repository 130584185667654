import firebase from 'firebase/app';
import {oauthConstants} from './zendesk';

import {
  Filter,
  FilterResult,
} from '../views/configuration/templates/details/logic/utils';

export const graphql = (operationName: string, variables: Object) => {
  const query = `
  query SearchEvalJobByConfig($settingsId: String!, $config: String!, $version: String!) {
    settings(settingsId: $settingsId) {
      evalJobConnectionByConfig(config: $config, version: $version) {
        edges {
          node {
            id
            endDate
            success
            version
            counts
            recall
            coverage
            precision
            training
          }
        }
      }
    }
  }
`;
  const fn = firebase.app().functions('europe-west1').httpsCallable('graphql');
  return fn({
    query,
    variables,
    operationName,
  });
};

export const configureZendeskFromBoard = (code: string, domain: string) => {
  const fn = firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('configureZendeskFromBoard');

  const {client_id, redirect_uri, scope} = oauthConstants(domain);

  return fn({
    zendesk: {
      code,
      domain,
      client_id,
      redirect_uri,
      scope,
    },
  });
};

export const getPrediction = async (data: {
  config: string;
  settingsId: string;
  content: {
    body: string;
    subject: string;
  };
  source?: string;
}): Promise<{
  intents: string[];
  language: string;
  patterns: Object;
  subject?: string;
  body?: string;
}> => {
  const fn = firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('getPrediction');

  const {
    data: {intents, language, patterns},
  } = await fn({source: 'dashboard-v2', ...data});

  return {intents, language, patterns};
};

export const fetchAgentsAndGroups = (settingId: string) => {
  const fn = firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('fetchAgentsAndGroups');

  return fn({settingId});
};

export const updateActiveMacros = (settingId: string) => {
  const fn = firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('updateActiveMacrosOnCall');

  return fn({settingId});
};

export const runFilterOnTicket = (
  settingId: string,
  externalId: string,
  macroFilters: Filter[]
): Promise<{data: FilterResult[]}> => {
  const fn = firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('runFilterOnTicket');

  return fn({settingId, externalId, macroFilters});
};
