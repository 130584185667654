// @flow
import React from 'react';
import {Info} from 'react-feather';
import {useTranslation} from 'react-i18next';

import {oauthConstants} from '../../../../lib/zendesk';

const BlockedPopUpInfo = ({
  domain = '',
  clicked,
}: {
  domain: string,
  clicked: boolean,
}) => {
  const {t} = useTranslation();
  return (
    <div
      className={`d-flex align-items-center animate__animated animate__delay-3s ${
        clicked ? 'animate__fadeInUp' : 'invisible'
      }`}
    >
      <Info
        size="16"
        className="text-warning mr-2"
        style={{strokeWidth: '3px'}}
      />
      <p className="m-0">
        {t('If the authorization pop-up has been blocked by your browser,')}{' '}
        <strong>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={oauthConstants(domain).link}
            className="text-hubware"
          >
            {t('Click here')}
          </a>
        </strong>
      </p>
    </div>
  );
};

export default BlockedPopUpInfo;
