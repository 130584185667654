// @flow
import React, {type Node} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import {NavLink} from 'react-router-dom';

const CustomNavLink = ({
  children,
  pathname,
  exact,
  className,
  active,
}: {
  children: Node,
  pathname?: string,
  exact?: boolean,
  className?: string,
  active?: boolean,
}) => {
  if (pathname) {
    return (
      <StyledPathNavLink
        to={{pathname, search: window.location.search}}
        exact={exact}
        className={classnames('nav-link', className)}
        activeClassName="active"
      >
        {children}
      </StyledPathNavLink>
    );
  }

  return (
    <StyledNavLink className={classnames({active}, className, 'nav-link')}>
      {children}
    </StyledNavLink>
  );
};

export default CustomNavLink;

const style = `
  border-bottom: 2px solid var(--white);
  color: var(--gray);
  margin: 0 10px;
  padding: 20px 0;

  &:hover {
    color: var(--gray-dark);
  }

  &.active {
    color: var(--hubware) !important;
    border-bottom: 2px solid var(--hubware);
  }

  svg {
    margin-right: 0.7rem;
  }
`;

const StyledPathNavLink = styled(NavLink)`
  ${style}
`;

const StyledNavLink = styled.div`
  ${style}
`;
