// @flow
import React from 'react';
import {kebabCase} from 'lodash';
import {useSelector, useStore} from 'react-redux';
import styled from 'styled-components';

import {Card} from '../../components/card';
import TabNav from '../../components/tab-nav';
import Table from '../../components/table';
import {getAllActions} from '../../selectors/actions';
import {
  getAllIntents,
  getIntentsListWithTemplates,
} from '../../selectors/intents';
import {getAllTemplates} from '../../selectors/templates';
import actionsUtils from './actions/list';
import intentsUtils from './intents/list';
import templatesUtils from './templates/list';

const Config = ({route, intents}: {route: Object, intents: Object}) => {
  const allIntents = useSelector(getAllIntents);
  const tabsMap = React.useMemo(
    () => [intentsUtils.tab, templatesUtils.tab, actionsUtils.tab],
    []
  );

  const columnsMap = React.useMemo(
    () => ({
      intents: intentsUtils.columns(),
      templates: templatesUtils.columns(allIntents),
      actions: actionsUtils.columns(),
    }),
    [allIntents]
  );

  const addButtonMap = {
    intents: intentsUtils.AddButton(),
    templates: templatesUtils.AddButton(),
    actions: actionsUtils.AddButton(),
  };

  const store = useStore();
  const pairs =
    store.getState()?.firestore?.setting?.operatorsIntentPairs ?? {};

  const dataMap = {
    intents: useSelector(getIntentsListWithTemplates)
      .filter(({templates}) => templates.length > 0)
      .map((intent) => ({...intent, pair: pairs?.[intent.key]})),
    templates: useSelector(getAllTemplates),
    actions: useSelector(getAllActions),
  };
  const selectedTab =
    tabsMap.find(({pathname}) => window.location.pathname === pathname) || {};

  return (
    <div className="container">
      <Card className="mb-3">
        <div className="card-body py-0 d-flex justify-content-between align-items-center">
          <TabNav
            tabsMap={tabsMap.map((t) => ({
              ...t,
              value: dataMap[t.key].length,
            }))}
          />
          <div>{addButtonMap[selectedTab.key]}</div>
        </div>
      </Card>
      <Card>
        <StyledTableWrapper>
          <Table
            sortBy={['intent', 'title']}
            data={dataMap[selectedTab.key]}
            columns={columnsMap[selectedTab.key]}
            csvFilename={kebabCase(selectedTab.title)}
          />
        </StyledTableWrapper>
      </Card>
    </div>
  );
};

export default Config;

const StyledTableWrapper = styled.div`
  table {
    th:last-child,
    td:last-child {
      text-align: center;
      width: 80px;
    }
    td:last-child {
      padding: 7px 0;
    }
    svg:hover {
      cursor: pointer;
      transform: scale(1.25);
    }
  }
`;
