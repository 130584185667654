// @flow
import React from 'react';
import styled from 'styled-components';
import {Info} from 'react-feather';

const Help = ({help, eventId}: {help?: string, eventId: string}) => {
  if (!help) {
    return null;
  }

  return (
    <StyledWrapper>
      <StyledIcon>
        <Info size={40} id={eventId} color="white" />
      </StyledIcon>
      <StyledBubble>
        <span>{help}</span>
      </StyledBubble>
    </StyledWrapper>
  );
};

export default Help;

const StyledWrapper = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const StyledIcon = styled.div.attrs({
  className:
    'animate__animated animate__fadeInUp animate__delay-1s hubware-gradient shadow',
})`
  border-radius: 50%;
  bottom: 10px;
  padding: 5px;
  position: absolute;
  right: 10px;
`;

const StyledBubble = styled.div.attrs({
  className: 'animate__animated animate__fadeInUp animate__delay-1s text-right',
})`
  background-color: #fff;
  bottom: 50px;
  border: #eee;
  border-radius: 4px;
  box-shadow: 1px 1px 4px #ddd;
  color: var(--gray-dark);
  line-height: 1.3;
  margin: 0 auto 40px;
  max-width: 350px;
  padding: 10px;
  position: absolute;
  right: 0;

  .p {
    margin: 0 0 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ::after {
    content: '';
    border-top: 20px solid #fff;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    position: absolute;
    right: 20px;
    top: 100%;
  }

  ::before {
    content: '';
    border-left: 23px solid transparent;
    border-right: 23px solid transparent;
    border-top: 23px solid;
    border-top-color: inherit;
    position: absolute;
    right: 18px;
    top: 100%;
  }
`;
