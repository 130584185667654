// @flow
import React from 'react';
import {useQuery} from 'graphql-hooks';
import {chain, sumBy} from 'lodash';

import {CardError} from '../../components/card';
import getDataHook, {defaultDateRange} from '../../lib/graphQL';
import {buildAnalytics} from './utils';

import Numbers from './numbers';
import Progress from '../analytics/components/progress';

const Home = () => {
  const botUsages = useQuery(
    ...getDataHook('getBotUsage', {date: defaultDateRange})
  );
  const varsAndClics = useQuery(
    ...getDataHook('getClicsAndVarsCount', {date: defaultDateRange})
  );
  const isLoading = () => botUsages.loading || varsAndClics.loading;

  if (botUsages.error || varsAndClics.error) {
    return <CardError />;
  }

  const groupedBotUsages = chain(botUsages.data)
    .get('botUsage', [])
    .groupBy('grouped')
    .mapValues((v) => sumBy(v, 'value'))
    .value();

  const groupedVarsAndClics = chain(varsAndClics.data)
    .get('linksAndVars', [])
    .keyBy('key')
    .mapValues('value')
    .value();

  const data = buildAnalytics({
    ...groupedBotUsages,
    ...groupedVarsAndClics,
  });

  return (
    <div className="container" id="home-wrapper">
      <Numbers
        data={data}
        isLoading={isLoading()}
        dateRange={defaultDateRange}
      />

      <Progress data={data} isLoading={isLoading()} />
    </div>
  );
};

export default Home;
