// @flow
import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Form, FormGroup, Label, Input} from 'reactstrap';
import {withRouter} from 'react-router-dom';

import Modal from '../../../../components/modal';
import {addPreparedReply} from '../../../../lib/firestore/prepared-reply';

const NEW_TEMPLATE =
  '<!DOCTYPE html><html><body style="font-family: sans-serif; font-size: 12px;"> <p>Hello World!</p></body></html>';

export default withRouter(
  ({
    modal,
    setModal,
    history,
  }: {
    modal: boolean,
    setModal: (x: boolean) => void,
    history: Object,
  }) => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
      setTitle('');
      setIsloading(false);
    }, []);

    const handleSubmit = async (e) => {
      e.preventDefault();
      const form = document.getElementById('add-template-form');
      //$FlowFixMe
      if (!form.reportValidity()) {
        return;
      }
      setIsloading(true);
      const {id} = await dispatch(addPreparedReply(title, NEW_TEMPLATE));
      setIsloading(false);
      setModal(false);
      history.push({
        pathname: `/config/templates/${id}/details`,
        search: window.location.search,
      });
    };

    return (
      <Modal
        size="md"
        modal={modal}
        setModal={setModal}
        handleConfirm={handleSubmit}
        handleCancel={() => {
          setTitle('');
          setModal(false);
        }}
        title="Nouvelle réponse"
        isLoading={isLoading}
      >
        <Form onSubmit={handleSubmit} id="add-template-form">
          <FormGroup>
            <Label for="title">Titre</Label>
            <Input
              autoFocus={true}
              type="text"
              id="title"
              name="title"
              placeholder="Titre..."
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </FormGroup>
          {/* we add a hidden submit button in orer to trigger form HTML5 validation */}
          <button type="submit" className="d-none" />
        </Form>
      </Modal>
    );
  }
);
