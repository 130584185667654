import {v4 as uuidv4} from 'uuid';

export interface Filter {
  id: number;
  description: string;
  script: string;
  disabled: boolean;
  suggestOnError?: boolean;
}
export interface FilterBloc {
  id: string;
  type: string;
  title: string;
  description: string;
  code: string;
  testFail?: boolean;
}

export type FilterResult = {
  executionTimeMs: number;
  id: number;
  log: string[];
  reason: string;
  suggest: boolean;
};

export const newFilterBloc = (
  props: {
    type?: string;
    title?: string;
    description?: string;
    code?: string;
  } = {}
) => ({
  id: uuidv4(),
  type: '',
  title: '',
  description: '',
  code: '',
  ...props,
});

export const splitScript = (script: string = ''): FilterBloc[] => {
  const BLOC_START = '/* automatic comment to not alter *\n';
  const BLOC_END = 'automatic comment to not alter */\n';
  // Check id there is no script
  if (!script) {
    return [];
  }

  // Check if at least one bloc exists
  if (script.indexOf(BLOC_START) === -1) {
    return [newFilterBloc({type: 'code', code: script, title: 'Personnalisé'})];
  }

  return script
    .split(BLOC_START)
    .map((b) => {
      const [meta, code] = b.split(BLOC_END);
      return {
        id: uuidv4(),
        type: /\* type: (.*)/g.exec(meta)?.[1] ?? '',
        title: /\* title: (.*)/g.exec(meta)?.[1] ?? '',
        description: /\* description: (.*)/g.exec(meta)?.[1] ?? '',
        code,
      };
    })
    .filter(({code}) => code !== undefined);
};

export const stringifyBlocs = (filterBlocs: FilterBloc[]): string =>
  filterBlocs
    .filter(({type}) => type !== 'new')
    .map(stringifyBloc)
    .join('\n');

const stringifyBloc = ({
  type,
  title,
  description,
  code,
}: FilterBloc): string => `/* automatic comment to not alter *
* title: ${title}
* description: ${description}
* type: ${type}
automatic comment to not alter */
${code}`;
