// @flow
import {createSelector} from 'reselect';
import {merge} from 'lodash';

export type BOARDCONFIG_INTERFACE = {
  freemiumSteps: Array<Object>,
  assistanceBenefits: {
    DELAYS: Object,
    avgProcessingDuration: number,
    avgProcessingDelay: number,
  },
  requestAccountDeletion: boolean,
  requestDataExport: boolean,
  sabotageAnalytics?: {
    value?: string,
    period?: string,
    tickets?: Array<{
      ticketId?: string,
      creation?: string,
      intent?: string,
      status?: string,
    }>,
    intents?: Array<{
      value?: string,
      intent?: string,
    }>,
  },
};

const boardConfiguration = ({
  firestore: {
    setting: {boardConfiguration = {}},
  },
}) => {
  const defaultValue = {
    freemiumSteps: [
      {
        description: "Téléchargement et installation de l'Analyzer",
        status: 'done',
        title: 'Installation',
      },
      {
        description: 'Inscription validée, connexion effectuée',
        status: 'done',
        title: 'Inscription',
      },
      {
        description: 'Analyse des messages clients et des réponses conseillers',
        status: 'active',
        title: 'Analyse',
      },
      {
        description: 'Traitement et catégorisation des conversations',
        title: 'Traitement',
      },
      {
        description: "Envoi du rapport d'analyse détaillé",
        title: "Rapport d'analyse",
      },
    ],
    assistanceBenefits: {
      DELAYS: {
        AUTO_REPLY: 1, // percent
        MACRO_AUTO_USED: 0.6, // percent
        SUGGESTION_USED: 0.3, // percent
        VARIABLE_SUBSTITUTION_MACRO_COUNT: 0.04, // percent
        LINK_CLICKED: 0.2, // percent
      },
      avgProcessingDuration: 1.5, // minutes
      avgProcessingDelay: 20 * 60, // minutes
    },
    requestAccountDeletion: undefined,
    requestDataExport: undefined,
  };

  return merge(defaultValue, boardConfiguration);
};

export const getBoardconfiguration = createSelector(
  [boardConfiguration],
  (config) => config
);
