// @flow
import React from 'react';
import {map} from 'lodash';
import {Button} from 'reactstrap';
import styled from 'styled-components';
import {MoreHorizontal, Plus} from 'react-feather';

import Table from '../../../../components/table';

const MetaDataList = ({
  metaDataMapping,
  handleclick,
}: {
  metaDataMapping: Object,
  handleclick: Function,
}) => (
  <>
    <div className="d-flex justify-content-between align-items-center">
      <h5 className="m-0">Éléments variables disponibles</h5>
      <Button
        color="hubware"
        id="add-button"
        outline
        onClick={() => handleclick(undefined)}
      >
        <Plus size="15" className="mr-2" /> Ajouter
      </Button>
    </div>
    {Object.keys(metaDataMapping).length > 0 && (
      <StyledTableWrapper>
        <Table
          pageSize={8}
          columns={getColmuns(handleclick)}
          data={formatValues(metaDataMapping)}
          sortBy={['key']}
        />
      </StyledTableWrapper>
    )}
    {Object.keys(metaDataMapping).length === 0 && (
      <p className="text-center my-4" style={{fontSize: '90%'}}>
        Aucun élément variable disponible
      </p>
    )}
  </>
);

export default MetaDataList;

function getColmuns(handleclick: Function) {
  return [
    {
      accessor: 'key',
      Header: 'Clef',
      Cell: ({cell: {value}}: {cell: {value: string}}) => `@@${value}@@`,
    },
    {
      accessor: 'description',
      Header: 'Description',
      Cell: ({cell: {value}}: {cell: {value?: string}}) =>
        value ? value : <em>Aucune description</em>,
    },
    {
      Header: 'Modifier',
      accessor: 'edit',
      disableSorting: true,
      Cell: ({cell: {value}}: {cell: {value?: string}}) => (
        <span>
          <MoreHorizontal onClick={() => handleclick(value)} color="grey" />
        </span>
      ),
    },
  ];
}

function formatValues(metaDataMapping: Object): Array<Object> {
  return map(
    metaDataMapping,
    (value: {description?: string, jsonata: string} | string, key: string) => ({
      key,
      edit: key,
      description: typeof value === 'string' ? undefined : value.description,
    })
  );
}

const StyledTableWrapper = styled.div.attrs({
  className: 'mt-4',
})`
  table {
    th:last-child,
    td:last-child {
      text-align: center;
      width: 80px;
    }
    svg:hover {
      cursor: pointer;
      transform: scale(1.25);
    }
  }
`;
