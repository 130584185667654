// @flow
import React, {useState} from 'react';
import {FormGroup, Input, Label, Button} from 'reactstrap';
import {useTranslation} from 'react-i18next';

import {sendPasswordResetEmail} from '../../lib/firebase';
import SignWrapper from './sign-wrapper';

const RecoverPassword = () => {
  const [invalidForm, setInvalidform] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [validForm, setValidform] = useState(false);
  const [email, setEmail] = useState('');

  const {t} = useTranslation();
  const handleSubmit = (event) => {
    event.preventDefault();
    sendPasswordResetEmail(email)
      .then(() => {
        setValidform(true);
        setInvalidform(false);
      })
      .catch((err) => {
        setValidform(false);
        setInvalidform(true);
        setErrorMessage(err.message);
      });
  };

  return (
    <SignWrapper link={{pathname: '/sign-in', title: t('Back')}}>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="email">{t('Email')}</Label>
          <Input
            bsSize="lg"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            invalid={invalidForm}
            valid={validForm}
            disabled={validForm}
          />

          <div className="valid-feedback">
            {t('An email will be sent to the address')} {email}
            <br />
            {t('containing instructions allowing you to change your password')}
          </div>
          <div className="invalid-feedback">{errorMessage}</div>
        </FormGroup>
        <FormGroup>
          <Button
            color="hubware"
            size="lg"
            className="w-100"
            disabled={validForm}
          >
            {t('Reset password')}
          </Button>
        </FormGroup>
      </form>
    </SignWrapper>
  );
};

export default RecoverPassword;
