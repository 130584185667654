// @flow
import {useState, useCallback, useMemo} from 'react';
import {useDispatch, useStore} from 'react-redux';
import {DateTime} from 'luxon';

import {fetchAgentsAndGroups} from '../../lib/callable-functions';
import {setOperatorsAndGroups} from '../../lib/firestore/operators';

const useModal = (): Object => {
  const dispatch = useDispatch();
  const {getState} = useStore();

  const settingId = getState()?.firestore.setting?.id;
  const authorsLastUpdate = getState()?.firestore.setting?.zendesk
    ?.authorsLastUpdate;

  const [loading, setLoading] = useState(false);

  const handleRefresh = useCallback(async () => {
    setLoading(true);
    await fetchAgentsAndGroups(settingId); // Database sync
    await dispatch(setOperatorsAndGroups()); // fetch database into global state
    setLoading(false);
  }, [dispatch, settingId]);

  const needUpdate = useMemo(
    () =>
      !authorsLastUpdate ||
      DateTime.fromJSDate(authorsLastUpdate) <
        DateTime.local().minus({day: 30}).startOf('day'),
    [authorsLastUpdate]
  );

  const updatedAt = useMemo(
    () => DateTime.fromJSDate(authorsLastUpdate).toRelativeCalendar(),
    [authorsLastUpdate]
  );

  return {loading, needUpdate, handleRefresh, updatedAt};
};

export default useModal;
