// @flow
import firebase from 'firebase/app';

import {
  handleUser,
  handleProvider,
  getDBConnection,
  handleDisconnected,
  handleFirestoreConnection,
} from './utils';

let wasInit = false;

const Init = (testEnv: boolean = false) => async (dispatch: Function) => {
  if (wasInit && !testEnv) {
    throw new Error('App Already init');
  }
  wasInit = true;

  return firebase.auth().onAuthStateChanged(async (res) => {
    if (!res) {
      // If disconnected
      return dispatch(handleDisconnected());
    }
    // Handle User data and loggedUser infos
    const loggedUser: {email: string, uid: string} = res.toJSON();
    await dispatch(handleUser(loggedUser));
    // connect to firestore data
    const db = await dispatch(getDBConnection());
    if (!db) {
      // If error thrown before
      return;
    }

    await dispatch(handleProvider(db));
    await dispatch(handleFirestoreConnection(db));
  });
};

export default Init;
