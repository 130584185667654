// @flow
import {DateTime} from 'luxon';
import {get, chain, sumBy, groupBy, find, pickBy, sortBy, map} from 'lodash';

export const buildBarsDataset = (
  yLabels: Array<string>,
  data: Object
): Array<Object> => {
  const groupedData = groupData(yLabels, data);

  return [
    {
      yAxisID: 'predictions',
      label: 'Avec catégorie',
      backgroundColor: 'hsl(214, 69%, 35%)',
      borderColor: 'transparent',
      data: groupedData.map(({date: x, intents: y}) => ({x, y})),
    },
    {
      yAxisID: 'predictions',
      label: 'Sans catégorie',
      backgroundColor: 'hsl(214, 69%, 75%)',
      borderColor: 'transparent',
      data: groupedData.map(({date: x, no_intent: y}) => ({x, y})),
    },
  ];
};

export const buildMeanLineDataset = (
  yLabels: Array<string>,
  data: Object
): Object => {
  const groupedData = groupData(yLabels, data);
  const coveragePerWeek = getCoveragePerWeek(groupedData);

  return {
    yAxisID: 'coverage',
    type: 'line',
    label: 'Couverture hebdomadaire',
    data: coveragePerWeek,
    fill: false,
    lineTension: 0.2,
    borderColor: '#ed484e',
    backgroundColor: 'transparent',
  };
};

export const getTotalCount = (data: Object): number =>
  sumBy(data?.predictions ?? [], 'value');

export const getIntentsCount = (data: Object): number =>
  chain(data)
    .get('predictions')
    .map(({grouped}) => grouped)
    .uniq()
    .filter((v) => v)
    .filter((v) => !v.includes(':other')) // remove "other" intent
    .value().length;

export const getTotalCoverage = (
  yLabels: Array<string>,
  data: Object
): number => {
  const groupedData = groupData(yLabels, data);
  const totalIntents = sumBy(groupedData, 'intents');
  const totalNoIntents = sumBy(groupedData, 'no_intent');
  const percentage = (totalIntents / (totalIntents + totalNoIntents)) * 100;
  return isNaN(percentage) ? 0 : Math.round(percentage);
};

function getCoveragePerWeek(
  groupedData: Array<{date: string, intents: number, no_intent: number}>
): Array<{x: string, y: number}> {
  const groupedByWeek = groupBy(
    groupedData,
    (o) => DateTime.fromISO(o.date).weekNumber
  );

  const fullWeeks = pickBy(groupedByWeek, (a) => a.length === 7);

  const assistanceByWeek = map(fullWeeks, (weekValues) => {
    const totalIntents = sumBy(weekValues, 'intents');
    const totalNoIntents = sumBy(weekValues, 'no_intent');
    const percentage = (totalIntents / (totalIntents + totalNoIntents)) * 100;

    return {
      x: sortBy(weekValues, 'date')[2].date, // Get thursday.. don't judge me..
      y: isNaN(percentage) ? 0 : Math.round(percentage),
    };
  });

  return sortBy(assistanceByWeek, 'x');
}

function groupData(
  yLabels: Array<string>,
  data: Object
): Array<{date: string, intents: number, no_intent: number}> {
  const groupedData = groupBy(get(data, 'predictions', []), (d) =>
    d.grouped ? 'intent' : 'no_intent'
  );

  const summedIntentsData = sumDataWithIntents(groupedData?.intent);

  return yLabels.map((date) => ({
    date,
    intents: find(summedIntentsData, {date})?.value ?? 0,
    no_intent: find(groupedData?.no_intent ?? [], {date})?.value ?? 0,
  }));
}

function sumDataWithIntents(
  data?: Array<{date: string, value: number}> = []
): Array<{date: string, value: number}> {
  return chain(data)
    .groupBy('date')
    .map((values, date) => ({
      date,
      value: sumBy(values, 'value'),
    }))
    .value();
}
