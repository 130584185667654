// @flow
import React from 'react';
import styled from 'styled-components';

const Bubble = ({color, symbol}: {color: string, symbol: string}) => (
  <StyledBubble color={color}>
    <div className="d-flex align-self-center">{symbol}</div>
  </StyledBubble>
);

export default Bubble;

const StyledBubble = styled.div.attrs({
  className: 'd-flex ml-auto align-self-center justify-content-center',
})`
  background-color: ${(props) => props.color};
  border-radius: 50px;
  color: #fff;
  font-weight: bolder;
  font-size: 1.7rem;
  height: 3rem;
  text-transform: uppercase;
  width: 3rem;
`;
