// @flow
import React, {useState} from 'react';
import {Row, Col, Card} from 'reactstrap';

import PredictionAnswer from './predictionAnswer';
import PredictionForm from './predictionForm';

const Requests = () => {
  const [predictions, setPredictions] = useState([]);
  const addPrediction = (prediction: Object) =>
    setPredictions([prediction, ...predictions]);

  return (
    <>
      <Card>
        <div className="card-body">
          <h4 className="card-title m-0 pr-4">
            Tester la performance de l'intelligence artificielle.
          </h4>
          <h6 className="card-subtitle mt-2 text-muted">
            Simuler l'envoi d'un message à votre service client et découvrer
            quelle catégorie est détectée par le modèle.
          </h6>
        </div>
      </Card>
      <Row>
        <Col>
          <PredictionForm addPrediction={addPrediction} />
        </Col>
        <Col>
          <PredictionAnswer predictions={predictions} />
        </Col>
      </Row>
    </>
  );
};

export default Requests;
