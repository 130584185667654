// @flow
import React from 'react';
import {DateTime} from 'luxon';
import styled from 'styled-components';
import {capitalize} from 'lodash';

export interface ItemProps {
  to: string;
  status: string;
  replyAt: Object;
  externalId: string;
  externalUrl: string;
  selectedTab: string;
  templateTitle: string;
}

const ListItem = ({
  to,
  status,
  replyAt,
  externalId,
  externalUrl,
  selectedTab,
  templateTitle,
}: ItemProps) => (
  <StyledReply>
    <div className="d-flex justify-content-between">
      <h6>
        {capitalize(replyAt.toRelativeCalendar())} à{' '}
        {replyAt.toLocaleString(DateTime.TIME_24_SIMPLE)}
      </h6>

      <a
        href={externalUrl}
        rel="noopener noreferrer"
        className="text-muted"
        target="_blank"
      >
        {externalId}
      </a>
    </div>

    <div>
      <strong>Destinataire</strong> : {to}
    </div>

    <div>
      <strong>Modèle de réponse</strong> : {templateTitle}
    </div>

    {selectedTab === 'rejected' && (
      <div>
        <strong>Statut : {status}</strong>
      </div>
    )}
  </StyledReply>
);

export default ListItem;

const StyledReply = styled.div.attrs({
  className: 'px-3 py-3',
})`
  border-bottom: 1px solid var(--gray-300);

  &:first-child {
    border-top: 1px solid var(--gray-300);
  }
`;
