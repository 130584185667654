// @flow
import React from 'react';
import {MoreHorizontal} from 'react-feather';
import {Link} from 'react-router-dom';

import filters from '../../../../components/table/filters/filters';
import {type ACTION_INTERFACE} from '../../../../selectors/actions';
import AddButton from './add-button';

const columns = () => [
  {
    Header: 'Titre',
    accessor: 'title',
  },
  {
    Header: 'Description',
    accessor: (row: ACTION_INTERFACE) => row.description || '-',
  },
  {
    Header: 'Modèles de réponses',
    accessor: (row: ACTION_INTERFACE) => (row.templates || []).length || '-',
    filter: 'bool',
    Filter: filters.toggle,
  },
  {
    Header: 'Statut',
    accessor: (row: ACTION_INTERFACE) => (row.isActive ? 'Activé' : '-'),
    filter: 'bool',
    Filter: filters.toggle,
  },
  {
    Header: 'Détails',
    accessor: 'id',
    id: 'details',
    disableSorting: true,
    Cell: ({cell: {value}}: {cell: {value: string}}) => (
      <Link
        to={{
          pathname: `/config/actions/${value}/details`,
          search: window.location.search,
        }}
      >
        <MoreHorizontal color="grey" />
      </Link>
    ),
  },
];

const tab = {
  key: 'actions',
  title: 'Actions Backoffice',
  pathname: '/config/_actions',
};

const actionsUtils = {
  tab,
  columns,
  AddButton,
};

export default actionsUtils;
