// @flow
import {groupBy, map, sumBy, chain, round} from 'lodash';

import {
  EVENT_VALUES,
  ASSISTANCE_USAGES,
  REFUSED_ASSISTANCE_USAGES,
} from '../../../../lib/analytics';

export const buildLineDataset = (
  yLabels: Array<string>,
  data: Object,
  view: string
): Object => {
  const usages = [...ASSISTANCE_USAGES, ...REFUSED_ASSISTANCE_USAGES].sort();
  const dataByDay = groupBy(data?.botUsage ?? [], 'date');

  if (view === 'details') {
    return usages.map((usage) => {
      const data = getCoverageByDayByUsages(dataByDay, [usage]);
      const {shortTitle, color} = EVENT_VALUES[usage];
      return {
        data,
        lineTension: 0.2,
        label: shortTitle,
        yAxisID: 'coverage',
        borderColor: 'transparent',
        backgroundColor: color,
        cubicInterpolationMode: 'default',
      };
    });
  }

  return [
    {
      fill: false,
      lineTension: 0.2,
      yAxisID: 'coverage',
      borderColor: '#ed484e',
      label: 'Couverture globale',
      backgroundColor: 'transparent',
      cubicInterpolationMode: 'default',
      data: getCoverageByDayByUsages(dataByDay, usages),
    },
  ];
};

function getCoverageByDayByUsages(
  dataByDay: Object,
  usages: Array<string>
): Array<{x: string, y?: number}> {
  return map(dataByDay, (values, date: string) => {
    const totalCount = sumBy(values, 'value');
    const usagesCount = chain(values)
      .filter(({grouped}) => usages.includes(grouped))
      .sumBy('value')
      .value();

    const y = round((usagesCount / totalCount) * 100, 0);

    return {
      x: date,
      y: isNaN(y) ? undefined : y,
    };
  }).filter(({y}) => y !== undefined);
}

export const getProgressData = (data: Object): Object =>
  chain(data)
    .get('botUsage', [])
    .groupBy('grouped')
    .mapValues((v) => sumBy(v, 'value'))
    .value();

export const getHsitoryProgressData = (data: Object): Object =>
  chain(data)
    .get('botUsage', [])
    .keyBy('key')
    .mapValues((v) => v.value)
    .value();
