// @flow
import React, {type Node} from 'react';
import {find, get} from 'lodash';
import i18n from '../../i18n';

import Provider from './steps-components/provider';
import ZendeskDomain from './steps-components/zendesk-domain';
import Rgpd from './steps-components/rgpd';
import ZendeskInstallation from './steps-components/zendesk-installation';
import Calendly from './steps-components/calendly';
import {FinalZendesk, FinalOther} from './steps-components/final';

export const getSteps = (
  onboarding: Array<Object>
): (Array<{
  title: string,
  description?: string,
  children: (props: any) => Node,
  eventId: string,
  done?: boolean,
  disabled?: boolean,
  help?: string,
}>) =>
  [
    {
      title: i18n.t('Which ticket management solution are you using?'),
      description: i18n.t(
        'Choose the ticket management solution you use among the solutions supported by Hubware, otherwise tell us the name of your solution'
      ),
      help: i18n.t(`Hubware is also compatible with other solutions`),
      children: (props: any) => <Provider {...props} />,
      eventId: 'PROVIDER_STEP',
      done: !!find(onboarding, {id: 'PROVIDER_STEP'}),
    },
    {
      title: i18n.t('Privacy and security'),
      help: i18n.t(
        `For any questions regarding security please contact us at privacy@hubwa.re`
      ),
      children: (props: any) => <Rgpd {...props} />,
      eventId: 'RGPD_STEP',
      done: !!find(onboarding, {id: 'RGPD_STEP'}),
    },
    {
      title: i18n.t('What is your Zendesk subdomain?'),
      description: i18n.t(
        `Your subdomain is the unique identifier of your Zendesk authority. It can be identified from its URL : https://[sous-domaine].zendesk.com`
      ),
      children: (props: any) => <ZendeskDomain {...props} />,
      eventId: 'ZENDESK_DOMAIN_STEP',
      disabled:
        get(find(onboarding, {id: 'PROVIDER_STEP'}), 'value') !== 'zendesk',
      done: !!find(onboarding, {id: 'OAUTH_SUCCESS'}),
    },
    {
      title: i18n.t('Analyzer installation in progress'),
      description: i18n.t(`Please do not close this page`),
      children: (props: any) => <ZendeskInstallation {...props} />,
      eventId: 'ZENDESK_APP_INSTALL_STEP',
      disabled:
        get(find(onboarding, {id: 'PROVIDER_STEP'}), 'value') !== 'zendesk',
      done: !!find(onboarding, {
        id: 'ZENDESK_APP_INSTALL_STEP',
        value: 'VALIDATED',
      }),
    },
    {
      title: i18n.t('You are almost there!'),
      description: i18n.t(
        `Your Analyzer is now installed. After an appointment with one of our experts, we will analyze your messages and the responses sent by yours advisers.`
      ),
      help: i18n.t(
        `This meeting will allow us to present you the Hubware solution and estimate the ROI of the Assistant for your customer service.`
      ),
      children: (props: any) => <Calendly {...props} />,
      eventId: 'CALENDLY_STEP',
      done: !!find(onboarding, {
        id: 'CALENDLY_STEP',
        value: 'calendly.event_scheduled',
      }),
    },
    {
      title:
        get(find(onboarding, {id: 'PROVIDER_STEP'}), 'value') === 'zendesk'
          ? i18n.t('Thank you for installing the Hubware Analyser')
          : i18n.t('Registration completed'),
      help: i18n.t(
        `You will receive a welcome email with all the necessary information.`
      ),
      children: (props: any) =>
        get(find(onboarding, {id: 'PROVIDER_STEP'}), 'value') === 'zendesk' ? (
          <FinalZendesk {...props} />
        ) : (
          <FinalOther {...props} />
        ),
      eventId: 'FINAL_STEP',
      done: !!find(onboarding, {
        id: 'FINAL_STEP',
        value: 'enable_freemium',
      }),
    },
  ].filter((s: {disabled?: boolean}) => !s.disabled);
