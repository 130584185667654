// @flow
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {find, get} from 'lodash';
import {Play} from 'react-feather';
import {Button} from 'reactstrap';

import Modal from '../../modal';
import {updateOnboarding} from '../../../lib/firestore/user';

export const ForceOnboarding = ({
  user,
  updateOnboarding,
}: {
  user: Object,
  updateOnboarding: Function,
}) => {
  const [step, setStep] = useState({id: '', value: ''});
  const [modal, setModal] = useState(false);

  const handleClick = async () => {
    await updateOnboarding(step);
  };

  return (
    <>
      <div onClick={() => setModal(true)}>
        <Play color="gray" className="mr-2" size="12" />
      </div>
      <Modal
        title="Passer à l'étape :"
        handleConfirm={() => {
          handleClick();
          setModal(!modal);
        }}
        handleCancel={() => {
          setStep({id: '', value: ''});
          setModal(!modal);
        }}
        setModal={setModal}
        size="sm"
        modal={modal}
      >
        {stepToGo(user).map(({id, value, children}) => (
          <Button
            key={`id-${id}`}
            className="clic mr-1"
            outline
            active={step.id === id}
            onClick={() => setStep({id, value})}
          >
            {children}
          </Button>
        ))}
      </Modal>
    </>
  );
};

function stepToGo(user: Object) {
  const userProvider = get(
    find(user?.uiSettings?.onboarding, {
      id: 'PROVIDER_STEP',
    }),
    'value'
  );
  let steps = [
    {id: 'RGPD_STEP', value: '', children: 'Calendly'},
    {
      id: 'CALENDLY_STEP',
      value: 'calendly.event_scheduled',
      children: 'Final step',
    },
    {id: 'FINAL_STEP', value: 'enable_freemium', children: 'Freemium'},
  ];

  if (userProvider === 'zendesk') {
    steps[0] = {
      id: 'ZENDESK_APP_INSTALL_STEP',
      value: 'VALIDATED',
      children: 'Calendly',
    };
  }
  return steps;
}

export default connect(
  () => ({}),
  (dispatch) => ({
    ...bindActionCreators({updateOnboarding}, dispatch),
  })
)(ForceOnboarding);
