import {combineReducers} from 'redux';

import {appReducer} from './app';
import {dataReducer} from './data';
import {firestoreReducer} from './firestore';

const reducer = combineReducers({
  app: appReducer,
  data: dataReducer,
  firestore: firestoreReducer,
});

export default reducer;
export type RootState = ReturnType<typeof reducer>;
