import {chain, sumBy, reduce} from 'lodash';

export const getTopOperatorsPerTickets = (
  data: Array<Object>
): Array<{key: string, value: number}> =>
  chain(data)
    .groupBy('aggregation.operator')
    .map((values, key) => ({
      key,
      value: sumBy(values, 'value'),
    }))
    .sortBy(['value'])
    .reverse()
    .value();

export const getTotalOperators = (data: Array<Object>): number =>
  getTopOperatorsPerTickets(data).length;

export const getTopOperatorsPerTemplates = (
  data: Array<Object>
): Array<{key: string, value: number}> =>
  chain(data)
    .groupBy('aggregation.operator')
    .map((values, key) => ({key, value: getTemplatesRatioPercent(values)}))
    .sortBy(['value'])
    .reverse()
    .value();

export const getTotalTemplatesUsage = (data: Array<Object>): number =>
  getTemplatesRatioPercent(data);

function getTemplatesRatioPercent(values: Array<Object>) {
  const v = reduce(
    values,
    (agg, n) => {
      const k = n.aggregation.macroid ? 'templates' : 'noTemplates';
      return {...agg, [k]: agg[k] + n.value};
    },
    {templates: 0, noTemplates: 0}
  );

  const percent = v.templates / (v.noTemplates + v.templates);
  return isNaN(percent) ? 0 : Math.round(percent * 100);
}
