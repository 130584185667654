// @flow
import React from 'react';
import {useQuery, useManualQuery} from 'graphql-hooks';

import {CardError} from '../../../../components/card';
import {
  ASSISTANCE_USAGES,
  REFUSED_ASSISTANCE_USAGES,
} from '../../../../lib/analytics';

import getDataHook from '../../../../lib/graphQL';
import DataWrapper from './data-wrapper';
import {formatValues} from './utils';

const AnalyticsIntents = ({
  dateRange,
  diffDateRange,
}: {
  dateRange: Object,
  diffDateRange: Object,
}) => {
  const {loading, error, data} = useQuery(
    ...getDataHook('getIntentsAgg', {date: dateRange})
  );

  const [fetchHistoryData, historyState] = useManualQuery(
    ...getDataHook('getIntentsAgg', {date: diffDateRange})
  );

  if (error || historyState.error) {
    return <CardError />;
  }

  if (
    !loading &&
    !historyState.data &&
    !historyState.loading &&
    !historyState.error
  ) {
    fetchHistoryData();
  }

  const usagesList = [...ASSISTANCE_USAGES, ...REFUSED_ASSISTANCE_USAGES];
  const formatedValues = formatValues(data, usagesList);
  const historyFormatedValues = formatValues(historyState.data, usagesList);

  return (
    <DataWrapper
      dateRange={dateRange}
      isLoading={loading}
      historyRange={diffDateRange}
      formatedValues={formatedValues}
      historyFormatedValues={historyFormatedValues}
    />
  );
};

export default AnalyticsIntents;
