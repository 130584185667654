// @flow
import React from 'react';
import {useQuery} from 'graphql-hooks';
import {chain, groupBy, sumBy} from 'lodash';

import {type INTENT_INTERFACE} from '../../../../selectors/intents';
import {ASSISTANCE_USAGES} from '../../../../lib/analytics';
import {buildBarsDataset} from './utils';
import getDataHook, {defaultDateRange} from '../../../../lib/graphQL';
import BarChart from '../../../analytics/components/bar';
import {CardError} from '../../../../components/card';

const Chart = ({intent}: {intent: INTENT_INTERFACE}) => {
  const formatedIntent = intent.key === 'no_intent' ? null : intent.key;

  const {loading, error, data} = useQuery(
    ...getDataHook('getBotUsageByIntents', {
      date: defaultDateRange,
      intents: [formatedIntent],
    })
  );

  if (error) {
    return <CardError />;
  }

  // set const with all interval values
  const valuesGroupedByUsage = groupBy(data?.botUsage ?? [], 'grouped');

  // set const with uniq yvalues (here time values)
  const yLabels = chain(data)
    .get('botUsage')
    .map(({date}) => date)
    .uniq()
    .value();

  return (
    <BarChart
      labels={yLabels}
      isLoading={loading}
      height="250px"
      yaxes={['responses']}
      datasets={buildBarsDataset(
        yLabels,
        valuesGroupedByUsage,
        ASSISTANCE_USAGES
      ).filter((dataset) => sumBy(dataset.data, 'y') > 0)}
    />
  );
};

export default Chart;
