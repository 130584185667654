import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import queryString from 'query-string';

import {updateOnboarding} from '../lib/firestore/user';

export const OAuth = ({
  updateOnboarding,
}: {
  updateOnboarding: (x: Object) => void,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setFlags = async () => {
      const {code, error, error_description = ''} = queryString.parse(
        window.location.search
      );
      if (code) {
        await updateOnboarding({
          id: 'OAUTH_SUCCESS',
          value: code,
        });
      } else {
        await updateOnboarding({
          id: 'OAUTH_ERROR',
          value: `${error} ${error_description.split('+').join(' ')}`,
        });
      }

      setLoading(false);
    };

    setFlags();
  }, [updateOnboarding]);

  const {code, error} = queryString.parse(window.location.search);

  return (
    <div className="h-100 w-100 d-flex align-items-center text-center">
      <h4 className="m-auto">
        {loading && 'Chargement...'}
        {!loading && code && (
          <span>
            Vous pouvez fermer cette fenêtre.
            <br />
            L'installation va se poursuivre dans l'autre fenêtre.
          </span>
        )}
        {!loading && error && (
          <span>
            Vous pouvez fermer cette fenêtre,
            <br />
            Une erreur est survenue, des informations complémentaires vous
            guideront dans l'autre fenêtre.
          </span>
        )}
      </h4>
    </div>
  );
};

export default connect(
  () => ({}),
  (dispatch) => ({
    ...bindActionCreators({updateOnboarding}, dispatch),
  })
)(OAuth);
