import React from 'react';
import {connect} from 'react-redux';

import {RootState} from '../../../../reducer';
import {Card} from '../../../../components/card';
import RefreshMacros from './refresh-macros';
import RefreshOperators from './refresh-operators';
import RefreshToken from './refresh-token';
import Informations from './informations';

const Zendesk = ({
  settingId,
  zendesk,
  token,
  provider,
}: {
  settingId: RootState['firestore']['setting']['id'];
  zendesk: RootState['firestore']['setting']['zendesk'];
  token: RootState['firestore']['jwt'];
  provider: RootState['data']['provider'];
}) => {
  return (
    <Card>
      <div className="card-body">
        <h4 className="card-title">
          {provider === 'zendesk' ? 'Zendesk' : 'Salesforce'}
        </h4>
        <hr />
        <>
          {provider === 'zendesk' && (
            <>
              <Informations zendesk={zendesk} />
              <hr />

              <RefreshMacros zendesk={zendesk} settingId={settingId} />
              <hr />

              <RefreshOperators zendesk={zendesk} settingId={settingId} />
              <hr />
            </>
          )}
        </>
        <RefreshToken token={token} />
      </div>
    </Card>
  );
};

export default connect((state: RootState) => ({
  settingId: state.firestore.setting.id,
  zendesk: state.firestore.setting.zendesk,
  token: state.firestore.jwt,
}))(Zendesk);
