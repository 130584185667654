// @flow
import React, {useState, useEffect} from 'react';
import {ListGroup, ListGroupItem} from 'reactstrap';
import {sortBy} from 'lodash';
import {Link} from 'react-router-dom';
import {Plus} from 'react-feather';
import {v4 as uuidv4} from 'uuid';

import {type INTENT_INTERFACE} from '../../../../selectors/intents';

const Modal = ({intents}: {intents: Array<INTENT_INTERFACE>}) => {
  const [fakeLoading, setFakeLoading] = useState(true);
  const [search, setSearch] = useState('');

  useEffect(() => {
    // Fake loading required in order to focus the input
    setTimeout(() => setFakeLoading(false), 30);
  }, []);

  if (fakeLoading) {
    return null;
  }

  return (
    <>
      <form>
        <input
          autoFocus={true}
          type="search"
          value={search}
          className="form-control mb-3"
          placeholder="Rechercher une catégorie"
          onChange={(e) => setSearch(e.target.value)}
        />
      </form>
      <ListGroup>
        {sortBy(findIntents(intents, search), ['title']).map(
          ({key, title, description}) => (
            <ListGroupItem
              className="d-flex align-items-center justify-content-between"
              key={uuidv4()}
            >
              <div>
                <h6>{title || key}</h6>
                <h6 className="text-muted m-0">{description}</h6>
              </div>
              <Link
                className="ml-4 p-2 text-hubware"
                to={{
                  pathname: `/config/intents/${key}/new`,
                  search: window.location.search,
                }}
              >
                <Plus size="15" />
              </Link>
            </ListGroupItem>
          )
        )}
      </ListGroup>
    </>
  );
};

export default Modal;

function findIntents(
  intents: Array<INTENT_INTERFACE>,
  search: string
): Array<Object> {
  return intents.filter(
    (intent) =>
      (intent.title || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      (intent.description || '').toLowerCase().indexOf(search.toLowerCase()) !==
        -1
  );
}
