// @flow
import queryString from 'query-string';
import firebase from 'firebase/app';
import {isNil, omitBy} from 'lodash';
import {getFromZendesk} from '../utils';

const schemas = require('@hubwa-re/joi-firebase')(require('joi-browser'));

export const getUser = (userId: string) => async (
  dispatch: Function,
  getState: Function
) => {
  const user = await fetchUser(userId);
  // if user exists return it, if the user is forced and the user doesn't exists, do not create it
  if (user || queryString.parse(window.location.search).uid) {
    return user;
  }

  // No user, then create it and try to fetch it
  const signUpForm = getState().app.uiState.signUpForm || {
    email: getState().app.loggedUser.email,
    displayName: '-',
    company: '-',
  };

  const zendeskDomain = getFromZendesk(window.location.search);
  const signupStep = {
    id: 'SIGNUP_STEP',
    timestamp: new Date(),
  };

  await createUser(userId, {
    created: new Date(),
    uiSettings: {
      ...signUpForm,
      onboarding: !zendeskDomain
        ? [signupStep]
        : [
            signupStep,
            {
              id: 'PROVIDER_STEP',
              value: 'zendesk',
            },
            {
              id: 'ZENDESK_DOMAIN_STEP',
              timestamp: new Date(),
              value: zendeskDomain,
            },
          ],
    },
  });

  const newUser = await fetchUser(userId);

  return newUser;

  function fetchUser(uid: string) {
    return firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .get()
      .then((doc) => doc.data());
  }
};

export const updateUserEmail = (userId: string, email: string) =>
  firebase.firestore().collection('users').doc(userId).update({
    'uiSettings.email': email,
  });

export const createUser = async (
  userId: string,
  user: {
    created: Date,
    uiSettings: {
      displayName: string,
      email: string,
      company: string,
      onboarding: [
        {
          id: string,
          timestamp: Date,
        }
      ],
    },
  }
) => {
  const validatedUser = await schemas.user.validate(user);

  return firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .set(validatedUser);
};

export const updateOnboarding = (update: {id: string, value?: string}) => (
  dispatch: Function,
  getState: Function
) =>
  firebase
    .firestore()
    .collection('users')
    .doc(getState()?.firestore?.user?.id)
    .update({
      'uiSettings.onboarding': firebase.firestore.FieldValue.arrayUnion(
        omitBy(
          {
            ...update,
            timestamp: new Date(),
          },
          isNil
        )
      ),
    });
