// @flow
import React, {type Node} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import cs from 'classnames';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {updateOnboarding} from '../../../lib/firestore/user';
import Help from '../help';

export const Step = ({
  index,
  activeStep,
  prevActiveStep,
  step: {title, description, help, children, eventId},
  updateOnboarding,
}: {
  index: number,
  activeStep: number,
  prevActiveStep?: number,
  step: {
    title: string,
    description?: string,
    help?: string,
    eventId: string,
    children: (props: any) => Node,
  },
  updateOnboarding: (x: Object) => void,
}) => {
  const {t} = useTranslation();

  const isTheNextStep = index === activeStep + 1;
  const isThePreviousStep = index === activeStep - 1;
  const isActiveStep = index === activeStep;
  const hasPreviousActiveStep = !(
    prevActiveStep === undefined || prevActiveStep === activeStep
  );
  if (!isThePreviousStep && !isTheNextStep && !isActiveStep) {
    return null;
  }
  return (
    <StyledStep
      className={cs('step-wrapper animate__animated', {
        ' animate__fadeInUp': isActiveStep && hasPreviousActiveStep,
        ' animate__fadeOutUp': isThePreviousStep && hasPreviousActiveStep,
        'd-none':
          isTheNextStep || (isThePreviousStep && !hasPreviousActiveStep),
        active: isActiveStep,
      })}
    >
      <p className="h3">
        <small className="text-muted">
          {t('Step')} {index + 1}
        </small>
      </p>

      {title && <h1 className="display pt-4">{title}</h1>}
      {description && <p className="lead">{description}</p>}

      {children({
        isActive: isActiveStep,
        updateOnboarding: (value?: string) =>
          updateOnboarding({id: eventId, value}),
      })}

      <Help help={help} eventId={eventId} />
    </StyledStep>
  );
};

const StyledStep = styled.div`
  padding: 0 40px;
  position: absolute;
  left: 0;
  right: 0;
  .display {
    font-size: '3rem';
  }
  p.lead {
    color: var(--dark);
    white-space: pre-line;
  }
`;

export default connect(
  () => ({}),
  (dispatch) => ({
    ...bindActionCreators({updateOnboarding}, dispatch),
  })
)(Step);
