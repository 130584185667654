import {getAppSetting} from '../lib/utils';
import {getUserNotification} from '../lib/user';

import {AppState} from './types/app-types';
import {
  AppActionsTypes,
  RESET_STORE,
  SET_IS_ADMIN,
  SET_LOGGED_USER,
  SET_ERROR,
  REFRESH_PARAMS,
  SET_UI_STATE,
} from '../actions/types/app-types';

const initialState: AppState = {
  debug: getAppSetting('debug'),
  offline: getAppSetting('offline') || getAppSetting('demo-tour'),
  isAdmin: false,
  loggedUser: undefined,
  error: undefined,
  uiState: {
    adminUsers: getAdminUsers(),
    userNotification: getUserNotification(),
  },
};

export function appReducer(
  state = initialState,
  action: AppActionsTypes
): AppState {
  switch (action.type) {
    case SET_IS_ADMIN: {
      return {
        ...state,
        isAdmin: action.isAdmin,
      };
    }
    case SET_LOGGED_USER: {
      return {
        ...state,
        loggedUser: action.user,
      };
    }
    case SET_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case REFRESH_PARAMS: {
      return {
        ...state,
        debug: getAppSetting('debug'),
        offline: getAppSetting('offline'),
      };
    }

    case SET_UI_STATE: {
      if (action.force) {
        return {
          ...state,
          uiState: action.uiState,
        };
      }
      return {
        ...state,
        uiState: {
          ...state.uiState,
          ...action.uiState,
        },
      };
    }

    default:
      return state;
  }
}

const rootReducer = (
  state = initialState,
  action: AppActionsTypes
): AppState => {
  if (action.type === RESET_STORE) {
    if (!action.stores || action.stores.includes('app')) {
      state = undefined;
    }
  }
  return appReducer(state, action);
};

export default rootReducer;

function getAdminUsers() {
  const adminUsers = localStorage.getItem('adminUsers');
  if (!adminUsers) {
    return [];
  }

  try {
    return JSON.parse(adminUsers);
  } catch (error) {
    return [];
  }
}
