// @flow
import React from 'react';
import {connect} from 'react-redux';
import {useQuery} from 'graphql-hooks';

import {getAllIntents} from '../../../../selectors/intents';
import {
  getAllTemplates,
  type TEMPLATE_INTERFACE,
} from '../../../../selectors/templates';
import getDataHook from '../../../../lib/graphQL';
import {CardError} from '../../../../components/card';

import Numbers from './numbers';
import TopLists from './top-lists';
import IntentsTable from './intents-table';

import {
  getTotalResponses,
  getTotalIntents,
  getTopIntentsPerTickets,
  getTopIntentsPerTemplates,
  getTotalTemplatesUsage,
  getTableHeaders,
  getTableData,
} from './utils';

export const Intents = ({
  intents,
  dateRange,
  allTemplates,
}: {
  intents: Object,
  dateRange: Object,
  allTemplates: Array<TEMPLATE_INTERFACE>,
}) => {
  /* ––––––––––––––––––––––––––––––- */
  /*           Dissclaimer           */
  /* This code is unclean,
                 written in a rush ! */
  /* ––––––––––––––––––––––––––––––- */
  const {loading, error, data} = useQuery(
    ...getDataHook('getNoBotUsageIntents', {date: dateRange})
  );
  const aggregate = data?.botUsage ?? [];

  if (error) {
    return <CardError />;
  }

  return (
    <>
      <Numbers
        loading={loading}
        totalResponses={getTotalResponses(aggregate, intents)}
        totalIntents={getTotalIntents(aggregate, intents)}
        totalTemplatesUsage={getTotalTemplatesUsage(aggregate)}
      />

      <TopLists
        loading={loading}
        topIntentsPerTickets={getTopIntentsPerTickets(aggregate, intents)}
        topIntentsPerTemplates={getTopIntentsPerTemplates(aggregate, intents)}
      />

      <IntentsTable
        loading={loading}
        tableHeaders={getTableHeaders(aggregate, intents)}
        tableData={getTableData(aggregate, allTemplates)}
      />
    </>
  );
};

export default connect((state) => ({
  intents: {...getAllIntents(state)},
  allTemplates: [
    ...getAllTemplates(state),
    {id: 'null', title: 'Réponse libre'},
  ],
}))(Intents);
