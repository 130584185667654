// @flow
import React, {type Node} from 'react';
import styled from 'styled-components';

const Number = ({
  children,
  isLoading,
  value,
  unit,
  color,
  title,
  description,
}: {
  children: Node,
  isLoading: boolean,
  value: number,
  unit?: string,
  color: string,
  title: string,
  description: string,
}) => (
  <div className="d-flex">
    <Bubble color={color}>{children}</Bubble>
    <div>
      <div className="display-4 mb-2">
        {isLoading && (
          <span
            className="text-muted animate__animated animate__flash animate__infinite animate__slower"
            style={{fontSize: '2rem'}}
          >
            ...
          </span>
        )}
        {!isLoading && (
          <span>
            {value}
            {Boolean(unit) && <StyledUnit>{unit}</StyledUnit>}
          </span>
        )}
      </div>
      <h6>
        <strong>{title}</strong>
      </h6>
      <p className="text-muted">{description}</p>
    </div>
  </div>
);

export default Number;

const StyledUnit = styled.small`
  font-size: 12px;
  padding-left: 5px;
`;

const Bubble = styled.div.attrs({
  className:
    'd-flex mt-3 mr-3 jOJoCd align-items-center justify-content-center',
})`
  background-color: ${(props) => props.color};
  border-radius: 50px;
  flex-shrink: 0;
  font-weight: bolder;
  font-size: 1.7rem;
  height: 2rem;
  opacity: 0.8;
  text-transform: uppercase;
  width: 2rem;

  svg {
    stroke: white;
    stroke-width: 2px;
  }
`;
