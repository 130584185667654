// @flow
import React, {type Node} from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

const SideNavLink = ({
  children,
  pathname,
}: {
  children: Node,
  pathname: string,
}) => (
  <StyledNavLink
    to={{pathname, search: window.location.search}}
    exact={true}
    activeClassName="active"
  >
    {children}
  </StyledNavLink>
);

export default SideNavLink;

const StyledNavLink = styled(NavLink).attrs({
  className: 'list-group-item',
})`
  background: transparent;
  border-width: 0;
  color: var(--gray);
  width: calc(100% + 10px);

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &.active {
    background: #f3f3f3;
    color: inherit;
    font-weight: 600;
  }

  & + &.active {
    border-top-width: 0;
  }
`;
