// @flow
import React, {useState} from 'react';
import {useQuery} from 'graphql-hooks';
import {Row, Col} from 'reactstrap';

import getDataHook from '../../../../lib/graphQL';
import {CardError} from '../../../../components/card';
import NumberChart from '../../../analytics/components/number';
import TopList from '../../../analytics/components/top-list';

import {
  getTotalOperators,
  getTopOperatorsPerTickets,
  getTopOperatorsPerTemplates,
  getTotalTemplatesUsage,
} from './utils';

const FreemiumOperators = ({dateRange}: {dateRange: Object}) => {
  const [slice, setSlice] = useState(5);
  const {loading, error, data} = useQuery(
    ...getDataHook('getNoBotUsageOperators', {date: dateRange})
  );

  if (error) {
    return <CardError />;
  }

  const aggregate = data?.botUsage ?? [];

  return (
    <>
      <Row>
        <Col>
          <NumberChart
            usage="Conseillers"
            isLoading={loading}
            value={getTotalOperators(aggregate)}
          />
          <TopList
            slice={slice}
            values={getTopOperatorsPerTickets(aggregate)}
            setSlice={setSlice}
            isLoading={loading}
            title="Top par réponses"
            subTitle="Première réponse par conseiller"
          />
        </Col>
        <Col>
          <NumberChart
            usage="Utilisation de modèles de réponse"
            unit="%"
            isLoading={loading}
            value={getTotalTemplatesUsage(aggregate)}
          />
          <TopList
            slice={slice}
            values={getTopOperatorsPerTemplates(aggregate)}
            setSlice={setSlice}
            isLoading={loading}
            title="Top par modèles de réponse"
            subTitle="Utilisation des modèles de réponse par conseiller"
            unit="%"
          />
        </Col>
      </Row>
    </>
  );
};

export default FreemiumOperators;
