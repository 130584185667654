// @flow
import React, {useState} from 'react';
import {Row, Col} from 'reactstrap';

import TopList from '../../../analytics/components/top-list';

const TopLists = ({
  loading,
  topIntentsPerTickets = [],
  topIntentsPerTemplates = [],
}: {
  loading: boolean,
  topIntentsPerTickets?: Array<Object>,
  topIntentsPerTemplates?: Array<Object>,
}) => {
  const [slice, setSlice] = useState(5);

  return (
    <Row>
      <Col>
        <TopList
          slice={slice}
          values={topIntentsPerTickets}
          setSlice={setSlice}
          isLoading={loading}
          title="Top par réponses"
          subTitle="Première réponse par catégorie"
        />
      </Col>
      <Col>
        <TopList
          slice={slice}
          values={topIntentsPerTemplates}
          setSlice={setSlice}
          isLoading={loading}
          title="Top par modèles de réponse"
          subTitle="Utilisation des modèles de réponse par catégorie"
          unit="%"
        />
      </Col>
    </Row>
  );
};

export default TopLists;
