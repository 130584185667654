// @flow
import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {DateTime} from 'luxon';
import {ExternalLink} from 'react-feather';
import styled from 'styled-components';

import {Card} from '../../../components/card';
import Table from '../../../components/table';

export const Templates = ({
  zendeskBaseUrl,
  templates,
  zendeskMacrosLastUpdate,
}: {
  zendeskBaseUrl?: string,
  templates: Array<Object>,
  zendeskMacrosLastUpdate?: Date,
}) => {
  return (
    <Card className="mb-4">
      <div className="card-body">
        <div className="d-flex align-items-center">
          <h4>Utilisation des modèles de réponses</h4>
          {zendeskMacrosLastUpdate && (
            <div id="last-updated" className="text-muted small ml-auto">
              Dernière mise à jour :{' '}
              {DateTime.fromJSDate(zendeskMacrosLastUpdate).toLocaleString(
                DateTime.DATE_MED
              )}
            </div>
          )}
        </div>
        <StyledTable
          data={templates}
          columns={useMemo(
            () => [
              {
                Header: '',
                accessor: 'id',
                Cell: ({cell: {value: id}}: {cell: {value: string}}) =>
                  zendeskBaseUrl && id ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${zendeskBaseUrl}/agent/admin/macros/${id}`}
                    >
                      <ExternalLink size={14} />
                    </a>
                  ) : null,
              },
              {
                Header: 'Titre',
                accessor: 'title',
              },
              {
                Header: 'Usage 7 derniers jours',
                accessor: 'usage_7d',
              },
              {
                Header: 'Usage 30 derniers jours',
                accessor: 'usage_30d',
              },
            ],
            [zendeskBaseUrl]
          )}
          csvFilename="templates"
          sortBy={['-usage_7d']}
        />
      </div>
    </Card>
  );
};

export default connect(({firestore}) => ({
  templates: firestore?.templates ?? [], // Should use selector. here this page is only available on zendesk
  zendeskMacrosLastUpdate: firestore?.setting?.zendesk?.zendeskMacrosLastUpdate,
  zendeskBaseUrl: firestore?.setting?.zendesk?.baseUrl,
}))(Templates);

const StyledTable = styled(Table)`
  th:first-child,
  td:first-child {
    text-align: left;
    font-weight: bold;
    width: 20px;
  }
`;
