import React, {useState} from 'react';
import {useStore} from 'react-redux';
import {Info} from 'react-feather';
import {useTranslation} from 'react-i18next';

import {Bloclogic} from '../../../../reducer/types/firestore-types';
import {RootState} from '../../../../reducer/index';

const FormFooter = ({
  editLogic,
  handleDelete,
  getBlocUsage,
}: {
  editLogic: Bloclogic;
  handleDelete: any;
  getBlocUsage: Function;
}) => {
  const {t} = useTranslation();

  const [displayTitle, setDisplayTitle] = useState(false);
  const store = useStore<RootState>();
  const templates = store.getState().firestore.templates;

  const templateUsingBloc = templates.filter(({id}) =>
    getBlocUsage(editLogic).find((macro) => Number(macro.id) === Number(id))
  );

  return (
    <div>
      {!(editLogic.id === 'new') && (
        <div
          className="d-flex mt-4 justify-content-between"
          id="delete-wrapper"
        >
          <div style={{fontSize: '80%'}}>
            {t('Bloc used in')} {templateUsingBloc.length}{' '}
            {templateUsingBloc.length <= 1
              ? t('template answer')
              : t('template answers')}
            {!!templateUsingBloc.length && (
              <Info
                className="ml-2"
                onClick={() => setDisplayTitle(!displayTitle)}
                size="12px"
                color="grey"
              />
            )}
            {displayTitle && (
              <span>
                {templateUsingBloc.map(({id, title}) => (
                  <li className="list-unstyled" key={id}>
                    <a
                      className="text-muted"
                      href={`https://app.hubwa.re/config/templates/${id}/logic${window.location.search}`}
                    >
                      {title}
                    </a>
                  </li>
                ))}
              </span>
            )}
          </div>
          <div
            color="link"
            className="text-danger clic"
            style={{fontSize: '80%'}}
            onClick={() => handleDelete(editLogic)}
          >
            {t('Delete this custom bloc')}
          </div>
        </div>
      )}
    </div>
  );
};

export default FormFooter;
