// @flow
import React, {useState, useMemo, useCallback} from 'react';
import {useDispatch, useStore} from 'react-redux';

import {
  addOperatorIntentPair,
  useAssignees,
} from '../../../../../lib/firestore/operators';

import PairCard from './paired';
import UnPairCard from './unPaired';
import DisabledCard from './disabled';

const useAutoAssign = ({intent}: {intent: Object}): Object => {
  const dispatch = useDispatch();
  const store = useStore();
  const [modal, setModal] = useState<boolean>(false);
  const salesforceProvider = store.getState()?.data.provider === 'salesforce';

  const pairs = store.getState()?.firestore?.setting?.operatorsIntentPairs;

  const {assignees} = useAssignees();
  // this feature is not enabled for Salesforce accounts

  const notSupported = useMemo(
    () => ['no_intent', 'all'].includes(intent.key) || salesforceProvider,
    [intent.key, salesforceProvider]
  );

  // operator or operators group paried with this intent
  const pairedOperator = useMemo(() => pairs?.[intent.key], [
    intent.key,
    pairs,
  ]);

  // display paired operator, incentive message or disabled message
  const Child = useMemo(() => {
    if (notSupported) {
      return <DisabledCard />;
    } else if (pairedOperator) {
      return (
        <PairCard
          assignees={assignees}
          pair={pairs?.[intent.key]}
          intent={intent}
          loading={!assignees.operators && !assignees.groups}
        />
      );
    }
    return <UnPairCard intent={intent} setModal={setModal} />;
  }, [assignees, intent, notSupported, pairedOperator, pairs]);

  // Callback handling click on operator/group in modal
  const handleClick = useCallback(
    ({id, name, type}: {id: number, name: string, type: string}) => {
      dispatch(addOperatorIntentPair(intent.key, id, name, type));
      setModal(false);
    },
    [dispatch, intent.key]
  );

  return {notSupported, modal, setModal, handleClick, Child, pairedOperator};
};

export default useAutoAssign;
