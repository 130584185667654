// @flow
import React, {useState, useEffect, useRef} from 'react';
import {Home, BarChart2, Aperture, Sliders} from 'react-feather';
import {Nav, NavItem, NavbarToggler, Collapse} from 'reactstrap';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import NavLink from '../../nav-link';

const MainLinks = ({salesforceProvider}: {salesforceProvider?: boolean}) => {
  const node = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const {t} = useTranslation();

  const handleClickOutside = (e) => {
    //$FlowFixMe
    if (node.current.contains(e.target)) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      //$FlowFixMe
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      //$FlowFixMe
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      //$FlowFixMe
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    //$FlowFixMe
    <div ref={node}>
      <NavbarToggler onClick={(e) => setIsOpen(!isOpen)} />
      <StyledCollapse onClick={(e) => setIsOpen(false)} isOpen={isOpen} navbar>
        <Nav navbar>
          <NavItem className="visible-md">
            <NavLink pathname="/home" exact>
              <Home width={14} className="align-bottom" />
              {t('Home')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink pathname="/analytics">
              <BarChart2 width={14} className="align-bottom" />
              {t('Stats')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink pathname="/config">
              <Sliders width={14} className="align-bottom" />
              {t('Setup')}
            </NavLink>
          </NavItem>
          {salesforceProvider ? (
            <div />
          ) : (
            <NavItem>
              <NavLink pathname="/ai">
                <Aperture width={14} className="align-bottom" />
                {t('Categorization')}
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </StyledCollapse>
    </div>
  );
};

export default MainLinks;

const StyledCollapse = styled(Collapse)`
  @media (max-width: 767.98px) {
    background-color: var(--white) !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.125);
    left: 0;
    position: absolute;
    top: 66px;
    width: 100%;
    z-index: 9000;
  }
`;
