// @flow
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
// $FlowFixMe
import GButton from 'react-google-button';
import {connect} from 'react-redux';
import {FormGroup, Input, Label, Button} from 'reactstrap';
import {useTranslation} from 'react-i18next';

import {signInWithGoogle, signInWithEmail} from '../../lib/firebase';
import SignWrapper from './sign-wrapper';

export const SignIn = ({debug = false}: {debug?: boolean}) => {
  const [invalidForm, setInvalidform] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const {t} = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    setInvalidform(false);
    setErrorMessage('');
    signInWithEmail(email, password).catch((err) => {
      setInvalidform(true);
      setErrorMessage(err.message);
    });
  };

  return (
    <SignWrapper
      link={{pathname: '/recover-password', title: t('Reset your password')}}
    >
      <div className="text-muted text-center m-2 mr-4">
        {t('Do not have an account yet?')}{' '}
        <Link
          to={{
            pathname: '/sign-up',
            search: window.location.search,
          }}
        >
          {t('Sign-up')}
        </Link>
      </div>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="email">{t('Email')}</Label>
          <Input
            bsSize="lg"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="hello@me.com"
            required
            invalid={invalidForm}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password">{t('Password')}</Label>
          <Input
            bsSize="lg"
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('Password')}
            required
            invalid={invalidForm}
          />
          <div className="invalid-feedback">{errorMessage}</div>
        </FormGroup>
        <FormGroup>
          <Button color="hubware" size="lg" className="w-100">
            {t('Log in')}
          </Button>
        </FormGroup>
      </form>

      {debug && (
        <>
          <hr style={{margin: '30px 0 25px'}} />
          <GButton
            className="w-100"
            onClick={signInWithGoogle}
            type="light"
            label={t('Sign in with Google')}
          />
        </>
      )}
    </SignWrapper>
  );
};

export default connect(({app}) => ({
  debug: app.debug,
}))(SignIn);
