// @flow
import React, {useState} from 'react';
import {connect} from 'react-redux';

import {Button, Form, FormGroup, Label, Input, Spinner} from 'reactstrap';
import {CheckCircle, XCircle} from 'react-feather';
import {get} from 'lodash';

import {Card} from '../../../components/card';
import {getPrediction} from '../../../lib/callable-functions';

export const PredictionForm = ({
  config,
  settingsId,
  addPrediction,
}: {
  config: string,
  settingsId: string,
  addPrediction: (x: Object) => void,
}) => {
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormState({});
    try {
      setLoading(true);

      const res = await getPrediction({
        config,
        settingsId,
        content: {body, subject},
      });
      setFormState({valid: true, error: false});
      addPrediction({subject, body, ...res});

      setSubject('');
      setBody('');
    } catch (error) {
      setFormState({error: 'Une erreur est survenue.'});
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="mt-3">
      <div className="card-body">
        <Form onSubmit={(e) => handleSubmit(e)}>
          <FormGroup>
            <Label for="subject">Sujet</Label>
            <Input
              type="text"
              name="subject"
              id="subject"
              placeholder="Entrer un sujet"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label for="body">Message</Label>
            <Input
              type="textarea"
              rows="6"
              name="body"
              id="body"
              placeholder="Entrer un message"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              required
            />
          </FormGroup>
          <div className="d-flex flex-row-reverse justify-content-between align-items-center">
            <Button color="hubware" disabled={loading}>
              {loading ? 'Chargement' : 'Analyser'}
            </Button>
            <div className="d-flex flex-row align-items-center">
              {loading && (
                <Spinner
                  type="grow"
                  color="danger"
                  style={{width: '2rem', height: '2rem'}}
                />
              )}
              {formState.error && (
                <>
                  <XCircle color="red" />
                  <span className="ml-2 mr-2 text-error">
                    {formState.error}
                  </span>
                </>
              )}
              {formState.valid && (
                <>
                  <CheckCircle color="green" />
                  <span className="ml-2 mr-2 text-success">
                    Analyse effectuée
                  </span>
                </>
              )}
            </div>
          </div>
        </Form>
      </div>
    </Card>
  );
};

export default connect((state) => ({
  config: get(state, 'firestore.setting.apiPrediction.configName', ''),
  settingsId: state.firestore.setting.id,
}))(PredictionForm);
