// @flow
import React, {useState, useEffect} from 'react';
import {Table} from 'reactstrap';
import {v4 as uuidv4} from 'uuid';
import {DateTime} from 'luxon';

import {adminGetUsers} from '../../../../lib/firestore/admin';

const UsersList = ({connectedUser = {}}: {connectedUser: Object}) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await adminGetUsers();
      setUsers(mapUsers(users));
      setLoading(false);
    };

    fetchUsers();
  }, []);

  if (loading) {
    return <div className="text-muted text-center">Loading...</div>;
  }

  return (
    <div>
      <form className="pb-3">
        <input
          autoFocus={true}
          type="search"
          value={search}
          className="form-control"
          placeholder="Rechercher un utilisateur"
          onChange={(e) => setSearch(e.target.value)}
        />
      </form>
      <Table hover responsive style={{fontSize: '90%'}}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Created</th>
            <th>
              Setting <small>& User Id</small>
            </th>
            <th>Links</th>
          </tr>
        </thead>
        <tbody>
          {findUser(users, search).map((user) => (
            <tr
              key={uuidv4()}
              className={`${
                user.id === connectedUser.id ? 'table-success' : ''
              }`}
            >
              <td>{user.email}</td>
              <td>{user.creation}</td>
              <td>
                <strong>{user.selectedSetting}</strong>
                <br />
                <small>{user.id}</small>
              </td>
              <td className="text-right">
                {user.id !== connectedUser.id && (
                  <a href={`${window.location.origin}?uid=${user.id}`}>
                    <small>connect</small>
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default UsersList;

function findUser(users: Array<Object>, search: string): Array<Object> {
  return users.filter(
    (user) =>
      user.id.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      user.selectedSetting.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      user.email.toLowerCase().indexOf(search.toLowerCase()) !== -1
  );
}

function mapUsers(users: Array<Object>): Object {
  return users.map((user) => ({
    id: user.id,
    email: user?.uiSettings.email ?? 'email not found',
    selectedSetting: user?.uiSettings.selectedSetting ?? 'no selectedSetting',
    creation: DateTime.fromMillis(
      user?.created.seconds * 1000
    ).toLocaleString(),
  }));
}
