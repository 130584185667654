// @flow
import React, {useState} from 'react';
import {isUndefined} from 'lodash';
import styled from 'styled-components';
import {Edit2, X, Check} from 'react-feather';
import BackBtn from '../../../components/back-btn';
import {Form, Input} from 'reactstrap';

const Title = ({
  back,
  title,
  description,
  onValidate = () => {},
  allowEdit = true,
}: {
  back: {pathname: string, listName: string},
  title: string,
  description?: string,
  onValidate?: (newTitle: string, newDesc?: string) => void,
  allowEdit?: boolean,
}) => {
  const [edit, setEdit] = useState(false);
  const [editTitle, setEditTitle] = useState(title);
  const [editDescription, setEditDescription] = useState(description);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = document.getElementById('edit-title-form');
    //$FlowFixMe
    if (!form.reportValidity()) {
      return;
    }
    setLoading(true);
    await onValidate(editTitle, editDescription);
    setLoading(false);
    setEdit(false);
  };

  const handleCancel = () => {
    if (loading) {
      return;
    }
    setEditTitle(title);
    setEdit(false);
  };

  return (
    <div className="mb-4 pb-2">
      <div className="d-flex align-items-start" style={{opacity: 0.8}}>
        <BackBtn
          to={{
            pathname: back.pathname,
            search: window.location.search,
          }}
          listName={back.listName}
        />

        {edit && (
          <Form onSubmit={handleSubmit} id="edit-title-form">
            <StyledInput>
              <Input
                bsSize="lg"
                type="text"
                autoFocus={true}
                value={editTitle}
                disabled={loading}
                placeholder="Titre..."
                onChange={(e) => setEditTitle(e.target.value)}
                className={editTitle.length === 0 ? 'is-invalid' : ''}
                required
              />

              {!isUndefined(description) && (
                <Input
                  type="text"
                  placeholder="Description..."
                  value={editDescription}
                  disabled={loading}
                  onChange={(e) => setEditDescription(e.target.value)}
                />
              )}

              <StyledIcon className="active" onClick={handleSubmit}>
                <Check size="15" />
              </StyledIcon>
              <StyledIcon className="active" onClick={handleCancel}>
                <X size="15" />
              </StyledIcon>
            </StyledInput>
            {/* we add a hidden submit button in orer to trigger form HTML5 validation */}
            <button type="submit" className="d-none" />
          </Form>
        )}

        {!edit && (
          <StyledTitle>
            <div className="d-flex">
              <h4 className="m-0" onDoubleClick={() => setEdit(allowEdit)}>
                {title}
              </h4>
              {allowEdit && (
                <StyledIcon onClick={() => setEdit(allowEdit)}>
                  <Edit2 size="15" />
                </StyledIcon>
              )}
            </div>
            {!isUndefined(description) && (
              <h6
                className="card-subtitle mt-2 text-muted"
                onDoubleClick={() => setEdit(allowEdit)}
              >
                {description || <em>Aucune description</em>}
              </h6>
            )}
          </StyledTitle>
        )}
      </div>
      <hr />
    </div>
  );
};

export default Title;

const StyledInput = styled.div.attrs({className: 'd-flex align-items-center'})`
  input {
    margin-top: -11px;
    margin-right: 5px;
    position: relative;
    top: 5px;
    width: 230px;
  }
`;

const StyledTitle = styled.div.attrs({
  className: 'flex-column justify-content-center pr-4',
})`
  &:hover,
  &:active {
    .styled-btn {
      opacity: 1;
    }
  }
`;

const StyledIcon = styled.div.attrs({
  className: 'styled-btn ml-2 clic text-center',
})`
  border-radius: 50%;
  height: 25px;
  opacity: 0;
  width: 25px;

  &:hover,
  &:active {
    background-color: #eee;
  }

  &.active {
    background-color: #eee;
    opacity: 1;
  }
`;
