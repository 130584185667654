const queries = {
  getIntents: {
    query: `query getIntentsTranslation($language: Language!) {
      intents: getIntentsTranslation(language: $language)
    }`,
    variables: {
      language: 'fr',
    },
  },

  getBotUsage: {
    query: `query getBotUsage(
      $config: AnalyticsConfigInput!
      $interval: AnalyticsIntervalInput
    ) {
      botUsage: getAnalyticsInterval(
        config: $config
        interval: $interval
      ) {
        date
        value
        grouped
      }
    }`,
    variables: {
      config: {
        types: ['BOT_USAGE'],
      },
      interval: {
        by: 'day',
        groupedBy: 'DATA_value',
      },
    },
    offline: 'graphql/getBotUsage.json',
  },

  getBotUsageTrend: {
    query: `query getBotUsageTrend(
      $config: AnalyticsConfigInput!
      $by: ANALYTIC_GROUPBY!
    ) {
      botUsage: getAnalyticsGroup(config: $config, by: $by) {
        key
        value
      }
    }`,
    variables: {config: {types: ['BOT_USAGE']}, by: 'DATA_value'},
    offline: 'graphql/getBotUsageTrend.json',
  },

  getIntentsAgg: {
    query: `query getIntentAggregation(
      $config: AnalyticsConfigInput!
      $by: [ANALYTIC_GROUPBY!]!
    ) {
      botUsage: getAnalyticsAggregate(config: $config, by: $by) {
        value
        aggregation {
          value
          intent
        }
      }
    }`,
    variables: {
      config: {types: ['BOT_USAGE']},
      by: ['DATA_value', 'DATA_intent'],
    },
    offline: 'graphql/getIntentsAgg.json',
  },
  // Test de query
  getMacroIdAgg: {
    query: `query getMacroIdAggregation(
      $config: AnalyticsConfigInput!
      $by: [ANALYTIC_GROUPBY!]!
    ) {
      botUsage: getAnalyticsAggregate(config: $config, by: $by) {
        value
        aggregation {
          macroid
          value
          intent
        }
      }
    }`,
    variables: {
      config: {
        types: ['BOT_USAGE'],
        values: [
          'AUTO_REPLY',
          'AUTO_REPLY_NOT_USED',
          'MACRO_AUTO_NOT_USED',
          'MACRO_AUTO_USED',
          'SUGGESTION_NOT_USED',
          'SUGGESTION_USED',
        ],
      },
      by: ['DATA_value', 'macroid', 'DATA_intent'],
    },
    offline: 'graphql/getMacroIdAgg.json',
  },

  getMacros: {
    query: `query getMacros($config: AnalyticsConfigInput!, $by: [ANALYTIC_GROUPBY!]!) {
      botUsage: getAnalyticsAggregate(config: $config, by: $by) {
        value
        aggregation {
          macroid
          value
        }
      }
    }`,
    variables: {
      config: {
        types: ['BOT_USAGE'],
        values: [
          'AUTO_REPLY',
          'AUTO_REPLY_NOT_USED',
          'MACRO_AUTO_NOT_USED',
          'MACRO_AUTO_USED',
          'SUGGESTION_NOT_USED',
          'SUGGESTION_USED',
        ],
      },
      by: ['DATA_value', 'macroid'],
    },
    offline: 'graphql/getMacroIdAgg.json',
  },

  getOperators: {
    query: `query getOperators($config: AnalyticsConfigInput!, $by: [ANALYTIC_GROUPBY!]!) {
      botUsage: getAnalyticsAggregate(config: $config, by: $by) {
        value
        aggregation {
          operator
          value
        }
      }
    }`,
    variables: {
      config: {
        types: ['BOT_USAGE'],
      },
      by: ['DATA_value', 'DATA_operator'],
    },
    offline: 'graphql/getOperators.json',
  },

  getClics: {
    query: `query getClics($config: AnalyticsConfigInput!, $by: [ANALYTIC_GROUPBY!]!) {
      linkClicked: getAnalyticsAggregate(config: $config, by: $by) {
        value
        aggregation {
          operator
          intent
        }
      }
    }`,
    variables: {
      config: {types: ['LINK_CLICKED']},
      by: ['DATA_intent', 'DATA_operator'],
    },
    offline: 'graphql/getClics.json',
  },

  getClicsTrend: {
    query: `query getClicsTrend($config: AnalyticsConfigInput!) {
      linkClicked: getAnalyticsCount(config: $config)
    }`,
    variables: {
      config: {
        types: ['LINK_CLICKED'],
      },
    },
    offline: 'graphql/getClicsTrend.json',
  },

  getVars: {
    query: `query getVars($config: AnalyticsConfigInput!, $by: [ANALYTIC_GROUPBY!]!) {
      variableSubstitution: getAnalyticsAggregate(config: $config, by: $by) {
        value
        aggregation {
          operator
          intent
        }
      }
    }`,
    variables: {
      config: {
        types: ['VARIABLE_SUBSTITUTION_MACRO_COUNT'],
      },
      by: ['DATA_intent'],
    },
    offline: 'graphql/getVars.json',
  },

  getVarsTrend: {
    query: `query getVarsTrend($config: AnalyticsConfigInput!) {
      variableSubstitution: getAnalyticsCount(config: $config)
    }`,
    variables: {
      config: {
        types: ['VARIABLE_SUBSTITUTION_MACRO_COUNT'],
      },
    },
    offline: 'graphql/getVarsTrend.json',
  },

  getClicsAndVarsCount: {
    query: `query getClicsAndVarsCount(
      $config: AnalyticsConfigInput!
      $by: ANALYTIC_GROUPBY!
    ) {
      linksAndVars: getAnalyticsGroup(config: $config, by: $by) {
        key
        value
      }
    }`,
    variables: {
      config: {
        types: ['LINK_CLICKED', 'VARIABLE_SUBSTITUTION_MACRO_COUNT'],
      },
      by: 'type',
    },
    offline: 'graphql/getClicsAndVarsCount.json',
  },

  getBotUsageByIntents: {
    query: `query getBotUsageByIntents(
      $config: AnalyticsConfigInput!
      $interval: AnalyticsIntervalInput
    ) {
      botUsage: getAnalyticsInterval(config: $config, interval: $interval) {
        date
        value
        grouped
      }
    }`,
    variables: {
      config: {
        types: ['BOT_USAGE'],
      },
      interval: {
        by: 'day',
        groupedBy: 'DATA_value',
      },
    },
    offline: 'graphql/getBotUsageByIntents.json',
  },

  getPredictionActivity: {
    query: `query getPredictionActivity(
      $config: PredictionConfigInput!
      $interval: PredictionIntervalInput
      )
      {
      predictions: getPredictionsInterval(config: $config, interval: $interval) {
        date
        value
        grouped
      }
    }`,
    variables: {
      interval: {
        by: 'day',
        groupedBy: 'OUTPUT_intents',
      },
    },
    offline: 'graphql/getPredictionActivity.json',
  },

  getNoBotUsageIntents: {
    query: `query getNoBotUsageIntents(
      $config: AnalyticsConfigInput!
      $by: [ANALYTIC_GROUPBY!]!
    ) {
      botUsage: getAnalyticsAggregate(config: $config, by: $by) {
        value
        aggregation {
          intent
          macroid
        }
      }
    }`,
    variables: {
      config: {
        types: ['NO_BOT_USAGE'],
      },
      by: ['DATA_intent', 'macroid'],
    },
    offline: 'graphql/getNoBotUsageIntents.json',
  },

  getNoBotUsageOperators: {
    query: `query getBotUsageNoOperators(
      $config: AnalyticsConfigInput!
      $by: [ANALYTIC_GROUPBY!]!
    ) {
      botUsage: getAnalyticsAggregate(config: $config, by: $by) {
        value
        aggregation {
          operator
          macroid
        }
      }
    }`,
    variables: {
      config: {
        types: ['NO_BOT_USAGE'],
      },
      by: ['DATA_operator', 'macroid'],
    },
    offline: 'graphql/getNoBotUsageOperators.json',
  },

  getPredictions: {
    query: `query getPredictions(
      $config: PredictionConfigInput!
      $list: PredictionListInput!
    ) {
      predictions: getPredictionsList(config: $config, list: $list) {
        id
        creation
        intents
        transient_content {
          core_body
          core_subject
        }
      }
    }`,
    variables: {
      list: {
        offset: 0,
        limit: 2,
      },
    },
  },

  getNoAnswerMessagePredictions: {
    query: `query getNoAnswerMessagePredictions(
          $config: PredictionConfigInput!
          $group: PredictionGroupInput
        ) {
          predictions : getPredictionsGroup(config: $config, group: $group) {
            key,
            value
          }
        }`,
    variables: {
      config: {
        unanswered: true,
      },

      group: {by: 'OUTPUT_intents', limit: 30},
    },
    offline: 'graphql/getNoAnswerMessagePredictions.json',
  },
};

export default queries;
