// @flow
import React, {useState, useEffect} from 'react';
import {get, omit} from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import Modal from '../../../../components/modal';
import {updateSettingAtPath} from '../../../../lib/firestore/setting';

export const MetadataModal = ({
  modal,
  setModal,
  metadata,
  metaDataMapping,
  updateSettingAtPath,
}: {
  modal: boolean,
  setModal: (x: boolean) => void,
  metadata?: string,
  metaDataMapping: Object,
  updateSettingAtPath: Function,
}) => {
  const [key, setKey] = useState('');
  const [description, setDescription] = useState('');
  const [jsonata, setJsonata] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (modal && metadata && metaDataMapping[metadata]) {
      setKey(metadata);
      setDescription(get(metaDataMapping, [metadata, 'description'], ''));
      setJsonata(
        get(metaDataMapping, [metadata, 'jsonata'], metaDataMapping[metadata])
      );
    } else {
      clearForm();
    }
  }, [metaDataMapping, metadata, modal]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    if (!key) {
      setError('Une clef doit être spécifiée');
      return;
    }
    if (!metadata && metaDataMapping[key]) {
      setError(
        'La nouvelle clef est déjà utilisée pour un autre élément variable'
      );
      return;
    }

    if (!/^[0-9A-Za-z_-]*$/.test(key)) {
      setError(
        "La clef ne doit pas contenir d'espaces, de caractères spéciaux ni d'accents"
      );
      return;
    }

    await updateSettingAtPath('metaDataMapping', {
      ...metaDataMapping,
      ...{
        [key]: {
          description,
          jsonata,
        },
      },
    });
    setModal(false);
  };

  const handleDelete = async () => {
    await updateSettingAtPath('metaDataMapping', omit(metaDataMapping, [key]));
    setModal(false);
  };

  const clearForm = () => {
    setKey('');
    setDescription('');
    setJsonata('');
  };

  return (
    <Modal
      size="md"
      modal={modal}
      setModal={setModal}
      handleConfirm={handleSubmit}
      handleCancel={() => {
        clearForm();
        setModal(false);
      }}
      title={metadata ? 'Élément variable' : 'Nouvel élément variable'}
    >
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="key">Clef</Label>
          <Input
            type="text"
            id="key"
            name="key"
            value={key}
            pattern="[0-9A-Za-z_-]*"
            onChange={(e) => setKey(e.target.value)}
            disabled={Boolean(metadata)}
            required
          />
          {!Boolean(metadata) && (
            <FormText color="muted">
              Le nom de l'élément variable ne doit pas contenir d'espace, de
              caractères spéciaux ni d'accents
            </FormText>
          )}
          <div className={`invalid-feedback ${error ? 'd-block' : ''}`}>
            {error}
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="description">Description</Label>
          <Input
            type="text"
            id="description"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="jsonata">JSONata</Label>
          <Input
            type="text"
            id="jsonata"
            name="jsonata"
            value={jsonata}
            onChange={(e) => setJsonata(e.target.value)}
            required
          />
        </FormGroup>
        {/* we add a hidden submit button in orer to trigger form HTML5 validation */}
        <button type="submit" className="d-none" />
      </Form>

      {metadata && (
        <div className="mt-4 text-right" id="delete-wrapper">
          <div
            color="link"
            className="text-danger clic"
            style={{fontSize: '80%'}}
            onClick={handleDelete}
          >
            Supprimer l'élément variable
          </div>
        </div>
      )}
    </Modal>
  );
};

export default connect(
  () => ({}),
  (dispatch) => ({
    ...bindActionCreators({updateSettingAtPath}, dispatch),
  })
)(MetadataModal);
