// @flow
import React from 'react';
import {connect} from 'react-redux';
import {DateTime} from 'luxon';
import {get} from 'lodash';

import {type TEMPLATE_INTERFACE} from '../../../../../selectors/templates';
import ListItem, {type ItemProps} from './list-item';

const REASONS_LIST = [
  {
    en: 'Missing metadata variable value(s)',
    fr: 'Variable(s) de réponses manquante(s)',
  },
  {
    en:
      'UpdateConflict: Safe update timestamp prevented the update due to outdated ticket data. Please fetch the latest ticket data and try again.',
    fr:
      "Le ticket a été mis à jour par un conseiller avant l'envoi de la réponse automatique",
  },
  {
    en: 'outdated',
    fr:
      "Le ticket a été mis à jour par un conseiller avant l'envoi de la réponse automatique",
  },
];

interface replyProps {
  reply: Object;
  provider?: string;
  selectedTab: string;
  zendeskBaseUrl: string;
  salesforceBaseUrl: string;
  template?: TEMPLATE_INTERFACE;
}

const Reply = ({...props}: replyProps) => {
  const providerInfos = getProviderInfos(props);

  //$FlowFixMe -- spread error. to be fixed
  return <ListItem {...providerInfos} />;
};

export default connect((state) => ({
  provider: state.data.provider,
  zendeskBaseUrl: get(state, 'firestore.setting.zendesk.baseUrl', ''),
  salesforceBaseUrl: get(state, 'firestore.setting.salesforce.domain', ''),
}))(Reply);

function getProviderInfos({
  reply,
  template,
  provider,
  selectedTab,
  zendeskBaseUrl,
  salesforceBaseUrl,
}: replyProps): ItemProps {
  const {macroId, externalId, reason} = reply;
  const replyAt = DateTime.fromJSDate(reply.replyAt).plus({minutes: 1});
  const templateTitle = template?.title ?? macroId;

  if (provider === 'salesforce') {
    const {replyToAddress, status} = reply;

    return {
      replyAt,
      selectedTab,
      templateTitle,
      to: replyToAddress,
      status: getReason(status === 'outdated' ? 'outdated' : reason), // variable part in salesforce reason for outdated.
      externalId: `Case ${externalId}`,
      externalUrl: `https://${salesforceBaseUrl}.lightning.force.com/lightning/r/Case/${externalId}/view`,
    };
  }

  const {endUserEmail} = reply;

  return {
    replyAt,
    selectedTab,
    templateTitle,
    to: endUserEmail,
    status: getReason(reason),
    externalId: `Ticket ${externalId}`,
    externalUrl: `${zendeskBaseUrl}/agent/tickets/${externalId}`,
  };
}

function getReason(reason: string): string {
  const trad = REASONS_LIST.find(({en}) => en === reason);
  return trad ? trad.fr : reason;
}
