//@flow
import React from 'react';

const TableBody = ({
  page,
  prepareRow,
  isLoading,
  link,
}: {
  page: Array<Object>,
  prepareRow: Function,
  isLoading?: boolean,
  link?: Function,
}) => (
  <tbody>
    {page.length === 0 && (
      <tr>
        <td colSpan="100%" className="text-center">
          {isLoading ? 'Chargement' : 'Aucune donnée'}
        </td>
      </tr>
    )}
    {page.map(
      (row, i) =>
        prepareRow(row) || (
          <tr key={'row-' + i} {...row.getRowProps()}>
            {row.cells.map((cell) => (
              <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
            ))}
          </tr>
        )
    )}
  </tbody>
);

export default TableBody;
