// @flow
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'reactstrap';
import {get, filter, sortBy, map} from 'lodash';
import {List} from 'react-feather';

import {getIntentsByKeys} from '../../../../selectors/intents';
import {
  AUTO_ASSISTANCE,
  PREPARED_ASSISTANCE,
  SUGGESTED_ASSISTANCE,
} from '../../../../lib/analytics';

import {Card} from '../../../../components/card';
import Dropdown from './dropdown';
import IntentTable from './intent-table';
import TemplateTable from './template-table';
import Doughnuts from './doughnuts';

export const DataWrapper = ({
  dateRange,
  formatedValues,
  historyFormatedValues,
  historyRange,
  intents,
  isLoading,
  salesforceProvider,
}: {
  dateRange: {startDate: Date, endDate: Date},
  formatedValues: Array<Object>,
  historyFormatedValues: Array<Object>,
  historyRange: {startDate: Date, endDate: Date},
  intents: Object,
  isLoading: boolean,
  salesforceProvider: boolean,
}) => {
  const [intent, setIntent] = useState();
  const usagesList = [
    ...AUTO_ASSISTANCE,
    ...PREPARED_ASSISTANCE,
    ...SUGGESTED_ASSISTANCE,
  ];

  const filteredFormatedValues = intent
    ? filter(formatedValues, {intent})
    : formatedValues;
  const filteredHistoryFormatedValues = intent
    ? filter(historyFormatedValues, {intent})
    : historyFormatedValues;

  return (
    <>
      <Row>
        <Dropdown
          intent={intent}
          intents={sortBy(map(intents), 'title')}
          setIntent={setIntent}
        />
      </Row>
      <Row>
        <Doughnuts
          auto={AUTO_ASSISTANCE}
          filteredFormatedValues={filteredFormatedValues}
          filteredHistoryFormatedValues={filteredHistoryFormatedValues}
          historyRange={historyRange}
          isLoading={isLoading}
          prepared={PREPARED_ASSISTANCE}
          suggested={SUGGESTED_ASSISTANCE}
        />
      </Row>
      <Row>
        <Col>
          <Card className="mb-4">
            <div className="card-body">
              {intent ? (
                <>
                  <div className="d-flex align-items-center">
                    <List
                      size={18}
                      onClick={() => setIntent(undefined)}
                      className="mr-3 mb-1 clic"
                      id="List"
                    />
                    <h4>
                      Assistance par catégorie :{' '}
                      {get(intents, [intent, 'title'], intent)}
                    </h4>
                  </div>
                  <div>
                    <TemplateTable
                      dateRange={dateRange}
                      intent={intent}
                      usagesList={usagesList}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <h4>Assistance par catégorie</h4>
                  <IntentTable
                    intent={intent}
                    intents={intents}
                    isLoading={isLoading}
                    filteredFormatedValues={filteredFormatedValues}
                    formatedValues={formatedValues}
                    salesforceProvider={salesforceProvider}
                    setIntent={setIntent}
                    usagesList={usagesList}
                  />
                </div>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default connect((state, props) => ({
  intents: getIntentsByKeys(
    state,
    props.formatedValues.map(({intent}) => intent)
  ),
  salesforceProvider: state.data.provider === 'salesforce',
}))(DataWrapper);
