// @flow

export const Calendly = (offline?: boolean) => {
  var link = document.createElement('link');
  link.href = 'https://assets.calendly.com/assets/external/widget.css';
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.media = 'screen,print';
  document.body && document.body.appendChild(link);

  const script = document.createElement('script');
  script.src = 'https://assets.calendly.com/assets/external/widget.js';
  if (offline) {
    script.onload = function () {
      window.Calendly.initBadgeWidget({
        url: 'https://calendly.com/hubware-meeting/demo',
        text: 'Découvrir Hubware',
      });
    };
  }

  document.body && document.body.appendChild(script);

  return null;
};
